import Joi from "joi";

export const initialValues = {
  files: []
};

/**
 *
 * @param document
 * @example
 */
const generateEditValues = (document) => ({
  publicName: document?.public_name || "",
  showPublic: document?.show_public || false
});

export { generateEditValues };

export const schema = Joi.object({
  files: Joi.array().min(1).items(Joi.object({
    fileName: Joi.string().empty("").required(),
    publicName: Joi
      .when("showPublic", {
        is: true,
        otherwise: Joi.string().empty(""),
        then: Joi.string().empty("").required()
      }),
    showPublic: Joi.boolean().required()
  }).unknown(true))
    .required()
}).unknown(true);

export const singleSchema = Joi.object({
  publicName: Joi
    .when("showPublic", {
      is: true,
      otherwise: Joi.string().empty(""),
      then: Joi.string().empty("").required()
    }),
  showPublic: Joi.boolean().required()
});
