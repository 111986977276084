/**
 *
 * @param props0 - The root object
 * @param props0.className - The root object
 * @example
 */
const PlusTripleIcon = ({ className }) => (
  <div className="inline-grid grid-cols-3">
    <div>
      <svg aria-hidden="true" className={className} fill="currentColor" viewBox="0 0 20 20">
        <path clipRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" fillRule="evenodd" />
      </svg>
    </div>

    <div>
      <svg aria-hidden="true" className={className} fill="currentColor" viewBox="0 0 20 20">
        <path clipRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" fillRule="evenodd" />
      </svg>
    </div>

    <div>
      <svg aria-hidden="true" className={className} fill="currentColor" viewBox="0 0 20 20">
        <path clipRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" fillRule="evenodd" />
      </svg>
    </div>
  </div>
);

export default PlusTripleIcon;
