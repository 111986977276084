import { useState } from "react";
import { useForm } from "react-hook-form";

import { selectByKeys } from "~/src/_common/_exports.js";

import validationResolver from "~/src/modules/validation-resolver.js";

import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import SlideOver from "~/src/ui/slides/container/index.jsx";

import CertificateForm from "./certificate-form.jsx";
import {
  createCertificateForNewIssuer, createCertificateForNewIssuerCertificate, fetchIssuers,
  getIssuerCertificateOptions, noFetchedCertificates
} from "./certificate-slide-helpers.js";
import { handleCreate, handleUpdate } from "./handlers.js";
import schema, {
  generateEditValues,
  initialValuesForForm
} from "./schema.js";

const emptyCertificatesFetched = "[{}]";

/**
 *
 * @param props0 - The root object
 * @param props0.certificate - The root object
 * @param props0.hide - The root object
 * @param props0.projectId - The root object
 * @param props0.reactFormApi - The root object
 * @param props0.reloadFlag - The root object
 * @param props0.show - The root object
 * @param props0.showAddIssuer - The root object
 * @param props0.visible - The root object
 * @example
 */
const NewCertificateSlide = ({
  certificate, hide, projectId, reactFormApi, reloadFlag, show, showAddIssuer, visible
}) => {
  const [issuerCertificatesFetched, setIssuerCertificatesFetched] =
    useState(emptyCertificatesFetched);

  const { mutate } = useProject(projectId);

  const [formIsLoading, setIsLoading] = useState(false);

  const [hasPoints, setHasPoints] = useState(false);

  const { showError, showSuccess } = useNotification();

  const onChangeIssuer = (newIssuer) => {
    const sameIssuer =
      newIssuer?.id === certificate?.issuer?.id;

    if (sameIssuer) {
      return;
    }

    setIssuerCertificatesFetched(emptyCertificatesFetched);
    show(createCertificateForNewIssuer(certificate?.id, newIssuer));
  };

  const onChangeIssuerCertificate = (newIssuerCertificate) => {
    const sameIssuerCertificate = (
      newIssuerCertificate?.id === certificate?.issuer_certificate?.id
    );

    if (sameIssuerCertificate) {
      return;
    }

    show(createCertificateForNewIssuerCertificate(certificate?.id, certificate?.issuer, newIssuerCertificate));
  };

  const loadIssuerCertificates = async (inputValue = "") => {
    const issuerFromCertificate = certificate?.issuer;
    const issuerId = issuerFromCertificate?.id;
    const issuerCertificates = JSON.parse(issuerCertificatesFetched);

    if (doNotFetchIssuerCertificates(issuerCertificates, issuerId)) {
      return issuerCertificates;
    }

    const newIssuerCertificates =
      await getIssuerCertificateOptions(inputValue, issuerId);

    setIssuerCertificatesFetched(JSON.stringify(newIssuerCertificates));

    return newIssuerCertificates;
  };

  const onSubmit = (data) => {
    const argumentForSubmit = {
      data,
      handleClose,
      mutate,
      projectId,
      setIsLoading,
      showError,
      showSuccess
    };

    if (certificate?.id) {
      argumentForSubmit.certificateId = certificate.id;
      handleUpdate(argumentForSubmit);

      return;
    }

    handleCreate(argumentForSubmit);
  };

  const handleClose = () => {
    setIssuerCertificatesFetched(emptyCertificatesFetched);
    hide(generateEditValues(), { useArg: true });
  };

  const doNotFetchIssuerCertificates = (issuerCertificates, issuerId) => {
    const issuerWithoutCertificates =
      !certificate.issuer?.has_certificates;
    const alreadyFetchedForIssuer =
      !noFetchedCertificates(issuerCertificates) &&
      issuerCertificates[0]?.id === issuerId;

    return issuerWithoutCertificates || alreadyFetchedForIssuer;
  };

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <CertificateForm
        key="certificate-form"
        {...{
          certificateJSON: JSON.stringify(certificate),
          fetchIssuers,
          formIsLoading,
          handleClose,
          loadIssuerCertificates,
          onChangeIssuer,
          onChangeIssuerCertificate,
          onSubmit,
          reactFormApi,
          reloadFlag,
          showAddIssuer
        }}
      />
    </SlideOver>
  );
};

export default NewCertificateSlide;
