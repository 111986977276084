import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param options0 - The root object
 * @param options0.ez - The root object
 * @param options0.kg - The root object
 * @example
 */
const useContracts = ({ ez, kg }) => {
  const parameters = new URLSearchParams({
    ez,
    kg
  });

  const {
    data, error, mutate
  } = useSWR(`/contracts?${parameters}`, fetcher);

  return {
    contracts: data?.payload.contracts,
    isError: error,
    isLoading: !error && !data,
    mutate
  };
};

export default useContracts;
