import { useParams } from "react-router-dom";

import useAutomatedProject from "~/src/hooks/use-automated-project.js";

import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

/**
 *
 * @example
 */
const AutomatedProjectRechtsform = () => {
  const { id } = useParams();
  const {
    automated_project: project,
    isError,
    isLoading
  } = useAutomatedProject(id);

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  return (
    <TabListContainer>
      <div className="sm:grid sm:grid-cols-8 sm:gap-4">
        <DescriptionTitle title="Rechtsform (Wohneinheiten)" />

        <DescriptionContainer>
          <div className="mb-2 grid grid-cols-4 gap-2 bg-gray-100 p-2">
            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">Eigentum</p>

              <p>Ja</p>
            </div>

            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">Miete</p>

              <p>-</p>
            </div>
          </div>
        </DescriptionContainer>
      </div>
    </TabListContainer>
  );
};

export default AutomatedProjectRechtsform;
