import cn from "classnames";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";

import innerText from "~/src/modules/inner-text.js";

import useModal from "~/src/hooks/use-modal.js";

import Button from "~/src/ui/buttons/button/index.jsx";
import CheckboxInput from "~/src/ui/forms/checkbox-input/index.jsx";
import ComboField from "~/src/ui/forms/combo-field/index.jsx";
import DateInput from "~/src/ui/forms/date-input/index.new.jsx";
import InlineInput from "~/src/ui/forms/inline-input/index.new.jsx";
import InputArea from "~/src/ui/forms/input-area/index.new.jsx";
import NumberInput from "~/src/ui/forms/number-input/index.new.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";

import DeleteFieldModal from "./delete-field-modal.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.lastFieldRemovable - The root object
 * @param props0.addDisabled - The root object
 * @param props0.addLabel - The root object
 * @param props0.addPosition - The root object
 * @param props0.aside - The root object
 * @param props0.askDelete - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.control - The root object
 * @param props0.deleteDisabled - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.handleSubmit - The root object
 * @param props0.iconSize - The root object
 * @param props0.inputs - The root object
 * @param props0.insertMethod - The root object
 * @param props0.isEdit - The root object
 * @param props0.label - The root object
 * @param props0.multiple - The root object
 * @param props0.name - The root object
 * @param props0.onChange - The root object
 * @param props0.placeholder - The root object
 * @param props0.prefix - The root object
 * @param props0.range - The root object
 * @param props0.register - The root object
 * @param props0.resetField - The root object
 * @param props0.setValue - The root object
 * @param props0.type - The root object
 * @param props0.watch - The root object
 * @example
 */
const CollectionField = ({
  lastFieldRemovable = false,
  addDisabled = false,
  addLabel = "",
  addPosition = "left",
  aside,
  autoFocus = false,
  className,
  control,
  deleteDisabled = false,
  disabled = false,
  errors,
  handleSubmit,
  iconSize = 6,
  inputs = [],
  insertMethod = "append",
  isEdit,
  label,
  multiple = false,
  name,
  onChange = () => { },
  prefix,
  range,
  register,
  resetField,
  setValue,
  type = "search",
  watch,

  id = name,
  askDelete = multiple,
  placeholder = innerText(label)
}) => {
  const [deleteIndex, setDeleteIndex] = useState();

  const {
    append,
    fields,
    prepend,
    remove
  } = useFieldArray({
    control,
    name
  });

  const {
    hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  const handleAdd = () => {
    const newField = multiple
      ? Object.fromEntries(inputs.map(({ name }) => [name, ""]))
      : { value: "" };

    if (newField) {
      if (insertMethod === "append") {
        append(newField);
      }
      else if (insertMethod === "prepend") {
        prepend(newField);
      }
    }
  };

  const onSubmitDelete = (index) => {
    const usedIndex = index ?? deleteIndex;

    remove(usedIndex);
    hideDelete();

    if (!lastFieldRemovable && usedIndex === 0 && fields.length === 1) {
      handleAdd();
    }
  };

  const handleRemove = (index) => {
    setDeleteIndex(index);
    if (askDelete) {
      showDelete();
    }
    else {
      onSubmitDelete(index);
    }
  };

  return (
    <div className={cn("sm:divide-y sm:divide-gray-200", className)}>
      <DeleteFieldModal hide={hideDelete} onSubmit={onSubmitDelete} show={deleteVisible} />

      <div className="items-start space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-x-4 sm:space-y-0 sm:px-6 sm:py-5">
        <div className="sticky top-4 h-auto">
          <label className="inline-block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor={id}>
            {label}
          </label>

          <div className="flex flex-col items-start gap-2">
            {
              addPosition === "left" && (
                <Button className="mt-2" disabled={addDisabled} icon={PlusIcon} label={addLabel} onClick={handleAdd} />
              )
            }

            {aside || null}

            {
              // TODO: overview of fields in collectionfield
              /* <ul className="flex flex-col gap-2 self-stretch">
							{
								fields.map((item, index) => (
									<li key={item.id} className="bg-white/50 p-2">
										<ul>
											{
												Object.entries(item)
													.filter(([key]) => key !== "id")
													.filter(([value]) => ["string", "number"].includes(typeof value))
													.map(([key, value]) => (
														<li key={key} className="text-gray-400 text-sm">
															{value}
														</li>
													))
											}
										</ul>
									</li>
								))
							}
						</ul> */}
          </div>
        </div>

        <ul
          className={
            cn(
              "flex flex-col col-span-2",
              {
                "gap-4": !multiple || inputs.length <= 2,
                "gap-16": multiple && inputs.length > 2
              }
            )
          }
        >
          {prefix || null}

          {
            fields.map((item, index) => (
              <li
                key={item.id}
                className={
                  cn(
                    "flex gap-4 w-full",
                    {
                      "flex-col": inputs.length > 2,
                      "w-full": inputs.length <= 2
                    }
                  )
                }
              >
                {
                  multiple
                    ? (
                      <ul className="relative grid grid-cols-4 gap-4">
                        {
                          inputs.map((input) => {
                            const {
                              id: innerId,
                              minDate,
                              maxDate,
                              async,
                              className,
                              countryCodes = [],
                              defaultOptions,
                              name: innerName,
                              numberType,
                              onChange: innerOnChange = () => { },
                              options,
                              span = 2,
                              type: innerType
                            } = input;

                            const arrayName = `${name}.${index}.${innerName}`;

                            let {
                              label: innerLabel
                            } = input;

                            if (typeof innerLabel === "function") {
                              innerLabel = innerLabel(arrayName);
                            }

                            const adjustedOnChange = (e) => {
                              innerOnChange(e, arrayName);
                              onChange(e, arrayName);
                            };

                            switch (innerType) {
                              case "area":
                                return (
                                  <InputArea
                                    {...{
                                      disabled,
                                      errors,
                                      name: arrayName,
                                      onChange: adjustedOnChange,
                                      placeholder: innerText(innerLabel),
                                      register,
                                      type: innerType
                                    }}
                                    key={`${id}.${index}.${innerName}`}
                                    className={cn(
                                      {
                                        "col-span-1": span === 1,
                                        "col-span-2": span === 2,
                                        "col-span-3": span === 3,
                                        "col-span-4": span === 4
                                      },
                                      className
                                    )}
                                  />
                                );
                              case "checkbox":
                                return (
                                  <CheckboxInput
                                    {...{
                                      disabled,
                                      errors,
                                      name: arrayName,
                                      onChange: adjustedOnChange,
                                      placeholder: innerText(innerLabel),
                                      prefix: <label className="text-sm" htmlFor={arrayName}>{innerLabel}</label>,
                                      register,
                                      type: innerType
                                    }}
                                    key={`${id}.${index}.${innerName}`}
                                    className={cn(
                                      {
                                        "col-span-1": span === 1,
                                        "col-span-2": span === 2,
                                        "col-span-3": span === 3,
                                        "col-span-4": span === 4
                                      },
                                      className
                                    )}
                                  />
                                );
                              case "combo":
                                return (
                                  <ComboField
                                    {...{
                                      async,
                                      control,
                                      countryCode: countryCodes?.[index],
                                      disabled,
                                      errors,
                                      isEdit,
                                      label: innerLabel,
                                      loadDefaultComboOptions: defaultOptions,
                                      name: arrayName,
                                      onChange: adjustedOnChange,
                                      options,
                                      register,
                                      resetField,
                                      setValue,
                                      watch
                                    }}
                                    inline
                                    noLabel
                                    key={`${id}.${index}.${innerName}`}
                                    className={cn(
                                      {
                                        "col-span-1": span === 1,
                                        "col-span-2": span === 2,
                                        "col-span-3": span === 3,
                                        "col-span-4": span === 4
                                      },
                                      className
                                    )}
                                  />
                                );
                              case "date":
                                return (
                                  <DateInput
                                    {...{
                                      minDate,
                                      maxDate,
                                      control,
                                      disabled,
                                      errors,
                                      name: arrayName,
                                      onChange: (e) => {
                                        adjustedOnChange(e);
                                      },
                                      placeholder: innerText(innerLabel),
                                      register
                                    }}
                                    key={`${id}.${index}.${innerName}`}
                                    className={cn(
                                      {
                                        "col-span-1": span === 1,
                                        "col-span-2": span === 2,
                                        "col-span-3": span === 3,
                                        "col-span-4": span === 4
                                      },
                                      className
                                    )}
                                  />
                                );
                              case "number":
                                return (
                                  <NumberInput
                                    {...{
                                      control,
                                      disabled,
                                      errors,
                                      name: arrayName,
                                      [numberType]: true,
                                      onChange: adjustedOnChange,
                                      placeholder: innerText(innerLabel),
                                      register
                                    }}
                                    key={`${id}.${index}.${innerName}`}
                                    className={cn(
                                      {
                                        "col-span-1": span === 1,
                                        "col-span-2": span === 2,
                                        "col-span-3": span === 3,
                                        "col-span-4": span === 4
                                      },
                                      className
                                    )}
                                  />
                                );
                              default:

                                return (
                                  <InlineInput
                                    {...{
                                      id: innerId,
                                      autoFocus,
                                      disabled,
                                      errors,
                                      name: arrayName,
                                      onChange: adjustedOnChange,
                                      placeholder: innerText(innerLabel),
                                      register,
                                      type: innerType
                                    }}
                                    key={`${id}.${index}.${innerName}`}
                                    className={cn(
                                      {
                                        "col-span-1": span === 1,
                                        "col-span-2": span === 2,
                                        "col-span-3": span === 3,
                                        "col-span-4": span === 4
                                      },
                                      className
                                    )}
                                  />
                                );
                            }
                          })
                        }
                      </ul>
                    )
                    : null
                }

                {
                  !multiple && (
                    <InlineInput
                      {...{
                        id,
                        autoFocus,
                        disabled,
                        errors,
                        name: `${name}[${index}].value`,
                        placeholder,
                        register,
                        type
                      }}
                    />
                  )
                }

                <div className="flex items-end justify-end gap-4">
                  {
                    addPosition === "right" && index === fields.length - 1 && (
                      <Button className="self-end" disabled={addDisabled} icon={PlusIcon} iconSize={iconSize} onClick={handleAdd} />
                    )
                  }

                  <Button
                    className="self-end bg-red-700 hover:bg-red-800 focus:ring-red-700 disabled:bg-red-300"
                    disabled={deleteDisabled}
                    icon={DeleteIcon}
                    iconSize={iconSize}
                    onClick={() => {
                      handleRemove(index);
                    }}
                  />
                </div>
              </li>
            ))
          }

          {
            addPosition === "bottom" && (
              <Button className="self-center" disabled={addDisabled} icon={PlusIcon} label={addLabel} onClick={handleAdd} />
            )
          }
        </ul>
      </div>
    </div>
  );
};

export default CollectionField;
