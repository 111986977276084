import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param options0 - The root object
 * @param options0.projectId - The root object
 * @example
 */
const useProjectContracts = ({
  projectId
}) => {
  const {
    data, error, mutate
  } = useSWR(
    `/projects/${projectId}/contracts`,
    fetcher
  );

  return {
    contracts: data?.payload?.contracts,
    isError: error,
    isLoading: !error && !data,
    mutate
  };
};

export default useProjectContracts;
