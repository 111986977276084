import { validDate } from "~/src/modules/formatters.js";
import Handler from "~/src/modules/handler.js";

const {
  handleUpdate
} = new Handler({
  endpoint: "/projects/:id/project-review",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (data, {
    form, project, userId
  }) => ({
    project: {
      last_review_date: validDate(form.values.last_review_date) || null,
      last_review_should_filter: form.values.last_review_should_filter || false,
      last_reviewer_id: form.values.last_review_date === project.last_review_date ? undefined : userId,
      last_unit_offer_research_date: validDate(form.values.last_unit_offer_research_date) || null,
      last_unit_offer_researcher_id: form.values.last_unit_offer_research_date === project.last_unit_offer_research_date ? undefined : userId,
      last_unit_offer_review_date: validDate(form.values.last_unit_offer_review_date) || null,
      last_unit_offer_reviewer_id: form.values.last_unit_offer_review_date === project.last_unit_offer_review_date ? undefined : userId,
      last_unit_review_should_filter: form.values.last_unit_review_should_filter || false,
      last_unit_sale_research_date: validDate(form.values.last_unit_sale_research_date) || null,
      last_unit_sale_researcher_id: form.values.last_unit_sale_research_date === project.last_unit_sale_research_date ? undefined : userId
    }
  })
});

export {
  handleUpdate
};
