import Joi from "joi";

export const initialValues = {
  area: "",
  baurecht: false,
  baurechtKg: {
    id: "",
    label: ""
  },
  brezNumber: "",
  ezNumber: "",
  gstNumbers: [{ value: "" }],
  kg: {
    id: "",
    label: ""
  }
};

/**
 *
 * @param einlage
 * @example
 */
const generateEditValues = (einlage) => {
  if (einlage) {
    const { katastralgemeinde: kg } = einlage;
    const { br_katastralgemeinde: br_kg } = einlage;

    return {
      area: einlage.area || "",
      baurecht: einlage.baurecht || false,
      baurechtKg: {
        id: br_kg ? br_kg.id : "",
        label: br_kg ? `${br_kg.code} ${br_kg.name} (BG ${br_kg.bezirksgericht.code} ${br_kg.bezirksgericht.name})` : ""
      },
      brezNumber: einlage?.brez_number || "",
      ezNumber: einlage?.ez_number || "",
      gstNumbers: einlage.gst_numbers.map((gstNumber) => ({ value: gstNumber })) || [{ value: "" }],
      kg: {
        id: kg.id,
        label: `${kg.code} ${kg.name} (BG ${kg.bezirksgericht.code} ${kg.bezirksgericht.name})`
      }
    };
  }

  return initialValues;
};

export { generateEditValues };

const schema = Joi.object({
  area: Joi.number().positive().empty("")
    .required(),
  baurecht: Joi.boolean().required(),
  baurechtKg: Joi.object({
    id: Joi.number().positive().empty(""),
    label: Joi.string().empty("")
  }).when(
    "baurecht",
    {
      is: true,
      then: Joi.object({
        id: Joi.number().positive().empty("")
          .required(),
        label: Joi.string().empty("").required()
      })
    }
  ),
  brezNumber: Joi.string().when(
    "baurecht",
    {
      is: true,
      otherwise: Joi.string().empty(""),
      then: Joi.string().empty("").required()
    }
  ),
  ezNumber: Joi.number().positive().empty("")
    .required(),
  gstNumbers: Joi.array().items(Joi.object({
    value: Joi.string().empty("").required()
  })),
  kg: Joi.object({
    id: Joi.number().positive().empty("")
      .required(),
    label: Joi.string().empty("")
  })
});

export default schema;
