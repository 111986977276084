import {
  Link, useParams, useRouteMatch
} from "react-router-dom";

import ProjectUnits from "~/src/features/project-units/index.jsx";

import useProject from "~/src/hooks/use-project.js";

import Skeleton from "~/src/ui/loading/item-skeleton/index.js";
import Page from "~/src/ui/page/index.jsx";

/**
 *
 * @example
 */
const ProjectUnitsGeneralPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);
  const { url } = useRouteMatch();

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <Page className="bg-transparent!" title="Objekte - Allgemein">
      <div className="flex justify-end p-2">
        <div className="underline">
          <Link to={`${url}/statistics`}>Statistiken</Link>
        </div>
      </div>

      <ProjectUnits />
    </Page>
  );
};

export default ProjectUnitsGeneralPage;
