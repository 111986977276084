import Handler from "~/src/modules/handler.js";

const { handleDelete } = new Handler({
  endpoint: "/project-contract-regions/:id",

  afterEvery: ({ setIsSubmitting }) => {
    setIsSubmitting(false);
  },
  afterEveryError: (error, { parseError, showError }) => {
    showError(parseError(error));
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  }
});

export { handleDelete };
