import { UnitBuyerDifferencesListProject } from "./list/_exports.js";

const UnitBuyerDifferencesList = ({
  editPossible,
  isLoading,
  mutate,
  unitBuyerDifferences
}) => {
  if (!unitBuyerDifferences) {
    return (
      <div className="h-screen">Loading...</div>
    );
  }

  return (
    <ul className="relative mt-8 flex w-[800px] flex-col gap-8">
      <li className="sticky top-[5.5rem] z-10 mx-[1.625rem] flex text-sm">
        <span className="ml-[25%] w-[35%] rounded bg-purple-200 p-1 text-center">Erfasser*in</span>

        <span className="ml-[5%] w-[35%] rounded bg-purple-200 p-1 text-center">KVEA</span>
      </li>

      {
        unitBuyerDifferences
          .map(({
            id, name, units
          }) => (
            <UnitBuyerDifferencesListProject
              key={id}
              {...{
                id,
                editPossible,
                mutate,
                name,
                units
              }}
            />
          ))
      }
    </ul>
  );
};

export default UnitBuyerDifferencesList;
