export const layout = [
  {
    children: ["offerArea", "saleArea"],
    title: "Fläche"
  },
  {
    layout: [
      {
        children: [
          "avgOfferPricePerMeter",
          "avgOfferPriceInvestorPerMeter",
          "avgOfferPriceBruttoInvestorPerMeter"
        ],
        title: "Kaufpreis/m²"
      },
      {
        children: [
          "offerPriceNormal",
          "offerPriceInvestor",
          "offerPriceBruttoInvestor"
        ],
        title: "Kaufpreis"
      },
      {
        children: ["avgSalePricePerMeter", "avgSalePriceGrossPerMeter"],
        title: "Verkaufspreis/m²"
      },
      {
        children: ["salePriceNet", "salePriceGross"],
        title: "Verkaufspreis"
      }
    ],
    title: "Preise"
  },
  {
    children: [
      "avgOfferRentNettoPerMeter",
      "avgOfferRentBruttoPerMeter",
      "avgOfferRentBkPerMeter",
      "rentNetto",
      "rentBrutto",
      "rentBk"
    ],
    title: "Miete"
  },
  {
    layout: [
      "Loggia",
      "Balkon",
      "Garten",
      "Terrasse",
      "Keller"
    ].map((title) => ({
      children: [`offer${title}Area`, `sale${title}Area`],
      title
    })),
    title: "Zubehör"
  }
];
