/**
 *
 * @param props0 - The root object
 * @param props0.label - The root object
 * @param props0.onClick - The root object
 * @example
 */
const CancelFormButton = ({ label = "abbrechen", onClick }) => (
  <button
    className="ml-4 font-medium text-gray-600"
    onClick={onClick}
    type="button"
  >
    {label}
  </button>
);

export default CancelFormButton;
