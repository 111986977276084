import Handler from "~/src/modules/handler.js";

const {
  handleUpdate
} = new Handler({
  endpoint: "/projects/:id/research-notes",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (researchNotes) => ({
    project: {
      research_notes: researchNotes
    }
  })
});

export {
  handleUpdate
};
