import cn from "classnames";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import validationResolver from "~/src/modules/validation-resolver.js";

import useModal from "~/src/hooks/use-modal.js";
import useNotification from "~/src/hooks/use-notification.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import CheckboxField from "~/src/ui/forms/checkbox-field/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import UnitDocumentItemSmall from "~/src/ui/project-unit/unit-document-item-small/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import EditBulkModal from "./edit-bulk-modal.jsx";
import OfferForm from "./forms/offer-form/index.jsx";
import { compareObjects } from "./helpers.js";
import { schemaBulkEdit } from "./unit-form-slide-bulk-edit/_exports.js";

const BulkUnitEditSlide = ({
  buildingParts, formValuesUnit, hide, mutateUnits, presetKeys, projectIds, resetSelection, setPage, unitIds, units, visible
}) => {
  const [formIsLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formIncoming, setFormIncoming] = useState(formValuesUnit);
  const [userSelectedKeys, setUserSelectedKeys] = useState([]);
  const [colorKeys, setColorKeys] = useState(presetKeys);
  const [changedField, setChangedField] = useState("");
  const [watcher, setWatcher] = useState({});

  const { showError, showSuccess } = useNotification();

  const form = useForm({
    defaultValues: formValuesUnit,
    resolver: validationResolver(schemaBulkEdit)
  });

  const {
    data: bulkEditData, hide: hideBulkEdit, show: showBulkEdit, visible: bulkEditVisible
  } = useModal();

  const {
    control, formState: { errors, isDirty }, handleSubmit, register, reset, resetField, setValue, watch
  } = form;
  const offerDataAvailable = watch("offerDataAvailable");
  const formValues = watch();

  const getDifferentKeys = (objectA, objectB) => [...new Set([...compareObjects(objectA, objectB), ...userSelectedKeys])];

  const handleClose = () => {
    reset(formValuesUnit);
    setColorKeys(presetKeys);
    setUserSelectedKeys([]);
    hide();
  };

  const unitHasOfferWithoutSale = () => {
    const hasOfferWithoutSale = (units)
      ? units.some((object) => object.offer_data_available && !object.sale_data_available)
      : false;

    return Boolean(hasOfferWithoutSale && offerDataAvailable);
  };

  const onSubmit = (data) => {
    const formEdited = getDifferentKeys(formValues, formIncoming).length > 0;

    if (formEdited) {
      if (units) {
        showBulkEdit({
          formValues,
          formValuesUnit,
          handleClose,
          mutate: mutateUnits,
          offerDataAvailable,
          presetKeys,
          projectIds,
          setIsLoading,
          showError,
          showSuccess
        });
      }
    }
    else {
      handleClose();
    }
  };

  useEffect(() => {
    setColorKeys(presetKeys);
  }, [presetKeys]);

  useEffect(() => {
    if (units && units.length > 0) {
      setIsEdit(true);
    }
  }, [units]);

  useEffect(() => {
    if (!userSelectedKeys.includes(changedField) && JSON.stringify(watcher[changedField]) !== JSON.stringify(formValuesUnit[changedField])) {
      setUserSelectedKeys([...userSelectedKeys, changedField]);
    }
  }, [JSON.stringify(watcher)]);

  useEffect(() => {
    reset(formValuesUnit);
    setFormIncoming(formValuesUnit);
    setUserSelectedKeys([]);
  }, [formValuesUnit]);

  useEffect(() => {
    for (const value of userSelectedKeys) {
      if (colorKeys.includes(value)) {
        const index = colorKeys.indexOf(value);

        setColorKeys([...colorKeys.slice(0, index), ...colorKeys.slice(index + 1)]);
      }
    }
  }, [userSelectedKeys]);

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (JSON.stringify(data) !== JSON.stringify(watcher)) {
        setChangedField(name);
        setWatcher(data);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div>
      <EditBulkModal
        changedKeys={getDifferentKeys(formValues, formIncoming)}
        data={bulkEditData}
        hide={hideBulkEdit}
        presetKeys={presetKeys}
        projectIds={projectIds}
        resetSelection={resetSelection}
        setPage={setPage}
        show={bulkEditVisible}
        units={units}
        watchData={{
          formValues,
          offerDataAvailable
        }}
      />

      <SlideOver hide={handleClose} visible={visible}>
        <FormProvider {...form}>
          <form autoComplete="off" className="relative flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" value="hidden" />

            <div className="flex flex-1 flex-col">

              <SlideFormHeader closeDisabled={formIsLoading} handleHide={handleClose} title={`Mehrere Objekte ${units ? "bearbeiten" : "erstellen"}`} />

              <CheckboxField
                className={colorKeys.includes("show") ? "space-y-6 bg-amber-500 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0" : "space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"}
                label="Objekt im Produkt anzeigen"
                name="show"
                {...{
                  errors,
                  register
                }}
              />

              <div
                className={cn("m-4 transition-all rounded", {
                  "bg-amber-500": colorKeys.includes("offerDataAvailable"),
                  "bg-primary-brown-light": offerDataAvailable,
                  "bg-white": !offerDataAvailable
                })}
              >
                <CheckboxField
                  disabled={unitHasOfferWithoutSale()}
                  label="Angebotsdaten vorhanden"
                  name="offerDataAvailable"
                  className={cn("transition-all transform", {
                    "-mx-4": !offerDataAvailable,
                    "mx-0": offerDataAvailable
                  })}
                  {...{
                    errors,
                    register
                  }}
                />

                {offerDataAvailable
                  ? (
                    <OfferForm
                      {...{
                        colorKeys,
                        isEdit,
                        onSubmit
                      }}
                    />
                  )
                  : null}
              </div>
            </div>

            <div className="sticky bottom-0 flex shrink-0 items-center justify-between gap-4 border-t border-gray-200 bg-white px-4 py-5 sm:px-6">
              {
                units?.documents && units.documents.length > 0
                  ? (
                    <ul className="flex flex-wrap gap-2">
                      {
                        units.documents.map((document) => (
                          <UnitDocumentItemSmall
                            document={document}
                            key={`document_unit_${document.id}`}
                          />
                        ))
                      }
                    </ul>
                  )
                  : null
              }

              <div className="ml-auto flex items-center justify-end gap-4">

                <CancelButton disabled={formIsLoading} onClick={handleClose} />

                <FormSubmitButton isSubmitting={formIsLoading}>
                  Speichern
                </FormSubmitButton>
              </div>
            </div>

          </form>

        </FormProvider>
      </SlideOver>
    </div>

  );
};

export default BulkUnitEditSlide;
