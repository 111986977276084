import CloseIcon from "~/src/ui/icons/close-icon.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @example
 */
const ModalCloseButton = ({ hide }) => (
  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
    <button className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none" onClick={hide} type="button">
      <CloseIcon />
    </button>
  </div>
);

export default ModalCloseButton;
