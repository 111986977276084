import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.form - The root object
 * @param props0.handleHide - The root object
 * @example
 */
const SlideFormFooter = ({ form, handleHide }) => (
  <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
    <div className="flex justify-end space-x-3">
      <CancelButton disabled={form.isLoading} onClick={handleHide} />

      <FormSubmitButton isSubmitting={form.isLoading}>
        Speichern
      </FormSubmitButton>
    </div>
  </div>
);

export default SlideFormFooter;
