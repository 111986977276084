import Joi from "joi";

export const initialValues = {
  additional_address_info: "",
  city: "",
  country: "",
  foreign_country: false,
  house_number: "",
  internal_notes: "",
  is_zvr: false,
  name: "",
  no_reg_number: false,
  reg_number: "",
  searchable: true,
  street: "",
  zipcode: ""
};

/**
 *
 * @param company
 * @example
 */
const generateEditValues = (company) => {
  if (company) {
    return {
      additional_address_info: company.additional_address_info || "",
      city: company.city || "",
      country: company.country || "",
      foreign_country: Boolean(company.country),
      house_number: company.house_number || "",
      internal_notes: company.internal_notes || "",
      is_zvr: company.is_zvr || false,
      name: company.name || "",
      no_reg_number: !company.reg_number,
      reg_number: company.reg_number || "",
      searchable: company.searchable,
      street: company.street || "",
      zipcode: company.zipcode || ""
    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  additional_address_info: Joi.string().empty("").optional(),
  city: Joi.string().empty("").optional(),
  country: Joi.string().empty("").optional(),
  foreign_country: Joi.boolean().optional(),
  house_number: Joi.string().empty("").optional(),
  internal_notes: Joi.string().empty("").optional(),
  is_zvr: Joi.boolean().required(),
  name: Joi.string().empty("").required(),
  no_reg_number: Joi.boolean().required(),
  reg_number: Joi.string().empty("").pattern(/^\S+[\w\-\\]$/u)
    .when("no_reg_number", {
      is: false,
      otherwise: Joi.optional(),
      then: Joi.required()
    }),
  searchable: Joi.boolean().required(),
  street: Joi.string().empty("").optional(),
  zipcode: Joi.string().empty("").optional()
});
