import cn from "classnames";
import {
  useEffect, useMemo, useState
} from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { statisticsLabels } from "~/src/modules/labels.jsx";
import validationResolver from "~/src/modules/validation-resolver.js";

import schema, { initialValues } from "~/src/features/project-units/statistics/schema.js";
import UnitFormSlide from "~/src/features/project-units/unit-form-slide.jsx";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";
import useUnitStatistics from "~/src/hooks/use-unit-statistics.js";

import ComboField from "~/src/ui/forms/combo-field/index.jsx";
import DateField from "~/src/ui/forms/date-field/index.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";
import ProjectItemSkeleton from "~/src/ui/loading/item-skeleton/index.js";

import { layout } from "./helpers.js";
import TopBottom from "./top-bottom.jsx";

/**
 *
 * @example
 */
const ProjectUnitsStatistics = () => {
  const [count, setCount] = useState(3);
  const [category, setCategory] = useState("");
  const [buildingPart, setBuildingPart] = useState("");
  const [createdRange, setCreatedRange] = useState([initialValues.createdAfter, initialValues.createdBefore]);
  const [updatedRange, setUpdatedRange] = useState([initialValues.updatedAfter, initialValues.updatedBefore]);

  const { id: projectId } = useParams();
  const { user } = useAuth();
  const { project } = useProject(projectId);
  const { editUnitPossible } = useEdit({
    project,
    user
  });
  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  const {
    categories, isLoading: isStatisticsLoading, mutate, statistics = {}
  } = useUnitStatistics({
    buildingPart,
    category,
    count,
    createdRange,
    projectId,
    updatedRange
  });

  const categoryOptions = useMemo(() => (categories?.map((uc) => ({
    id: uc.code,
    label: uc.name
  })) || []), [categories]);

  const buildingPartOptions = project?.building_parts.map((bp) => ({
    id: bp.id,
    label: bp.name
  })) || [];

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    watch
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  const onSubmit = (values) => {
    values.count && setCount(values.count);
    values.category && setCategory(values.category?.id);
    setBuildingPart(values.buildingPart?.id);

    const {
      createdAfter,
      createdBefore,
      updatedAfter,
      updatedBefore
    } = values;

    for (const [key, value] of Object.entries({
      createdAfter,
      createdBefore,
      updatedAfter,
      updatedBefore
    })) {
      if (value === null) {
        resetField(key);
      }
    }

    setCreatedRange([createdAfter, createdBefore]);
    setUpdatedRange([updatedAfter, updatedBefore]);
  };

  useEffect(() => {
    const value = watch("category");
    const newCategoryOptions = categoryOptions.length > 0 && !categoryOptions.find(({ id }) => id === value.id);

    if (newCategoryOptions) {
      reset({
        category: categoryOptions?.[0] || {
          id: "",
          label: ""
        }
      });
      setCategory(categoryOptions?.[0].id);
    }
  }, [
    categoryOptions,
    watch,
    reset
  ]);

  const showEditActions = editUnitPossible;

  return (
    <div className="flex flex-col gap-8 text-gray-800">
      <UnitFormSlide
        buildingParts={project?.building_parts}
        mutateUnits={mutate}
        projectId={projectId}
        unit={slideData}
        visible={slideVisible}
        hide={() => {
          hideSlide();
        }}
      />

      <form autoComplete="off" className="grid w-full grid-cols-3 gap-2" onChange={handleSubmit(onSubmit)} onSubmit={handleSubmit(onSubmit)}>

        <ComboField
          label="Kategorie"
          name="category"
          {...{
            control,
            resetField,
            setValue,
            watch
          }}
          inline
          options={categoryOptions}
          onChange={(e) => {
            handleSubmit(onSubmit)({ category: e });
          }}
        />

        <ComboField
          label="Bauteil"
          name="buildingPart"
          {...{
            control,
            resetField,
            setValue,
            watch
          }}
          inline
          options={buildingPartOptions}
          onChange={(e) => {
            handleSubmit(onSubmit)({ buildingPart: e });
          }}
        />

        <NumberField
          int
          label="Anzahl"
          name="count"
          {...{
            control,
            errors,
            register
          }}
          inline
          allowNegative={false}
          className="ml-auto w-16"
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= 1 && floatValue <= 30)}
        />

        <div className="col-span-2 grid grid-cols-4 gap-2">
          <div className="col-span-2 grid grid-cols-2 gap-2 rounded-sm border border-amber-300 bg-amber-50 p-2">
            <DateField
              label="erstellt ab"
              name="createdAfter"
              {...{
                control,
                errors,
                register
              }}
              inline
              isClearable
              className="col-span-1"
              defaultValue={initialValues.createdAfter}
              maxDate={new Date()}
              minDate={new Date("2020-01-01")}
              onChange={(e) => {
                handleSubmit(onSubmit)({ createdAfter: e });
              }}
            />

            <DateField
              label="erstellt bis"
              name="createdBefore"
              {...{
                control,
                errors,
                register
              }}
              inline
              isClearable
              className="col-span-1"
              defaultValue={initialValues.createdBefore}
              maxDate={new Date()}
              minDate={new Date("2020-01-01")}
              onChange={(e) => {
                handleSubmit(onSubmit)({ createdBefore: e });
              }}
            />
          </div>

          <div className="col-span-2 grid grid-cols-2 gap-2 rounded-sm border border-purple-300 bg-purple-50 p-2">
            <DateField
              label="bearbeitet ab"
              name="updatedAfter"
              {...{
                control,
                errors,
                register
              }}
              inline
              isClearable
              className="col-span-1"
              defaultValue={initialValues.updatedAfter}
              maxDate={new Date()}
              minDate={new Date("2020-01-01")}
              onChange={(e) => {
                handleSubmit(onSubmit)({ updatedAfter: e });
              }}
            />

            <DateField
              label="bearbeitet bis"
              name="updatedBefore"
              {...{
                control,
                errors,
                register
              }}
              inline
              isClearable
              className="col-span-1"
              defaultValue={initialValues.updatedBefore}
              maxDate={new Date()}
              minDate={new Date("2020-01-01")}
              onChange={(e) => {
                handleSubmit(onSubmit)({ updatedBefore: e });
              }}
            />
          </div>

        </div>

      </form>

      <article className="flex flex-col gap-8">
        {
          isStatisticsLoading && (!categories || categories.length > 0)
            ? (
              <div className="h-screen">
                <ProjectItemSkeleton className="grid grid-cols-3" />

                <ProjectItemSkeleton className="grid grid-cols-3" />

                <ProjectItemSkeleton className="grid grid-cols-3" />

                <ProjectItemSkeleton className="grid grid-cols-3" />

                <ProjectItemSkeleton className="grid grid-cols-3" />

                <ProjectItemSkeleton className="grid grid-cols-3" />
              </div>
            )
            : (
              layout.map(({
                children, layout: subLayout, title
              }) => (
                <section key={title}>
                  <h2 className="my-4 border-b-2 border-gray-300 pb-2 text-xl font-medium">{title}</h2>

                  <ul className={cn("mt-6", { "grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3": children?.length > 0 })}>
                    {
                      children
                        ? children.map((key) => {
                          const label = statisticsLabels.get(key);

                          return (
                            <TopBottom
                              key={key}
                              {...{
                                label,
                                showEditActions,
                                showSlide,
                                statistic: statistics?.[key],
                                statisticKey: key
                              }}
                            />
                          );
                        })
                        : null
                    }

                    {
                      subLayout
                        ? subLayout.map(({ children: subChildren, title: subTitle }) => (
                          <li className="mt-4" key={subTitle}>
                            <ul className={cn("mt-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3")}>

                              {
                                subChildren.map((key) => {
                                  const label = statisticsLabels.get(key);

                                  return (
                                    (
                                      <TopBottom
                                        key={key}
                                        {...{
                                          label,
                                          showEditActions,
                                          showSlide,
                                          statistic: statistics?.[key],
                                          statisticKey: key
                                        }}
                                      />
                                    )
                                  );
                                })
                              }

                            </ul>
                          </li>
                        ))
                        : null
                    }
                  </ul>
                </section>
              ))
            )
        }
      </article>
    </div>
  );
};

export default ProjectUnitsStatistics;
