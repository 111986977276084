/**
 *
 * @param props0 - The root object
 * @param props0.isEditor - The root object
 * @param props0.isLocked - The root object
 * @example
 */
const LockBadgeSmall = ({ isEditor = false, isLocked = false }) => {
  if (isLocked && isEditor) {
    return (
      <svg className="-ml-1 size-4 shrink-0 grow-0 text-amber-400" fill="currentColor" viewBox="0 0 20 20">
        <path clipRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" fillRule="evenodd" />
      </svg>
    );
  }

  if (isLocked && !isEditor) {
    return (
      <svg className="-ml-1 size-4 shrink-0 grow-0 text-red-400" fill="currentColor" viewBox="0 0 20 20">
        <path clipRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" fillRule="evenodd" />
      </svg>
    );
  }

  return null;
};

export default LockBadgeSmall;
