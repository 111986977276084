import Handler from "~/src/modules/handler.js";

const {
  handleCreate
} = new Handler({
  endpoint: "/projects",

  afterEvery: ({ setIsSubmitting }) => {
    setIsSubmitting(false);
  },
  afterEveryError: (error, { setError, showError }) => {
    setError(true);
    showError(error);
  },
  afterEverySuccess: (responseData, { hide, history }) => {
    setTimeout(() => {
      history.replace(`/projects/${responseData.payload.project.id}`);
    }, 800);

    hide();
  },
  transformCreateData: (data) => ({
    project: {
      name: data.name
    }
  })
});

export {
  handleCreate
};
