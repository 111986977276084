import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

const SlideOverPortal = ({ children }) => {
  const portalRoot = document.querySelector("#slides-root");

  /*
     Create a div as a wrapper
     using the useMemo hook so that a new value isn't
     computed on every render
  */
  const container = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    portalRoot.append(container);

    return () => {
      container.remove();
    };
  }, [container, portalRoot]);

  return createPortal(children, portalRoot);
};

export default SlideOverPortal;
