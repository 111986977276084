import { create } from "zustand";

const useStore = create((set) => ({

  /**
   *view Filter
   *
   * @example
   */
  setViewFilterCompact: () => {
    set((state) => ({ viewFilter: "compact" }));
  },
  setViewFilterDetail: () => {
    set((state) => ({ viewFilter: "detail" }));
  },
  viewFilter: "compact",

  //Bulk Edit Selection
  bulkEditSelection: [],
  setBulkEditSelection: (selection) => {
    set((state) => ({ bulkEditSelection: selection }));
  },

  selectedBulkUnits: [],
  setSelectedBulkUnits: (units) => {
    set((state) => ({ selectedBulkUnits: units }));
  },

  /*Project Query Params*/
  savedQuery: {
    cityId: undefined,
    name: undefined,
    page: 1,
    researchFilter: "all",
    sortFilter: "updated-desc",
    state: undefined,
    statusFilter: "all",
    street: undefined,
    synchronizedFilter: "all"
  },
  setSavedQuery: (query) => {
    set((state) => ({ savedQuery: query }));
  },

  /*Automated Project Query Params*/
  savedQueryAutomated: {
    cityId: undefined,
    name: undefined,
    page: 1,
    researchFilter: "all",
    sortFilter: "updated-desc",
    state: undefined,
    statusFilter: "all",
    street: undefined,
    synchronizedFilter: "all"
  },
  setSavedQueryAutomated: (query) => {
    set((state) => ({ savedQueryAutomated: query }));
  }
}));

export default useStore;
