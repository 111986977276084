import cn from "classnames";
import { useParams } from "react-router-dom";

import { formatDate, formatNumber } from "~/src/modules/formatters.js";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import { handleDelete } from "./handlers.js";
import WidmungSlide from "./widmung-slide.jsx";

const ProjectWidmungen = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();
  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  return (
    <TabListContainer>

      <WidmungSlide hide={hideSlide} projectId={id} visible={slideVisible} widmung={slideData} />

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete,
          hide: hideDelete,
          mutate,
          show: deleteVisible
        }}
      />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Flächenwidmungen" />

        <DescriptionContainer>

          {project.widmungen.map((widmung, index) => (

            <div
              key={`widmung_${widmung.id}`}
              className={cn("grid grid-cols-4 gap-2 p-2", {
                "bg-gray-100": index % 2 === 0
              })}
            >

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Plandokument</p>

                <p className="">{widmung.plan_number}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Kundmachung</p>

                <p className="">{formatDate({ date: widmung.publish_date })}</p>
              </div>

              {showEditActions
                ? (
                  <div className="col-span-2 flex items-center justify-end">
                    <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-none" onClick={() => showSlide(widmung)} type="button">
                      <EditIcon className="size-6" />
                    </button>

                    <button className="ml-6 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-none" onClick={() => showDelete(widmung)} type="button">
                      <DeleteIcon className="size-6" />
                    </button>
                  </div>
                )
                : null}

              {!showEditActions &&
                <div className="col-span-2" />}

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Flächenwidmung</p>

                <p>{widmung.widmung ? widmung.widmung : "-"}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Bauklasse</p>

                <p>{widmung.bauklasse ? widmung.bauklasse : "-"}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Höhenbeschränkung</p>

                <p>{widmung.height_limit_sign === true ? "+" : ""}{formatNumber(widmung.height_limit)}{widmung.height_limit_to ? `-${formatNumber(widmung.height_limit_to)}` : null}{widmung.height_limit ? "m" : "-"}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Bauweise</p>

                <p>{widmung.bauweise ? widmung.bauweise : "-"}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Zusätzl. Bestimmungen</p>

                <p>{widmung.additional ? widmung.additional : "-"}</p>
              </div>

            </div>
          ))}
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showSlide()}>
                  <PlusIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectWidmungen;
