import { useState } from "react";

/**
 *
 * @param options0 - The root object
 * @param options0.automated - The root object
 * @param options0.project - The root object
 * @param options0.user - The root object
 * @example
 */
const useEdit = ({
  automated = false, project = null, user = null
}) => {
  const [editing, setEditing] = useState(false);

  const activeEditorKey = automated ? "active_editor" : "active_project_editor";

  /**
   *
   * @example
   */
  function toggleEdit() {
    setEditing((previous) => !previous);
  };

  return {
    editPossible: project?.[activeEditorKey] && project[activeEditorKey].id === user.id,
    editUnitPossible: project?.active_unit_editor && project?.active_unit_editor?.id === user.id,
    editing,
    setEditing,
    toggleEdit
  };
};

export default useEdit;
