import { validDate } from "~/src/modules/formatters.js";
import Handler from "~/src/modules/handler.js";

const {
  handleUpdate
} = new Handler({
  endpoint: "/timelines/:id",

  afterEvery: ({
    setIsLoading, setIsSubmitting
  }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (values) => ({
    timeline: {
      current_construction_phase: values.current_construction_phase?.value,
      current_marketing_phase: values.current_marketing_phase?.value,

      construction_phase_purchase_date: validDate(values.construction_phase_purchase_date) || null,
      construction_phase_purchase_date_format: values.construction_phase_purchase_date_format?.value,

      construction_phase_active_date: validDate(values.construction_phase_active_date) || null,
      construction_phase_active_date_format: values.construction_phase_active_date_format?.value,

      construction_phase_completed_date: validDate(values.construction_phase_completed_date) || null,
      construction_phase_completed_date_format: values.construction_phase_completed_date_format?.value,

      marketing_phase_initial_date: validDate(values.marketing_phase_initial_date) || null,
      marketing_phase_initial_date_format: values.marketing_phase_initial_date_format?.value,

      marketing_phase_coming_soon_date: validDate(values.marketing_phase_coming_soon_date) || null,
      marketing_phase_coming_soon_date_format: values.marketing_phase_coming_soon_date_format?.value,

      marketing_phase_active_date: validDate(values.marketing_phase_active_date) || null,
      marketing_phase_active_date_format: values.marketing_phase_active_date_format?.value,

      marketing_phase_completed_apartments_date: validDate(values.marketing_phase_completed_apartments_date) || null,
      marketing_phase_completed_apartments_date_format: values.marketing_phase_completed_apartments_date_format?.value,

      marketing_phase_completed_all_date: validDate(values.marketing_phase_completed_all_date) || null,
      marketing_phase_completed_all_date_format: values.marketing_phase_completed_all_date_format?.value,

      marketing_phase_project_finished_date: validDate(values.marketing_phase_project_finished_date) || null,
      marketing_phase_project_finished_date_format: values.marketing_phase_project_finished_date_format?.value
    }
  })
});

export { handleUpdate };
