import Joi from "joi";

import { locationSchema } from "./_common/_exports.js";

const privateBuyerSchema = Joi.object({
  titlePrefix: Joi.string().empty(""),
  titleSuffix: Joi.string().empty(""),

  name: Joi.string().empty(""),
  surname: Joi.string().empty(""),

  birthdate: Joi.date().empty(null).allow(""),

  coordinates: Joi.object({
    label: Joi.string(),
    lat: Joi.number(),
    lng: Joi.number()
  }).allow(null, ""),

  id: Joi.number().positive().empty("")
    .optional(),
  notes: Joi.string().empty(""),
  show: Joi.boolean().default(true)
})
  .append(locationSchema)
  .when(
    Joi.custom((privateBuyer, helpers) => {
      const surnameIsEmpty = !privateBuyer.surname;

      const otherValuesAreSet = Object.values(privateBuyer).some((value) => (typeof value === "string" && value !== "") || ((typeof value === "object" && value?.constructor === Object) && Object.keys(value).length > 0));

      if (privateBuyer && surnameIsEmpty && otherValuesAreSet) {
        return helpers.error("any.required");
      }

      return privateBuyer;
    }),
    {
      otherwise: Joi.object({
        surname: Joi.required()
      })
    }
  );

export default privateBuyerSchema;
