import { useParams } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useProject from "~/src/hooks/use-project.js";

import EditButton from "~/src/ui/buttons/edit-project-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import ProjectNameForm from "./form.jsx";

/**
 *
 * @example
 */
const ProjectName = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, project
  } = useProject(id);
  const {
    editPossible, editing, setEditing
  } = useEdit({
    project,
    user
  });

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  return (
    <TabListContainer>

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Projektname" />

        <DescriptionContainer>
          {editing
            ? <ProjectNameForm projectId={id} setEditing={setEditing} />
            : <span>{project?.name}</span>}
        </DescriptionContainer>

        <EditButton
          editPossible={editPossible}
          editing={editing}
          onClick={() => setEditing((previous) => !previous)}
        />

      </div>

    </TabListContainer>
  );
};

export default ProjectName;
