import cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.className - The root object
 * @param props0.disabled - The root object
 * @param props0.icon - The root object
 * @param props0.iconPosition - The root object
 * @param props0.iconSize - The root object
 * @param props0.innerRef - The root object
 * @param props0.label - The root object
 * @param props0.onClick - The root object
 * @param props0.type - The root object
 * @example
 */
const Button = ({
  className,
  disabled,
  icon: Icon,
  iconPosition = "left",
  iconSize = 6,
  innerRef,
  label,
  onClick,
  type = "button"
}) => {
  let iconJsx;

  if (Icon) {
    iconJsx = (
      <Icon
        className={cn(
          "mx-1",
          {

            "h-1 w-1": iconSize === 1,
            "h-2 w-2": iconSize === 2,
            "h-3 w-3": iconSize === 3,
            "h-4 w-4": iconSize === 4,
            "h-5 w-5": iconSize === 5,
            "h-6 w-6": iconSize < 1 || iconSize > 5
          }
        )}
      />
    );
  }

  return (
    <button
      {...{
        disabled,
        onClick,
        type
      }}
      className={cn("inline-flex items-center justify-around px-4 py-2 shadow-sm font-medium rounded text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 disabled:bg-gray-400 disabled:cursor-auto [&>svg]:first:-ml-1 [&>svg]:last:-mr-1 [&>svg]:only:-mx-1", className)}
      ref={innerRef}
    >
      {iconPosition === "left" && iconJsx}

      {label ? <span>{label}</span> : null}

      {iconPosition === "right" && iconJsx}
    </button>
  );
};

export default Button;
