import { useParams } from "react-router-dom";

import ProjectConstructionInfos from "~/src/features/project-attributes/project-construction-infos/index.jsx";
import ProjectConstructionTypes from "~/src/features/project-attributes/project-construction-types/index.jsx";
import ProjectFunding from "~/src/features/project-attributes/project-funding/index.jsx";
import ProjectQuantities from "~/src/features/project-attributes/project-quantities/index.jsx";
import ProjectRechtsform from "~/src/features/project-attributes/project-rechtsform/index.jsx";

import useProject from "~/src/hooks/use-project.js";

import TabHeader from "~/src/ui/headers/tab-header/index.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @example
 */
const ProjectAttributesPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <>
      <TabHeader>Projekteigenschaften</TabHeader>

      <div className="divide-y divide-gray-200">
        <ProjectRechtsform />

        <ProjectQuantities />

        <ProjectFunding />

        <ProjectConstructionInfos />

        <ProjectConstructionTypes />
      </div>
    </>
  );
};

export default ProjectAttributesPage;
