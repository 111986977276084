import Joi from "joi";

export const initialValues = {
  public_notes: ""
};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project) {
    return {
      ...initialValues,
      ...project
    };
  }

  return initialValues;
};

export { generateEditValues };

const schema = Joi.object({
  public_notes: Joi.string().empty("").min(5)
});

export default schema;
