import Handler from "~/src/modules/handler.js";

const { handleUpdate } = new Handler({
  endpoint: "/users/:id",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (data) => {
    const userData = {
      user: {
        role: data.role.value,
        username: data.username
      }
    };

    if (data.password) {
      userData.user.password = data.password;
    }

    return userData;
  }
});

export {
  handleUpdate
};
