import Handler from "~/src/modules/handler.js";

const {
  handleCreate
} = new Handler({
  endpoint: "/users",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { setError, showError }) => {
    setError(true);
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data) => ({
    user: {
      email: data.email,
      password: data.password,
      role: data.role.value,
      username: data.username
    }
  })
});

export {
  handleCreate
};
