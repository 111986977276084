import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @example
 */
const useActiveUserProjects = () => {
  const {
    data, error, mutate
  } = useSWR("/projects/active-user-projects", fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    projects: data?.payload?.projects
  };
};

export default useActiveUserProjects;
