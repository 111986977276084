import ReactQuill from "react-quill";

/**
 *
 * @param props0 - The root object
 * @param props0.onChange - The root object
 * @param props0.value - The root object
 * @example
 */
const EditorInput = ({
  onChange,
  value
}) => (
  <ReactQuill
    bounds="[data-slide]"
    defaultValue={value}
    formats={formats}
    modules={modules}
    onChange={onChange}
    theme="snow"
  />
);

export default EditorInput;

const modules = {
  toolbar: [
    [
      {
        header: [
          2,
          3,
          false
        ]
      }
    ],
    [
      "bold",
      "italic",
      "underline",
      "blockquote"
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"],
    ["clean"]
  ]
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link"
];
