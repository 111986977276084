import cn from "classnames";
import {
  CircleCheckIcon, CircleXIcon, Grid2x2PlusIcon
  , RefreshCcwIcon, Trash2Icon
} from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import API from "~/src/modules/api.js";
import { formatDate } from "~/src/modules/formatters.js";

import ProjectContractList from "~/src/features/contract-list/index.jsx";
import DeleteContractRegionModal from "~/src/features/modals/contract-regions/delete-contract-region-modal/index.jsx";
import NewContractRegionModal from "~/src/features/modals/contract-regions/new-contract-region-modal/index.jsx";

import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";
import useProjectContracts from "~/src/hooks/use-project-contracts.js";

import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @example
 */
const ProjectContractsPage = () => {
  const { id } = useParams();
  const { mutate: mutateProject, project } = useProject(id);

  const {
    contracts, isError, isLoading, mutate
  } = useProjectContracts({ projectId: id });
  const [isRefreshing, setIsRefreshing] = useState(false);

  const {
    hide: hideNewContractRegionModal, show: showNewContractRegionModal, visible: contractRegionModalVisible
  } = useModal();
  const {
    data: deleteContractRegionModalData, hide: hideDeleteContractRegionModal, show: showDeleteContractRegionModal, visible: deleteContractRegionModalVisible
  } = useModal();

  /**
   *
   * @example
   */
  async function handleRefresh() {
    try {
      setIsRefreshing(true);

      await API.get(`/projects/${id}/search-contracts`);

      await mutateProject();
      await mutate();
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setIsRefreshing(false);
    }
  }

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <NewContractRegionModal hide={hideNewContractRegionModal} mutateProject={mutateProject} projectId={project?.id} visible={contractRegionModalVisible} />

      <DeleteContractRegionModal contractRegion={deleteContractRegionModalData} hide={hideDeleteContractRegionModal} mutateContracts={mutate} mutateProject={mutateProject} visible={deleteContractRegionModalVisible} />

      <div className="flex items-start justify-between">
        <h2 className="text-xl font-medium text-gray-800">Kaufverträge</h2>

        <div className="flex flex-col justify-between gap-2">
          <div className="flex items-center justify-end space-x-2">
            <p className="text-xs">in Verwendung</p>

            <div className="flex w-5 items-center justify-center rounded bg-green-300 py-0.5 text-green-700">
              <CircleCheckIcon className="size-3.5" />
            </div>
          </div>

          <div className="flex items-center justify-end space-x-2">
            <p className="text-xs">Änderungen vorhanden</p>

            <div className="flex w-5 items-center justify-center rounded bg-amber-300 py-0.5 text-amber-700">
              <CircleCheckIcon className="size-3.5" />
            </div>
          </div>

          <div className="flex items-center justify-end space-x-2">
            <p className="text-xs">nicht in Verwendung</p>

            <div className="flex w-5 items-center justify-center rounded bg-pink-300 py-0.5 text-pink-700">
              <CircleXIcon className="size-3.5" />
            </div>
          </div>

        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex items-start space-x-6">
          {project?.contract_regions.length > 0 && (
            <div className="flex flex-col items-start gap-4">
              <button
                disabled={isRefreshing}
                onClick={handleRefresh}
                type="button"
                className={cn("inline-flex justify-center items-center w-32 h-8 border border-transparent shadow-sm text-base font-medium rounded text-white bg-gray-700 focus:outline-none focus:ring-0", {
                  "hover:bg-gray-800": !isRefreshing
                })}
              >
                {!isRefreshing &&
                  <span>Aktualisieren</span>}

                <RefreshCcwIcon
                  className={cn("w-5 h-5", {
                    "animate-spin cursor-not-allowed": isRefreshing,
                    hidden: !isRefreshing
                  })}
                />
              </button>

              <p className="mt-2 text-sm">
                Letztes Update:
                <span className="ml-1 rounded-full bg-gray-300 px-3 py-1 font-medium text-gray-900">
                  {project?.last_contract_update
                    ? formatDate({
                      date: project?.last_contract_update,
                      withTime: true,
                      withWords: true
                    })
                    : "kein Update vorhanden"}
                </span>
              </p>
            </div>
          )}
        </div>

        <div className="col-span-2">
          <div className="flex justify-between">
            <h2 className="text-lg font-medium text-gray-800">KG/EZ Suche</h2>

            <button className="focus:outline-none" onClick={() => showNewContractRegionModal(project)} type="button">
              <Grid2x2PlusIcon className="ml-1 size-6 hover:text-gray-500" />
            </button>
          </div>

          <ul className="mt-0.5">
            {project?.contract_regions.map((contractRegion, index) => (
              <div className="grid grid-cols-6 space-x-4 text-sm" key={`contract_region_${index}`}>
                <p className="col-span-5">KG: {contractRegion.kgnr}, EZ: {contractRegion.ez}</p>

                <div className="col-span-1 flex items-center">
                  <button className="focus:outline-none" onClick={() => showDeleteContractRegionModal(contractRegion)} type="button">
                    <Trash2Icon className="ml-1 size-4 hover:text-gray-500" />
                  </button>
                </div>
              </div>
            ))}
          </ul>
        </div>
      </div>

      <ProjectContractList contracts={contracts} project={project} />

    </div>
  );
};

export default ProjectContractsPage;
