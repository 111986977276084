import Handler from "~/src/modules/handler.js";

const {
  handleCreate
} = new Handler({
  endpoint: "/project-contract-regions",

  afterEvery: ({ setIsSubmitting }) => {
    setIsSubmitting(false);
  },
  afterEveryError: (error, { showError }) => {
    showError();
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data, { projectId }) => ({
    contractRegion: {
      ez: data.ez,
      kgnr: data.kgnr,
      project_id: projectId
    }
  })
});

export {
  handleCreate
};
