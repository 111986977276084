/**
 *
 * @param data
 * @example
 */
const formatClipboardText = (data) => (data.street?.id
  ? `${data.street.label.replace(/ \((.*?)\)$/u, data.housenumber === "" ? ", $1" : ` ${data.housenumber}, $1`)}`
  : "");

export { formatClipboardText };

/**
 *
 * @param value
 * @param helpers
 * @example
 */
const coordinatesValidation = (value, helpers) => {
  if (value === "") {
    return value;
  }

  const coords = value.split(", ");

  if (coords.length !== 2) {
    return helpers.error("any.invalid");
  }

  for (const coordinate of coords) {
    const float = Number.parseFloat(coordinate);

    if (coordinate === "" || isNaN(float)) {
      helpers.error("any.invalid"); continue;
    }
  }

  return value;
};

export { coordinatesValidation };
