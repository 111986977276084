import Select from "~/src/ui/forms/select/index.jsx";

import roleOptions from "./options.js";

/**
 *
 * @param properties
 * @example
 */
const RoleSelect = (properties) => (
  <Select
    options={roleOptions}
    placeholder="Rolle"
    {...properties}
  />
);

export default RoleSelect;
