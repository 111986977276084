import { PencilIcon } from "lucide-react";

/**
 *
 * @param props0 - The root object
 * @param props0.className - The root object
 * @example
 */
const EditIcon = ({ className }) => (
  <PencilIcon className={className} />
);

export default EditIcon;
