import cn from "classnames";
import { PlusIcon } from "lucide-react";

import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DateInput from "~/src/ui/forms/date-input/index.jsx";
import InlineActionInput from "~/src/ui/forms/inline-action-input/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.jsx";
import InlineInput from "~/src/ui/forms/inline-input/index.jsx";
import InputArea from "~/src/ui/forms/input-area/index.jsx";
import NumberInput from "~/src/ui/forms/number-input/index.jsx";
import Select from "~/src/ui/forms/select/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import CompanySelect from "~/src/ui/selects/company-select/index.jsx";
import KGSelect from "~/src/ui/selects/kg-select/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleCreate } from "./handlers.js";
import { initialValues, schema } from "./schema.js";

/**
 *
 * @param root0 - The root object
 * @param root0.projectId - The root object
 * @param root0.visible - The root object
 * @param root0.hide - The root object
 * @param root0.showNewCompany - The root object
 * @example
 */
const NewTransactionSlide = ({
  hide, projectId, showNewCompany, visible
}) => {
  const { mutate, transactionCategories } = useProject(projectId);
  const { showError } = useNotification();

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleCreate({
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate,
        projectId,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  const sellerPrivate = form.values?.sellerPrivate === true || false;
  const buyerPrivate = form.values?.buyerPrivate === true || false;
  const isBaurecht = form.values.transactionCategory?.code === "baurecht";

  return (
    <SlideOver
      visible={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <form autoComplete="off" className="relative h-full" onSubmit={(e) => e.preventDefault()}>
        <div className="flex h-full flex-col bg-white shadow-xl">
          <div className="overflow-y-scroll pb-20">
            <div className="flex-1">

              <SlideFormHeader
                closeDisabled={form.isLoading}
                title="Neuer Liegenschaftsankauf"
                handleHide={() => {
                  handleHide(form);
                }}
              />

              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="usable">
                    Aktiv <br />(für GKA verwenden)
                  </label>
                </div>

                <div className="sm:col-span-2">
                  <InlineCheckbox
                    error={form.errors.usable}
                    id="usable"
                    isSubmitting={form.isSubmitting}
                    label="Aktiv"
                    name="usable"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    touched={form.touched.usable}
                    value={form.values.usable}
                  />
                </div>
              </div>

              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="historic">
                    Historischer Liegenschaftsankauf
                  </label>
                </div>

                <div className="sm:col-span-2">
                  <InlineCheckbox
                    error={form.errors.historic}
                    id="historic"
                    isSubmitting={form.isSubmitting}
                    label="Ja"
                    name="historic"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    touched={form.touched.historic}
                    value={form.values.historic}
                  />
                </div>
              </div>

              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="kvId">
                    Interne Sortierung
                  </label>
                </div>

                <div className="sm:col-span-2">
                  <InlineInput
                    dotSeperator
                    error={form.errors.sorting}
                    id="sorting"
                    isSubmitting={form.isSubmitting}
                    name="sorting"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    placeholder="Sortierung"
                    touched={form.touched.sorting}
                    value={form.values.sorting}
                  />
                </div>
              </div>

              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="kvUrl">
                    KV-Detail Link
                  </label>
                </div>

                <div className="sm:col-span-2">
                  <InlineInput
                    error={form.errors.kvUrl}
                    id="kvUrl"
                    isSubmitting={form.isSubmitting}
                    name="kvUrl"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    placeholder="KV-Detail Link"
                    touched={form.touched.kvUrl}
                    value={form.values.kvUrl}
                  />
                </div>
              </div>

              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="kvId">
                    KV-Detail ID
                  </label>
                </div>

                <div className="sm:col-span-2">
                  <InlineInput
                    error={form.errors.kvId}
                    id="kvId"
                    isSubmitting={form.isSubmitting}
                    name="kvId"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    placeholder="KV-Detail ID"
                    touched={form.touched.kvId}
                    value={form.values.kvId}
                  />
                </div>
              </div>

              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="transactionDate">
                      Vertragsdatum &#42;
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <DateInput
                      error={form.errors.transactionDate}
                      id="transactionDate"
                      isSubmitting={form.isSubmitting}
                      minDate={new Date(1_770, 0, 1)}
                      name="transactionDate"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      placeholder="Vertragsdatum"
                      touched={form.touched.transactionDate}
                      value={form.values.transactionDate ? new Date(form.values.transactionDate) : null}
                    />

                  </div>
                </div>
              </div>

              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="transactionCategory">
                      Objektkategorie &#42;
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <Select
                      className="col-span-4 sm:col-span-1"
                      customValueKey="body"
                      error={form.errors.transactionCategory}
                      isSubmitting={form.isSubmitting}
                      label="Objektkategorie"
                      name="transactionCategory"
                      onChange={form.handleChange}
                      placeholder="Objektkategorie..."
                      touched={form.touched.transactionCategory}
                      options={transactionCategories.map((cat) => (
                        {
                          body: {
                            id: cat.id,
                            code: cat.code,
                            name: cat.name
                          },
                          label: cat.name,
                          value: cat.id
                        }
                      ))}
                      {...(form.values.transactionCategory.code !== "" && {
                        defaultValue: {
                          label: form.values.transactionCategory.name,
                          value: form.values.transactionCategory.id
                        }
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="kgNumber">
                      KG &#42;
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <KGSelect
                      error={form.errors.katastralgemeinde}
                      isSubmitting={form.isSubmitting}
                      onChange={form.handleChange}
                      value={form.values.katastralgemeinde}
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="tzNumber">
                      TZ &#42; / TZ Jahr &#42;
                    </label>
                  </div>

                  <div className="sm:col-span-1">
                    <NumberInput
                      int
                      error={form.errors.tzNumber}
                      id="tzNumber"
                      isSubmitting={form.isSubmitting}
                      name="tzNumber"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      placeholder="TZ"
                      thousandSeparator=""
                      touched={form.touched.tzNumber}
                      value={form.values.tzNumber}
                    />
                  </div>

                  <div className="sm:col-span-1">
                    <DateInput
                      error={form.errors.tzYear}
                      format="year"
                      id="tzYear"
                      isSubmitting={form.isSubmitting}
                      name="tzYear"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      placeholder="Datum"
                      touched={form.touched.tzYear}
                      value={form.values.tzYear ? new Date(form.values.tzYear) : null}
                    />
                  </div>
                </div>
              </div>

              {!isBaurecht && (
                <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="ezNumbers.0">
                        Einlagezahlen &#42;
                        {form.errors.ezNumbers?.message
                          ? (
                            <svg className="ml-1 size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                              <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
                            </svg>
                          )
                          : null}
                      </label>

                      <div>
                        <button className="text-xs font-medium text-gray-500" onClick={() => form.push("ezNumbers", "")} type="button">Hinzufügen</button>
                      </div>
                    </div>

                    <div className="space-y-4 sm:col-span-2">
                      {form.values.ezNumbers.map((ez, index) => (
                        <div key={`ezNumbers_${index}`}>

                          <InlineActionInput
                            error={form.errors.ezNumbers ? form.errors.ezNumbers[index] : null}
                            id={`ezNumbers.${index}`}
                            isSubmitting={form.isSubmitting}
                            name={`ezNumbers.${index}`}
                            onAction={() => form.remove("ezNumbers", index)}
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            showAction={index > 0}
                            touched={form.touched.ezNumbers ? form.touched.ezNumbers[index] : null}
                            value={form.values.ezNumbers ? form.values.ezNumbers[index] : null}
                          />
                        </div>
                      ))}

                      {form.values.ezNumbers.length === 0 && (
                        <div className="flex h-full items-center">
                          <div>
                            <p className="text-sm text-gray-500">Keine Einträge</p>

                            {form.errors.ezNumbers?.message ? <p className="text-sm text-red-500">{form.errors.ezNumbers?.message}</p> : null}
                          </div>
                        </div>
                      )}

                      {form.errors.ezNumbers?.message && form.values.ezNumbers.length > 0 ? <p className="text-sm text-red-500">{form.errors.ezNumbers?.message}</p> : null}

                    </div>

                  </div>
                </div>
              )}

              {isBaurecht
                ? (
                  <>
                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="brez">
                            BREZ / EZ Stammliegenschaft
                          </label>
                        </div>

                        <div className="sm:col-span-1">
                          <NumberInput
                            int
                            error={form.errors.brez}
                            id="brez"
                            isSubmitting={form.isSubmitting}
                            name="brez"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            placeholder="BREZ"
                            thousandSeparator=""
                            touched={form.touched.brez}
                            value={form.values.brez}
                          />
                        </div>

                        <div className="sm:col-span-1">
                          <NumberInput
                            int
                            error={form.errors.br_stamm_ez}
                            id="br_stamm_ez"
                            isSubmitting={form.isSubmitting}
                            name="br_stamm_ez"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            placeholder="EZ Stammliegenschaft"
                            thousandSeparator=""
                            touched={form.touched.br_stamm_ez}
                            value={form.values.br_stamm_ez}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="baurechtsdauer">
                            Baurechtsdauer / Baurecht bis
                          </label>
                        </div>

                        <div className="sm:col-span-1">
                          <NumberInput
                            int
                            error={form.errors.baurechtsdauer}
                            id="baurechtsdauer"
                            isSubmitting={form.isSubmitting}
                            name="baurechtsdauer"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            placeholder="Baurechtsdauer"
                            thousandSeparator=""
                            touched={form.touched.baurechtsdauer}
                            value={form.values.baurechtsdauer}
                          />
                        </div>

                        <div className="sm:col-span-1">
                          <DateInput
                            error={form.errors.baurechtsdauer_date}
                            id="baurechtsdauer_date"
                            isSubmitting={form.isSubmitting}
                            name="baurechtsdauer_date"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            placeholder="Baurecht bis"
                            touched={form.touched.baurechtsdauer_date}
                            value={form.values.baurechtsdauer_date ? new Date(form.values.baurechtsdauer_date) : null}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="bauzins_net">
                            Bauzins (monatlich) netto / brutto
                          </label>
                        </div>

                        <div className="sm:col-span-1">
                          <NumberInput
                            error={form.errors.bauzins_net}
                            id="bauzins_net"
                            isSubmitting={form.isSubmitting}
                            name="bauzins_net"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            placeholder="Bauzins netto"
                            touched={form.touched.bauzins_net}
                            value={form.values.bauzins_net}
                          />
                        </div>

                        <div className="sm:col-span-1">
                          <NumberInput
                            error={form.errors.bauzins_gross}
                            id="bauzins_gross"
                            isSubmitting={form.isSubmitting}
                            name="bauzins_gross"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            placeholder="Bauzins brutto"
                            touched={form.touched.bauzins_gross}
                            value={form.values.bauzins_gross}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )
                : null}

              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="gstNumbers.0">
                      GST-Nr. &#42;
                      {form.errors.gstNumbers?.message
                        ? (
                          <svg className="ml-1 size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                            <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
                          </svg>
                        )
                        : null}
                    </label>

                    <div className="">
                      <button className="text-xs font-medium text-gray-500" onClick={() => form.push("gstNumbers", "")} type="button">Hinzufügen</button>
                    </div>
                  </div>

                  <div className="space-y-4 sm:col-span-2">
                    {form.values.gstNumbers.map((gst, index) => (
                      <div key={`gstNumbers_${index}`}>

                        <InlineActionInput
                          error={form.errors.gstNumbers ? form.errors.gstNumbers[index] : null}
                          id={`gstNumbers.${index}`}
                          isSubmitting={form.isSubmitting}
                          name={`gstNumbers.${index}`}
                          onAction={() => form.remove("gstNumbers", index)}
                          onBlur={form.handleBlur}
                          onChange={form.handleChange}
                          touched={form.touched.gstNumbers ? form.touched.gstNumbers[index] : null}
                          value={form.values.gstNumbers ? form.values.gstNumbers[index] : null}
                        />
                      </div>
                    ))}

                    {form.values.gstNumbers.length === 0 && (
                      <div className="flex h-full items-center">
                        <div>
                          <p className="text-sm text-gray-500">Keine Einträge</p>

                          {form.errors.gstNumbers?.message ? <p className="text-sm text-red-500">{form.errors.gstNumbers?.message}</p> : null}
                        </div>
                      </div>
                    )}

                    {form.errors.gstNumbers?.message && form.values.gstNumbers.length > 0 ? <p className="text-sm text-red-500">{form.errors.gstNumbers?.message}</p> : null}

                  </div>

                </div>
              </div>

              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="propertyArea">
                      GST-Fläche &#42;
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <NumberInput
                      area
                      error={form.errors.propertyArea}
                      id="propertyArea"
                      isSubmitting={form.isSubmitting}
                      name="propertyArea"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      placeholder="GST-Fläche"
                      touched={form.touched.propertyArea}
                      value={form.values.propertyArea}
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="buildArea">
                      Baufläche Gebäude / begrünt
                    </label>
                  </div>

                  <div className="sm:col-span-1">
                    <NumberInput
                      area
                      error={form.errors.buildArea}
                      id="buildArea"
                      isSubmitting={form.isSubmitting}
                      name="buildArea"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      placeholder="Baufläche (Gebäude)"
                      touched={form.touched.buildArea}
                      value={form.values.buildArea}
                    />
                  </div>

                  <div className="sm:col-span-1">
                    <NumberInput
                      area
                      error={form.errors.greenArea}
                      id="greenArea"
                      isSubmitting={form.isSubmitting}
                      name="greenArea"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      placeholder="Baufläche (begrünt)"
                      touched={form.touched.greenArea}
                      value={form.values.greenArea}
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="usableArea">
                      Nutzfläche
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <NumberInput
                      area
                      error={form.errors.usableArea}
                      id="usableArea"
                      isSubmitting={form.isSubmitting}
                      name="usableArea"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      placeholder="Nutzfläche"
                      touched={form.touched.usableArea}
                      value={form.values.usableArea}
                    />
                  </div>
                </div>
              </div>

              {!isBaurecht && (
                <>
                  <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                    <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="salePriceNet">
                          Kaufpreis netto &#42; / brutto
                        </label>
                      </div>

                      <div className="sm:col-span-1">
                        <NumberInput
                          error={form.errors.salePriceNet}
                          id="salePriceNet"
                          isSubmitting={form.isSubmitting}
                          name="salePriceNet"
                          onBlur={form.handleBlur}
                          onChange={form.handleChange}
                          placeholder="Kaufpreis netto *"
                          touched={form.touched.salePriceNet}
                          value={form.values.salePriceNet}
                        />
                      </div>

                      <div className="sm:col-span-1">
                        <NumberInput
                          error={form.errors.salePriceGross}
                          id="salePriceGross"
                          isSubmitting={form.isSubmitting}
                          name="salePriceGross"
                          onBlur={form.handleBlur}
                          onChange={form.handleChange}
                          placeholder="Kaufpreis brutto"
                          touched={form.touched.salePriceGross}
                          value={form.values.salePriceGross}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                    <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="shares">
                          Anteile &#42;
                        </label>
                      </div>

                      <div className="sm:col-span-2">
                        <InlineInput
                          error={form.errors.shares}
                          id="shares"
                          isSubmitting={form.isSubmitting}
                          name="shares"
                          onBlur={form.handleBlur}
                          onChange={form.handleChange}
                          placeholder="Anteile"
                          touched={form.touched.shares}
                          value={form.values.shares}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="notes">
                      Bemerkungen
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <InputArea
                      error={form.errors.notes}
                      id="notes"
                      isSubmitting={form.isSubmitting}
                      name="notes"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      placeholder="Bemerkungen"
                      touched={form.touched.notes}
                      value={form.values.notes}
                    />
                  </div>
                </div>
              </div>

              <div
                className={cn("py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200", {
                  "bg-gray-200 m-4": !sellerPrivate
                })}
              >
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="sellerCode">
                      {isBaurecht ? "Baurechtsgeber" : "Verkäufer"}
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <InlineCheckbox
                      error={form.errors.sellerPrivate}
                      id="sellerPrivate"
                      isSubmitting={form.isSubmitting}
                      label="Privat"
                      name="sellerPrivate"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      touched={form.touched.sellerPrivate}
                      value={form.values.sellerPrivate}
                    />
                  </div>
                </div>

                {!sellerPrivate && (
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="seller">
                        Unternehmen
                      </label>

                      <div className="mt-1">
                        <button onClick={showNewCompany} type="button">
                          <PlusIcon className="h-5 w-10 bg-gray-700 text-white focus:outline-none" />
                        </button>
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <CompanySelect
                        error={form.errors.seller}
                        id="seller"
                        isSubmitting={form.isSubmitting}
                        name="seller"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        touched={form.touched.seller}
                        value={form.values.seller}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div
                className={cn("mt-2 py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200", {
                  "bg-gray-200 m-4": !buyerPrivate
                })}
              >
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      {isBaurecht ? "Bauberechtigter" : "Käufer"}
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <InlineCheckbox
                      error={form.errors.buyerPrivate}
                      id="buyerPrivate"
                      isSubmitting={form.isSubmitting}
                      label="Privat"
                      name="buyerPrivate"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      touched={form.touched.buyerPrivate}
                      value={form.values.buyerPrivate}
                    />
                  </div>
                </div>

                {!buyerPrivate && (
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="buyer">
                        Unternehmen
                      </label>

                      <div className="mt-1">
                        <button onClick={showNewCompany} type="button">
                          <PlusIcon className="h-5 w-10 bg-gray-700 text-white focus:outline-none" />
                        </button>
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <CompanySelect
                        error={form.errors.buyer}
                        id="buyer"
                        isSubmitting={form.isSubmitting}
                        name="buyer"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        touched={form.touched.buyer}
                        value={form.values.buyer}
                      />
                    </div>
                  </div>
                )}
              </div>

            </div>

          </div>

          <div className="mt-12 shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="flex justify-end space-x-3">
              <CancelButton
                disabled={form.isLoading}
                onClick={() => {
                  handleHide(form);
                }}
              />

              <SubmitButton isSubmitting={form.isLoading} onClick={form.handleSubmit}>
                Speichern
              </SubmitButton>
            </div>
          </div>

        </div>
      </form>
    </SlideOver>
  );
};

export default NewTransactionSlide;
