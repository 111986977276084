/**
 *
 * @example
 */
const ProjectIcon = () => (
  <svg className="mr-4 size-10 shrink-0 rounded border-2 border-gray-300 fill-current text-gray-500" fill="none" viewBox="0 0 20 20">
    <path d="M7.466 2.3a2.669 2.669 0 00-2.672 2.672c0 1.478 2.672 4.264 2.672 4.264s2.672-2.786 2.672-4.264A2.687 2.687 0 007.466 2.3zm0 3.866c-.682 0-1.251-.568-1.251-1.25 0-.683.569-1.251 1.25-1.251.683 0 1.252.568 1.252 1.25 0 .711-.569 1.251-1.251 1.251z" />

    <path d="M12.668 6.564H10.28a.498.498 0 00-.483.483c0 .256.227.483.483.483h1.421v8.7h-8.47v-8.7h1.42a.498.498 0 00.484-.483.498.498 0 00-.483-.483h-2.36v10.603H16.108a1.905 1.905 0 001.904-1.904V9.265h-5.344V6.564zm1.28 4.975h1.875v.966h-1.876v-.966zm0 2.36h1.875v.966h-1.876v-.967z" />

    <path d="M6.47 13.529h-.966v1.762h.967v-1.762zM6.47 9.975h-.966v1.763h.967V9.975zM9.427 13.529h-.966v1.762h.966v-1.762zM9.427 9.975h-.966v1.763h.966V9.975z" />
  </svg>
);

export default ProjectIcon;
