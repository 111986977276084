import {
  BooleanParam, DelimitedArrayParam, DelimitedNumericArrayParam, NumberParam, StringParam, useQueryParams, withDefault
} from "use-query-params";

/**
 *
 * @example
 */
const useAutomatedProjectQueryParameters = () => {
  const [query, setQuery] = useQueryParams({
    cityId: NumberParam,
    includeDisabled: BooleanParam,
    name: StringParam,
    page: withDefault(NumberParam, 1),
    sortFilter: withDefault(StringParam, "updated-desc"),
    state: StringParam,
    statusFilter: withDefault(StringParam, "all"),
    street: StringParam,
    synchronizedFilter: withDefault(StringParam, "all")
  });

  return {
    query,
    setQuery
  };
};

export default useAutomatedProjectQueryParameters;
