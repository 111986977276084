import SortIcon from "~/src/ui/icons/sort-icon.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.handleClick - The root object
 * @example
 */
const sortUserButton = ({ handleClick }) => (
  <button className="flex items-center text-xs font-bold uppercase tracking-wider focus:outline-none" onClick={handleClick}>
    <p>Email</p>

    <SortIcon />
  </button>
);

export default sortUserButton;
