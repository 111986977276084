import { isEqual } from "lodash-es";

import { removeFromArrayByIndex } from "~/src/_common/_exports.js";

import API from "~/src/modules/api.js";

import {
  getIssuerCertificateInitialValues,
  path
} from "../schema.js";

const setIssuerCertificatesInForm = ({
  issuerCertificatesCache: { data: certificatesFromCache },
  reactForm
}) => {
  const certificatesFromForm = reactForm.getValues("issuerCertificates");

  const cacheIsSet = certificatesFromCache;

  if (!cacheIsSet) {
    return;
  }

  const cacheAndFormValuesEqual = isEqual(certificatesFromCache, certificatesFromForm);

  if (cacheAndFormValuesEqual) {
    return;
  }

  if (!certificateListOfFormIsEdited({
    certificatesFromCache,
    certificatesFromForm
  })) {
    reactForm.setValue("issuerCertificates", certificatesFromCache);

    const hasCertificates = certificatesFromCache.length > 0;

    reactForm.setValue("has_certificates", hasCertificates);
  }
};

/**
 *
 * @param data
 * @example
 */
function onIssuerCertificateResponse(data) {
  const { setValue } = this;
  const issuerCertificates = data.payload.issuer_certificates;

  setValue("issuerCertificates", issuerCertificates);
  setValue("has_certificates", issuerCertificates.length > 0);
}

const removeCertificateFromForm = ({
  issuerCertificateIndex: indexToRemove,
  modal: { data: modalState, show: rerenderModal },
  reactForm: { getValues, setValue }
}) => {
  const certificateList = getValues("issuerCertificates");
  const newCertificatesList = (
    removeFromArrayByIndex(certificateList, Number(indexToRemove))
  );

  setValue("issuerCertificates", newCertificatesList);
  rerenderModal({
    ...modalState,
    certificatesCount: newCertificatesList.length
  });
};

const addNewIssuerCertificateEntry = ({
  modal: { data: modalState, show: rerenderModal },
  reactForm: { getValues, setValue }
}) => {
  const certificateList = getValues("issuerCertificates");
  const newCertificatesList = [...certificateList, getIssuerCertificateInitialValues()];

  setValue("issuerCertificates", newCertificatesList);
  rerenderModal({
    ...modalState,
    certificatesCount: newCertificatesList.length
  });
};

const certificateListOfFormIsEdited = ({
  certificatesFromCache: certsInCache,
  certificatesFromForm: certsInForm
}) => {
  const listOfFormNotDefinedYet = !certsInForm;

  if (listOfFormNotDefinedYet) {
    return false;
  }

  const newEntriesWereAdded = (
    certsInForm.length && certsInForm.some((certificate) => !certificate.id)
  );

  if (newEntriesWereAdded) {
    return true;
  }

  const elseEntriesWereRemoved = certsInForm.length !== certsInCache.length;

  if (elseEntriesWereRemoved) {
    return true;
  }

  const elseEntriesWereModified = !isEqual(certsInForm, certsInCache);

  if (elseEntriesWereModified) {
    return true;
  }

  return false;
};

const setValuesOnShowForm = ({ issuer, reactForm }) => {
  if (!reactForm) {
    return;
  }

  if (!issuer?.id) {
    reactForm.setValue("has_certificates", false);
  }

  reactForm.setValue("searchable", issuer?.searchable);
  reactForm.setValue("name", issuer?.name);
  reactForm.setValue("issuerCertificates", null);
  reactForm.setValue("has_points", issuer?.has_points);
  reactForm.setValue("max_points", issuer?.max_points);
  reactForm.setValue("internal_notes", issuer?.internal_notes);
};

const handleClose = ({ modal, reactForm }) => {
  reactForm.clearErrors();
  modal.hide(null, {
    useArg: false,
    useTimeout: false
  });
};

const issuerNameIsUnique = (issuer, issuerId, allIssuers) => !allIssuers.some(
  (anyIssuer) => anyIssuer.id !== issuerId && anyIssuer.name === issuer.name
);

/**
 *
 * @param data
 * @example
 */
async function onInvalidSubmit(data) {
  console.log("[debug] submit not valid", {
    data,
    modal: this.modal
  });
}

/**
 *
 * @param issuer
 * @example
 */
async function onValidSubmit(issuer) {
  const {
    modal,
    mutateIssuerCertificates,
    mutateIssuers,
    reactForm,
    setIsDuringSubmit
  } = this;
  const {
    id: issuerId, issuersTempFrontEndValidation: issuersForTemporaryValidation
  } = modal.data;

  const issuerNameConflictMessage = "Aussteller existiert bereits";
  const isInEsgPage = issuersForTemporaryValidation;
  const clientSideIssuerUniquenessValidationUsedAndFailed = (
    isInEsgPage &&
    !issuerNameIsUnique(issuer, issuerId, issuersForTemporaryValidation)
  );

  if (clientSideIssuerUniquenessValidationUsedAndFailed) {
    reactForm.setError("name", { message: issuerNameConflictMessage });

    return;
  }

  if (!issuer.has_certificates) {
    issuer.issuerCertificates = [];
  }
  if (!issuer.has_points) {
    issuer.max_points = null;
  }

  this.setIsDuringSubmit(true);

  try {
    await (issuerId
      ? API.patch(path.issuers, {
        issuer: {
          id: issuerId,
          ...issuer
        }
      })
      : API.post(path.issuers, { issuer }));
  }
  catch (error) {
    if (error?.response?.status === 409) {
      reactForm.setError("name", { message: issuerNameConflictMessage });
    }
    else {
      reactForm.setError("name", { message: "Da war ein Fehler. Versuchen Sie es erneut." });
    }
    this.setIsDuringSubmit(false);

    return;
  }

  await mutateIssuerCertificates?.();
  await mutateIssuers();
  handleClose({
    modal,
    reactForm
  });
  this.setIsDuringSubmit(false);
}

export {
  addNewIssuerCertificateEntry,
  handleClose,
  onInvalidSubmit,
  onIssuerCertificateResponse,
  onValidSubmit,
  removeCertificateFromForm,
  setIssuerCertificatesInForm,
  setValuesOnShowForm
};
