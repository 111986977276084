const precision = (number) => {
  if (!isFinite(number)) {
    return 0;
  }

  let exponent = 1;
  let currentPrecision = 0;

  while (Math.round(number * exponent) / exponent !== number) {
    exponent *= 10;
    currentPrecision++;
  }

  return currentPrecision;
};

export default precision;
