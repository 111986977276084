import Joi from "joi";

import { locationSchema } from "./_common/_exports.js";

const companyBuyerSchema = Joi.object({
  name: Joi.string().empty(""),
  regNumber: Joi.string().empty(""),

  coordinates: Joi.object({
    label: Joi.string(),
    lat: Joi.number(),
    lng: Joi.number()
  }).allow(null, ""),

  id: Joi.number().positive().empty("")
    .optional(),
  isZvr: Joi.boolean().default(false),
  noRegNumber: Joi.boolean().default(false),
  notes: Joi.string().empty(""),
  show: Joi.boolean().default(true)
})
  .append(locationSchema)
  .when(
    Joi.custom((companyBuyer, helpers) => {
      const nameIsEmpty = !companyBuyer.name;

      const otherValuesAreSet = Object.values(companyBuyer).some((value) => (typeof value === "string" && value !== "") || ((typeof value === "object" && value?.constructor === Object) && Object.keys(value).length > 0));

      if (companyBuyer && nameIsEmpty && otherValuesAreSet) {
        return helpers.error("any.required");
      }

      return companyBuyer;
    }),
    {
      otherwise: Joi.object({
        name: Joi.required()
      })
    }
  );

export default companyBuyerSchema;
