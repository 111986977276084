import cn from "classnames";

const Skeleton = ({
  className, columns = 4, rows = 8
}) => (
  <div className={cn("flex flex-col gap-12 w-full animate-pulse", className)}>
    {
      new Array(rows)
        .fill()
        .map((empty, rowIndex) => (
          <div className="flex gap-4" key={rowIndex}>
            {
              new Array(columns)
                .fill()
                .map((empty, columnIndex) => (
                  <div
                    className="h-4 w-full rounded bg-gray-300"
                    key={columnIndex}
                  />
                ))
            }
          </div>
        ))
    }
  </div>
);

export default Skeleton;
