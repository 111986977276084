import { useState } from "react";

import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";

import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import Modal from "~/src/ui/modals/index.jsx";

import { handleDelete } from "./handlers.js";
import { schema } from "./schema.js";

const DeleteBauteilModal = ({
  hide, mutateProject, part, visible
}) => {
  const [notDeletable, setNotDeletable] = useState(false);
  const { showError } = useNotification();
  const initialValues = {};

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    hide();
    setNotDeletable(false);
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleDelete({
        id: part.id,
        hide: () => {
          handleHide(form);
        },
        mutate: mutateProject,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError: (error) => {
          showError();
          if (error?.status === 405) {
            setNotDeletable(true);
          }
        }
      });
    },
    schema
  });

  return (
    <Modal
      show={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <div aria-labelledby="modal-headline" aria-modal="true" className="inline-block overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle" role="dialog">
        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
            type="button"
            onClick={() => {
              handleHide(form);
            }}
          >
            <span className="sr-only">hide</span>

            <svg aria-hidden="true" className="size-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
            </svg>
          </button>
        </div>

        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
            <svg aria-hidden="true" className="size-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
            </svg>
          </div>

          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-headline">
              Bauteil löschen
            </h3>

            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Sicher, dass {part?.name} gelöscht werden soll?
              </p>
            </div>

            {notDeletable
              ? (
                <div className="mt-4">
                  <p className="text-lg text-red-700">
                    Das Bauteil kann nicht gelöscht werden, solange es in Objekten verwendet wird!
                  </p>
                </div>
              )
              : null}
          </div>
        </div>

        <form autoComplete="off" onSubmit={form.handleSubmit}>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <FormSubmitButton className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" isSubmitting={form.isLoading}>
              Bauteil löschen
            </FormSubmitButton>

            <button
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              type="button"
              onClick={() => {
                handleHide(form);
              }}
            >
              Abbrechen
            </button>
          </div>
        </form>
      </div>

    </Modal>
  );
};

export default DeleteBauteilModal;
