import Joi from "joi";

const initialValues = {
  fileName: "",
  imageLandscape: true,
  showPublic: true
};

const schema = Joi.object({
  fileName: Joi.string().empty("").required(),
  imageLandscape: Joi.boolean().required(),
  showPublic: Joi.boolean().required()
});

export {
  initialValues,
  schema
};
