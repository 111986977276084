/**
 *
 * @param props0 - The root object
 * @param props0.isLoading - The root object
 * @param props0.project - The root object
 * @param props0.state - The root object
 * @example
 */
const ProjectName = ({
  isLoading, project, state
}) => {
  if (isLoading && state?.project) {
    return state.project?.name;
  }

  return project?.name || null;
};

export default ProjectName;
