/**
 *
 * @param props0 - The root object
 * @param props0.styles - The root object
 * @example
 */
const SVGIcon = ({ styles }) => (
  <svg className={`size-20 ${styles}`} fill="none" height={0} viewBox="0 0 512 512" width={0} >
    <path d="M367.31 53.246l59.065 59.251-.228.218-58.335.044-.545-59.469.043-.044zM208.748 146.766c0 11.242-6.28 16.552-15.919 16.476-1.319 0-2.792 0-3.696-.207v-30.782c.904-.207 2.442-.425 4.819-.425 9.137.001 14.862 5.169 14.796 14.938zM161.132 138.601c0 4.819-3.49 7.687-9.137 7.687-1.538 0-2.661-.076-3.566-.283v-13.892c.774-.207 2.235-.414 4.394-.414 5.31 0 8.309 2.573 8.309 6.902z" fill="#F1F1F1" />

    <path d="M125.65 94.625h183.182v105.766H86.397V94.625h39.253z" fill="#F1F1F1" />

    <path d="M281.182 254.998c.13 10.98-6.892 32.405-6.892 32.405-2.422-2.551-7.263-16.203-7.263-28.83 0-12.626 4.079-16.071 7.263-16.071 3.194 0 6.759 1.527 6.892 12.496zM357.638 350.755c0 14.502-26.278 8.591-42.438-6.794 0 0 42.438-7.698 42.438 6.794zM205.149 398.513c-13.008-7.785 27.303-31.763 34.576-32.525 0-.001-20.957 40.669-34.576 32.525zM280.418 311.26c7.394 13.772 22.069 29.975 22.069 29.975s-5.616.633-18.666 3.827c-13.053 3.194-19.605 6.51-19.605 6.51l5.354-11.994c5.364-11.995 10.848-28.318 10.848-28.318z" fill="#DC1D00" />

    <path d="M426.593 112.714V458.81H125.65V200.391h183.182V94.625H125.65V53.191h241.616l.043.054-.043.043.545 59.469 58.335-.044.229-.218.218.219z" fill="#DC1D00" />

    <path d="M239.725 365.987c-7.272.763-47.584 24.74-34.576 32.525 13.619 8.145 34.576-32.525 34.576-32.525zm75.475-22.026c16.159 15.385 42.438 21.296 42.438 6.794 0-14.492-42.438-6.794-42.438-6.794zm-12.714-2.726s-14.676-16.202-22.069-29.975c0 0-5.484 16.323-10.849 28.317l-5.354 11.994s6.553-3.315 19.605-6.51c13.051-3.193 18.667-3.826 18.667-3.826zm-28.196-53.832s7.021-21.425 6.892-32.405c-.133-10.969-3.697-12.496-6.892-12.496-3.184 0-7.263 3.445-7.263 16.071 0 12.627 4.841 26.28 7.263 28.83zm8.81 16.585c7.143 15.439 26.791 35.328 26.791 35.328s6.368-1.657 32.646-2.289c26.288-.633 27.434 14.152 27.553 15.428.131 1.287 1.157 13.02-19.768 13.782-20.913.764-42.611-21.044-42.611-21.044s-8.92 1.406-16.313 3.185c-7.393 1.787-31.13 8.297-31.13 8.297s-7.022 12.757-22.582 33.158c-15.57 20.412-33.005 18.886-37.978 12.124-5.779-7.85-1.319-15.688 9.65-25.896 10.969-10.205 36.277-18.667 36.277-18.667s5.103-9.181 12.583-26.703c7.479-17.522 13.139-35.121 13.139-35.121s-7.404-16.813-8.844-31.119c-1.613-15.952.175-26.67 12.42-26.801 12.246-.131 16.072 9.061 16.454 25.001.381 15.954-8.287 41.337-8.287 41.337z" fill="#fff" />

    <path d="M255 133.002v-9h-28v47h10v-19h17v-8h-17v-11h18zM212.094 128.689c-4.536-3.424-10.548-5.027-19.489-5.027-5.375 0-10.605.349-14.605.981v46.21c3 .349 6.787.697 12.304.697 9.214 0 16.871-1.952 21.821-6.073 4.536-3.838 7.874-10.054 7.874-19.06 0-8.296-3.009-14.097-7.905-17.728zm-19.832 34.554c-1.319 0-2.262 0-4.262-.207v-30.782c2-.207 3.008-.425 5.386-.425 9.137 0 14.861 5.168 14.795 14.938 0 11.242-6.281 16.552-15.919 16.476zM166.648 127.436c-3.14-2.519-8.259-3.772-14.824-3.772-6.488 0-11.824.425-14.824.981v46.357h11v-16.765c1 .142 2.449.218 3.846.218 6.28 0 11.762-1.538 15.394-4.961 2.791-2.649 4.381-6.564 4.381-11.165 0-4.612-1.975-8.516-4.973-10.893zm-14.869 18.853c-1.537 0-2.779-.076-3.779-.283v-13.892c1-.207 2.449-.414 4.607-.414 5.311 0 8.309 2.573 8.309 6.902 0 4.819-3.489 7.687-9.137 7.687z" fill="#A51600" />
  </svg>
);

export default SVGIcon;
