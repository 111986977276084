/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
const TabHeader = ({ children }) => (
  <h2 className="mb-4 text-xl font-medium text-gray-800">
    {children}
  </h2>
);

export default TabHeader;
