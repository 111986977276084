import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @example
 */
export default function useSyncStatus() {
  const {
    data, error, mutate
  } = useSWR("/status/project-sync", fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    syncStatus: data?.payload.syncStatus
  };
}
