import { labelsToFiltersLabels } from "./helpers.js";

export const roleLabels = {
  admin: "Administrator",
  editor: "Erfasser",
  inactive: "deaktiviert",
  moderator: "Moderator"
};

export const constructionPhaseLabels = {
  construction_phase_active: "In Bau",
  construction_phase_completed: "Bau abgeschlossen",
  construction_phase_purchase: "Liegenschaft/Baurecht erworben",
  unknown: "unbekannt"
};

export const marketingPhaseLabels = {
  marketing_phase_active: "In Vermarktung",
  marketing_phase_coming_soon: "Coming soon",
  marketing_phase_completed_all: "Vollständig vermarktet",
  marketing_phase_completed_apartments: "Wohneinheiten vermarktet",
  marketing_phase_project_finished: "Projekt abgeschlossen",
  unknown: "unbekannt"
};

export const marketingPhaseFilterLabels =
  labelsToFiltersLabels(marketingPhaseLabels);

export const constructionPhaseFilterLabels =
  labelsToFiltersLabels(constructionPhaseLabels);

export const researchFilterLabels = {
  "project-research-closed": "Projekt kontrolliert",
  "project-research-initial": "Projekt zu erfassen",
  "project-research-open": "Projekt zu kontrollieren",
  "unit-research-closed": "Objekte kontrolliert",
  "unit-research-initial": "Objekte zu erfassen",
  "unit-research-open": "Objekte zu kontrollieren"
};

export const sortFilters = [
  {
    label: "Zuletzt bearbeitet (neu > alt)",
    value: "updated-at-desc"
  },
  {
    label: "Zuletzt bearbeitet (alt > neu)",
    value: "updated-at-asc"
  },
  {
    label: "Bauteil/Stiege/Nummer aufsteigend",
    value: "position-asc"
  },
  {
    label: "Bauteil/Stiege/Nummer absteigend",
    value: "position-desc"
  },
  {
    label: "Preis/m² Angebot Eigenverbraucher aufsteigend",
    value: "avg-offer-price-asc"
  },
  {
    label: "Preis/m² Angebot Eigenverbraucher absteigend",
    value: "avg-offer-price-desc"
  },
  {
    label: "Preis/m² Angebot Investor aufsteigend",
    value: "avg-offer-price-investor-asc"
  },
  {
    label: "Preis/m² Angebot Investor absteigend",
    value: "avg-offer-price-investor-desc"
  },
  {
    label: "Preis/m² Angebot Miete aufsteigend",
    value: "avg-offer-rent-netto-asc"
  },
  {
    label: "Preis/m² Angebot Miete absteigend",
    value: "avg-offer-rent-netto-desc"
  },
  {
    label: "Preis/m² Verkauf aufsteigend",
    value: "avg-sale-price-asc"
  },
  {
    label: "Preis/m² Verkauf absteigend",
    value: "avg-sale-price-desc"
  },
  {
    label: "Nutzfläche Angebot aufsteigend",
    value: "offer-area-asc"
  },
  {
    label: "Nutzfläche Angebot absteigend",
    value: "offer-area-desc"
  },
  {
    label: "Nutzfläche Verkauf aufsteigend",
    value: "sale-area-asc"
  },
  {
    label: "Nutzfläche Verkauf absteigend",
    value: "sale-area-desc"
  }
];

export const statisticsLabels = new Map([
  ["avgOfferPriceBruttoInvestorPerMeter", <>Kaufpreis/m² Anleger <span className="text-emerald-400">inkl. USt</span></>],
  ["avgOfferPriceInvestorPerMeter", <>Kaufpreis/m² Anleger <span className="text-indigo-400">exkl. USt</span></>],

  ["avgOfferPricePerMeter", "Kaufpreis/m² Eigennutzer"],
  ["avgOfferRentBkPerMeter", <>Preis/m² Miete <span className="text-emerald-400">Gesamtbelastung</span></>],
  ["avgOfferRentBruttoPerMeter", <>Preis/m² Miete <span className="text-indigo-400">Netto + USt</span></>],
  ["avgOfferRentNettoPerMeter", "Preis/m² Miete Netto"],
  ["avgSalePriceGrossPerMeter", <>Verkaufspreis/m² <span className="text-emerald-400">inkl. USt</span></>],

  ["avgSalePricePerMeter", <>Verkaufspreis/m² <span className="text-indigo-400">exkl. USt</span></>],
  ["offerArea", "Fläche Angebot"],
  ["offerBalkonArea", "Balkon Fläche Angebot"],
  ["offerGartenArea", "Garten Fläche Angebot"],
  ["offerKellerArea", "Keller Fläche Angebot"],

  ["offerLoggiaArea", "Loggia Fläche Angebot"],
  ["offerPriceBruttoInvestor", <>Kaufpreis Anleger <span className="text-emerald-400">inkl. USt</span></>],
  ["offerPriceInvestor", <>Kaufpreis Anleger <span className="text-indigo-400">exkl. USt</span></>],

  ["offerPriceNormal", "Kaufpreis Eigennutzer"],
  ["offerTerrasseArea", "Terrasse Fläche Angebot"],
  ["rentBk", <>Miete <span className="text-emerald-400">Gesamtbelastung</span></>],

  ["rentBrutto", <>Miete <span className="text-indigo-400">Netto + USt</span></>],
  ["rentNetto", "Miete Netto"],

  ["saleArea", "Fläche Verkauf"],
  ["saleBalkonArea", "Balkon Fläche Verkauf"],

  ["saleGartenArea", "Garten Fläche Verkauf"],
  ["saleKellerArea", "Keller Fläche Verkauf"],

  ["saleLoggiaArea", "Loggia Fläche Verkauf"],
  ["salePriceGross", <>Verkaufspreis <span className="text-emerald-400">inkl. USt</span></>],

  ["salePriceNet", <>Verkaufspreis <span className="text-indigo-400">exkl. USt</span></>],
  ["saleTerrasseArea", "Terrasse Fläche Verkauf"]
]);

export const dateFormatLabels = new Map([
  ["day", "Tag"],
  ["month", "Monat"],
  ["quarter", "Quartal"],
  ["year", "Jahr"]
]);

export const genderLabels = new Map([
  ["female", "weiblich"],
  ["male", "männlich"],
  ["unknown", "unbekannt"]
]);

export const activityNameLabels = new Map([
  ["create", "erstellt"],
  ["delete", "gelöscht"],
  ["update", "bearbeitet"]
]);

export const activityTypeLabels = new Map([
  ["building-part", "Bauteil"],
  ["company-role", "Unternehmen"],
  ["construction-info", "Bauinformationen"],
  ["construction-type", "Bautypen"],
  ["einlage", "Projektliegenschaften"],
  ["energy-class", "Energieausweis"],
  ["project-certificate", "Gebäudezertifikate"],
  ["funding", "Förderungen"],
  ["location", "Adressen"],
  ["project", "Projekt"],
  ["automated-project", "Automatisiertes Projekt"],
  ["project-document", "Dokumente"],
  ["project-quantity", "Einheiten"],
  ["project-contract-region", "KG/EZ Suche"],
  ["timeline", "Timeline"],
  ["transaction", "Liegenschaftsankäufe"],
  ["unit-document", "Objekt-Dokumente"],
  ["unit", "Objekt"],
  ["widmung", "Flächenwidmungen"]
].sort(([keyA, labelA], [keyB, labelB]) => labelA.localeCompare(labelB)));

export const companyRoleLabels = new Map([
  ["builder", "Bauträger"],
  ["marketer", "Vermarkter"],
  ["owner", "Eigentümer"]
]);

export const statusFilterLabels = [
  {
    label: "Alle",
    value: "all"
  },
  {
    label: "Intern",
    value: "intern"
  },
  {
    label: "Öffentlich",
    value: "public"
  }
];

export const synchronizedFilterLabels = [
  {
    label: "Alle",
    value: "all"
  },
  {
    label: "Offene Änderungen",
    value: "outdated"
  },
  {
    label: "Synchronisert",
    value: "synchronized"
  }
];

export const sortFilterLabels = {
  historic: [
    {
      label: "Name (A-Z)",
      value: "name-asc"
    },
    {
      label: "Name (Z-A)",
      value: "name-desc"
    },
    {
      label: "Bearbeitung",
      value: "updated-desc"
    }
  ],
  nonHistoric: [
    {
      label: "Name (A-Z)",
      value: "name-asc"
    },
    {
      label: "Name (Z-A)",
      value: "name-desc"
    },
    {
      label: "Bearbeitung",
      value: "updated-desc"
    },
    {
      label: "Letzte Projektkontrolle",
      value: "last-project-review"
    },
    {
      label: "Letzte Objektkontrolle",
      value: "last-unit-review"
    }
  ],
  personNames: [
    {
      label: "Name (A-Z)",
      value: "name-asc"
    },
    {
      label: "Name (Z-A)",
      value: "name-desc"
    },
    {
      label: "Zuletzt bearbeitet (neu-alt)",
      value: "updated-at-desc"
    },
    {
      label: "Zuletzt bearbeitet (alt-neu)",
      value: "updated-at-asc"
    },
    {
      label: "Anzahl aufsteigend",
      value: "count-asc"
    },
    {
      label: "Anzahl absteigend",
      value: "count-desc"
    }
  ]
};

export const researchFilterOptionLabels = [
  {
    label: "-",
    value: "all"
  },
  {
    label: "Projekt zu erfassen",
    value: "project-research-initial"
  },
  {
    label: "Projekt zu kontrollieren",
    value: "project-research-open"
  },
  {
    label: "Projekt kontrolliert",
    value: "project-research-closed"
  },
  {
    label: "Objekte zu erfassen",
    value: "unit-research-initial"
  },
  {
    label: "Objekte zu kontrollieren",
    value: "unit-research-open"
  },
  {
    label: "Objekte kontrolliert",
    value: "unit-research-closed"
  }
];

export const fundingLabels = new Map([
  ["gemeinde", "Gemeindewohnungen"],
  ["normal_funded", "gefördert"],
  ["not_funded", "freifinanziert"],
  ["smart_funded", "SMART Wohnungen"],
  ["smart_super_funded", "SMART Superförderung"],
  ["super_funded", "Superförderung"],
  ["wiener_wohnbau", "Wiener Wohnbauinitiative"]
]);
