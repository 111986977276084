import cn from "classnames";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

/**
 *
 * @param props0 - The root object
 * @param props0.allowNegative - The root object
 * @param props0.area - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.control - The root object
 * @param props0.decimalSeparator - The root object
 * @param props0.disabled - The root object
 * @param props0.error - The root object
 * @param props0.errors - The root object
 * @param props0.inline - The root object
 * @param props0.int - The root object
 * @param props0.isAllowed - The root object
 * @param props0.isDetailsView - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.meter - The root object
 * @param props0.name - The root object
 * @param props0.noprefix - The root object
 * @param props0.placeholder - The root object
 * @param props0.register - The root object
 * @param props0.scale - The root object
 * @param props0.small - The root object
 * @param props0.thousandSeparator - The root object
 * @param props0.touched - The root object
 * @param props0.id - The root object
 * @param props0.errorClassName - The root object
 * @param props0.fixed - The root object
 * @example
 */
const NumberInput = ({
  allowNegative = false,
  area = false,
  autoFocus = false,
  className,
  control,
  decimalSeparator = ",",
  disabled,
  error,
  errorClassName = "",
  errors,
  fixed = false,
  inline,
  int = false,
  isAllowed,
  isDetailsView = false,
  isSubmitting,
  meter = false,
  name,
  noprefix = false,
  placeholder,
  register,
  scale = null,
  small = false,
  thousandSeparator = ".",
  touched,

  id = `${name}${Date.now()}`
}) => {
  if (isDetailsView) {
    disabled = true;
  }

  const showError = Boolean(errors[name]);

  let decimalScale = int ? 0 : 2;

  if (scale) {
    decimalScale = scale;
  }

  if (scale === "none") {
    decimalScale = undefined;
  }

  let prefix = "€ ";

  if (noprefix || int || area || meter) {
    prefix = "";
  }

  let suffix = "";

  if (area) {
    suffix = " m²";
  }
  if (meter) {
    suffix = " m";
  }

  return (
    <div className={cn("relative", className)}>
      <div className="relative">
        <Controller
          control={control}
          id={id}
          name={name}
          variant="outlined"
          render={({
            field: {
              onBlur, onChange, value
            }
          }) => (
            <NumericFormat
              {...{
                id,
                allowNegative,
                autoFocus,
                decimalScale,
                decimalSeparator,
                disabled,
                isAllowed,
                onBlur,
                placeholder,
                prefix,
                suffix,
                thousandSeparator: fixed ? false : thousandSeparator,
                value
              }}
              fixedDecimalScale
              valueIsNumericString
              className={cn(
                "block w-full border shadow-sm px-3 focus:outline-none placeholder-gray-300",
                {
                  "bg-gray-200 cursor-not-allowed": disabled && !isDetailsView,
                  "bg-white": !disabled,
                  "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError && !isDetailsView,
                  "border-red-500 focus:ring-red-700 focus:border-red-700": showError,
                  "rounded-sm shadow-sm h-10 sm:text-sm": !small,
                  "rounded shadow-sm h-8 text-sm": small,
                  "shadow-none px-0 border-none": isDetailsView,
                  "text-base": !small && isDetailsView
                }
              )}
              onValueChange={(c) => {
                if (fixed) {
                  if (c.value !== "" && !Number.isNaN(Number(c.value))) {
                    onChange(Number(c.value));
                  }
                }
                else {
                  onChange(c.value);
                }
              }}
            />
          )}
        />

        {showError
          ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <svg className="size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
              </svg>
            </div>
          )
          : null}
      </div>

      {
        showError
          ? (
            <p
              className={cn(
                "mt-2 text-sm text-red-600 col-span-3",
                { "absolute -bottom-6": inline },
                errorClassName
              )}
            >
              {errors[name].message}
            </p>
          )
          : null
      }
    </div>
  );
};

export default NumberInput;
