import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param cityId
 * @example
 */
const useCityId = (cityId) => {
  if (cityId) {
    const parameters = `q=${cityId}`;

    const {
      data, error, mutate
    } = useSWR(`/cities/search-by-city-id?${parameters}`, fetcher);

    return {
      city: data?.payload.cities[0],
      isError: error,
      isLoading: !error && !data,
      mutate
    };
  }

  return {
    city: undefined
  };
};

export default useCityId;
