import { useParams } from "react-router-dom";

import useAutomatedProject from "~/src/hooks/use-automated-project.js";

import Badge from "~/src/ui/badges/status-badge/index.jsx";

/**
 *
 * @example
 */
const AutomatedProjectPublishOverview = () => {
  const { id } = useParams();
  const { automated_project: project } = useAutomatedProject(id);

  return (
    <li className="col-span-2">
      <div className="flex w-full items-center justify-between space-x-6">
        <div className="flex-1 truncate">
          <div>
            <h3 className="truncate font-medium text-gray-700">Projektstatus</h3>

            <div className="mt-2 space-y-6">
              <div className="flex flex-col items-start gap-1">
                {project?.published
                  ? <Badge badgeFor="public" text="Öffentlich" />
                  : <Badge badgeFor="internal" text="Intern" />}

                {(project?.published && project?.unpublished_changes) ? <Badge badgeFor="changes" text="Offene Änderungen" /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li >
  );
};

export default AutomatedProjectPublishOverview;
