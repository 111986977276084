import {
  createContext, useCallback, useState
} from "react";

export const NotificationContext = createContext({
  error: null,
  hideError: () => {},
  hideSuccess: () => {},
  showError: () => {},
  showSuccess: () => {},
  success: null
});

const NotificationProvider = ({ children }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const showSuccess = () => setSuccess(true);
  const showError = () => setError(true);
  const hideSuccess = () => setSuccess(false);
  const hideError = () => setError(false);

  const contextValue = {
    error,
    hideError: useCallback(() => hideError(), []),
    hideSuccess: useCallback(() => hideSuccess(), []),
    showError: useCallback(() => showError(), []),
    showSuccess: useCallback(() => showSuccess(), []),
    success
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
