import * as cn from "classnames";

import API from "~/src/modules/api.js";
import { genderLabels } from "~/src/modules/labels.jsx";

import Button from "~/src/ui/buttons/button/index.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @param {object} props - The root object
 * @param {Temporal.Instant} props.lastMutate - The root object
 * @param {boolean} props.isLoading - The root object
 * @param {Function} props.mutate - The root object
 * @param {object[]} props.personNames - The root object
 * @param {Function} props.setLastMutate - The root object
 * @param props.showProjectIds - The root object
 * @example
 */
const PersonNamesList = ({
  lastMutate,
  isLoading,
  mutate,
  personNames,
  setLastMutate,
  showProjectIds
}) => {
  if (isLoading) {
    return <Skeleton />;
  }

  const patch = async (name, patchObject) => {
    const baseUrl = globalThis?.settings?.REACT_APP_API_ENDPOINT;

    const response = await fetch(
      `${baseUrl}/person-names/${encodeURIComponent(name)}`,
      {
        body: JSON.stringify({
          ...patchObject,
          lastMutate
        }),
        credentials: "include",
        headers: new Headers({
          "content-type": "application/json"
        }),
        method: "PATCH"
      }
    );

    if (response.status === 409) {
      alert(`${name} wurde bereits bearbeitet`);
    }

    await mutate();

    setLastMutate(Temporal.Now.instant());
  };

  return (
    <table className="flex w-full flex-col">
      <thead className="flex w-full">
        <tr className="grid w-full grid-cols-12 rounded-t-sm border-2 border-gray-200 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500">
          <th className="col-span-3">Name</th>

          <th className="col-span-4 text-center">Geschlecht</th>

          <th
            className={cn({
              "col-span-1": showProjectIds,
              "col-span-2": !showProjectIds
            })}
          >
            Anzahl
          </th>

          {
            showProjectIds
              ? (
                <th className="col-span-2">Projekt-IDs</th>
              )
              : null
          }

          <th className="col-span-2 text-right">Bearbeitet</th>
        </tr>
      </thead>

      <tbody className="rounded-b-sm border-x-2 border-b-2 border-gray-200 text-sm font-medium text-gray-500">
        {
          personNames
            .map(({
              count,
              gender,
              name,
              project_ids,
              updated_at
            }) => (
              <tr className="grid w-full grid-cols-12 items-center border-b-2 border-gray-200 px-4 py-3 last:border-0" key={name}>
                <td className="col-span-3">{name}</td>

                <td className="col-span-4 flex justify-center">
                  <div className="flex">
                    {
                      [...genderLabels]
                        .toSorted(([keyA], [keyB]) => {
                          const sorting = [
                            "male",
                            "female",
                            "unknown"
                          ];

                          return sorting.indexOf(keyA) - sorting.indexOf(keyB);
                        })
                        .map(([key, label], index) => (
                          <Button
                            key={key}
                            label={<span className="text-lg font-medium">{label[0]}</span>}
                            className={cn(
                              "w-12 focus:ring-offset-[-2px] border",
                              {
                                "-ml-px rounded-l-none": index === genderLabels.size - 1,
                                "-ml-px rounded-none": index !== 0 && index !== genderLabels.size - 1,
                                "bg-gray-700! border-transparent": gender === key,
                                "bg-white! text-gray-700! border-gray-700 hover:bg-gray-100!": gender !== key,
                                "rounded-r-none": index === 0
                              }
                            )}
                            onClick={() => {
                              patch(name, { gender: gender === key ? null : key });
                            }}
                          />
                        ))
                    }

                  </div>
                </td>

                <td
                  className={cn({
                    "col-span-1": showProjectIds,
                    "col-span-2": !showProjectIds
                  })}
                >
                  {count}
                </td>

                {
                  showProjectIds
                    ? (
                      <td className="col-span-2 flex gap-1">
                        {
                          project_ids
                            .slice(0, 4)
                            .map((id, index) => (
                              <span>
                                <a className="underline hover:text-gray-600" href={`/projects/${id}`} key={id}>{id}</a>

                                {index === project_ids.length - 1 ? null : ","}

                                {index === 3 && project_ids.length > 4 ? " ..." : null}
                              </span>
                            ))
                        }
                      </td>
                    )
                    : null
                }

                <td className="col-span-2 text-right">
                  {
                    Temporal.Instant.from(updated_at)
                      .toZonedDateTimeISO(Temporal.Now.timeZoneId())
                      .toLocaleString(
                        "de-AT",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric"
                        }
                      )
                  }
                </td>
              </tr>
            ))
        }
      </tbody>
    </table>
  );
};

export default PersonNamesList;
