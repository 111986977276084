import Joi from "joi";

const initialValues = {
  name: "",
  sorting: ""
};

const schema = Joi.object({
  name: Joi.string().empty("").max(30)
    .required(),
  sorting: Joi.number().positive().precision(4)
    .empty("")
    .required()
});

export {
  initialValues,
  schema
};
