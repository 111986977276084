import Joi from "joi";

export const initialValues = {
  email: "",
  password: ""
};

export const schema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).empty("")
    .required(),
  password: Joi.string().empty("").required()
});
