const options = [
  {
    label: "unbekannt",
    value: "unknown"
  },
  {
    label: "Coming soon",
    value: "marketing_phase_coming_soon"
  },
  {
    label: "In Vermarktung",
    value: "marketing_phase_active"
  },
  {
    label: "Wohneinheiten vermarktet",
    value: "marketing_phase_completed_apartments"
  },
  {
    label: "Vollständig vermarktet",
    value: "marketing_phase_completed_all"
  },
  {
    label: "Projekt abgeschlossen",
    value: "marketing_phase_project_finished"
  }
];

export default options;
