/**
 *
 * @param props0 - The root object
 * @param props0.color - The root object
 * @param props0.size - The root object
 * @example
 */
const AlertIcon = ({ color = "text-red-600", size = "h-6 w-6" }) => (
  <svg className={`${size} ${color}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
  </svg>
);

export default AlertIcon;
