import { useParams } from "react-router-dom";

import AutomatedProjectEinlagen from "~/src/features/automated-project-overview/automated-project-einlagen/index.jsx";
import AutomatedProjectEnabled from "~/src/features/automated-project-overview/automated-project-enabled/index.jsx";
import AutomatedProjectLocations from "~/src/features/automated-project-overview/automated-project-locations/index.jsx";
import AutomatedProjectName from "~/src/features/automated-project-overview/automated-project-name/index.jsx";
import AutomatedProjectNameAdditional from "~/src/features/automated-project-overview/automated-project-name-additional/index.jsx";
import AutomatedProjectNotes from "~/src/features/automated-project-overview/automated-project-notes/index.jsx";
import AutomatedProjectOwners from "~/src/features/automated-project-overview/automated-project-owners/index.jsx";
import AutomatedProjectPublishOverview from "~/src/features/automated-project-overview/automated-project-publish-overview/index.jsx";
import AutomatedProjectQuantities from "~/src/features/automated-project-overview/automated-project-quantities/index.jsx";
import AutomatedProjectRechtsform from "~/src/features/automated-project-overview/automated-project-rechtsform/index.jsx";
import AutomatedProjectTimeline from "~/src/features/automated-project-overview/automated-project-timeline/index.jsx";
import AutomatedPublicProjectNotes from "~/src/features/automated-project-overview/automated-public-project-notes/index.jsx";

import useAutomatedProject from "~/src/hooks/use-automated-project.js";

import TabHeader from "~/src/ui/headers/tab-header/index.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @example
 */
const AutomatedProjectOverviewPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useAutomatedProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return <Skeleton />;
  }

  return (
    <>
      <TabHeader>Übersicht</TabHeader>

      <ul className="mt-12 grid grid-cols-12 gap-2">
        <AutomatedProjectPublishOverview />
      </ul>

      <div className="mt-12 divide-y divide-gray-200">
        <AutomatedProjectEnabled />

        <AutomatedProjectName />

        <AutomatedProjectNameAdditional />

        <AutomatedProjectNotes />

        <AutomatedProjectLocations />

        <AutomatedProjectOwners />

        <AutomatedProjectEinlagen />

        <AutomatedProjectRechtsform />

        <AutomatedProjectQuantities />

        <AutomatedProjectTimeline />
      </div>
    </>
  );
};

export default AutomatedProjectOverviewPage;
