import cn from "classnames";

import innerText from "~/src/modules/inner-text.js";

import DateInput from "~/src/ui/forms/date-input/index.new.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.minDate - The root object
 * @param props0.maxDate - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.control - The root object
 * @param props0.defaultValue - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.inline - The root object
 * @param props0.isClearable - The root object
 * @param props0.label - The root object
 * @param props0.name - The root object
 * @param props0.onChange - The root object
 * @param props0.placeholder - The root object
 * @param props0.prefix - The root object
 * @param props0.range - The root object
 * @param props0.register - The root object
 * @param props0.suffix - The root object
 * @param props0.type - The root object
 * @example
 */
const DateField = ({
  minDate,
  maxDate,
  autoFocus = false,
  className,
  control,
  defaultValue,
  disabled = false,
  errors,
  inline,
  isClearable,
  label,
  name,
  onChange,
  prefix,
  range,
  register,
  suffix,
  type = "search",

  id = name,
  placeholder = innerText(label)
}) => (
  <div className={cn("py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200", className)}>
    <div
      className={cn("space-y-1 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-x-4", {
        "p-0": inline,
        "py-5 px-6": !inline
      })}
    >
      <div className={inline ? "col-span-3" : "col-span-1"}>
        <label className="inline-block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor={id}>
          {label}
        </label>
      </div>

      <div
        className={cn("relative grid grid-cols-3", {
          [inline ? "col-span-2" : "col-span-1"]: prefix,
          [inline ? "col-span-3" : "col-span-2"]: !prefix
        })}
      >
        {prefix || null}

        <DateInput
          {...{
            id,
            minDate,
            maxDate,
            autoFocus,
            control,
            defaultValue,
            disabled,
            errors,
            isClearable,
            name,
            onChange,
            placeholder,
            range,
            register,
            type
          }}
          className={cn("w-full", {
            "col-span-1": prefix && suffix,
            "col-span-2": prefix || suffix,
            "col-span-3": !prefix && !suffix
          })}
        />
      </div>
    </div>
  </div>
);

export default DateField;
