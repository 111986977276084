/**
 *
 * @param nodeA
 * @param nodeB
 * @example
 */
const findFirstCommonAncestorNode = (nodeA, nodeB) => {
  const range = new Range();

  const nodeABeforeNodeB = nodeA.compareDocumentPosition(nodeB) & Node.DOCUMENT_POSITION_FOLLOWING;

  const firstNode = nodeABeforeNodeB ? nodeA : nodeB;
  const secondNode = nodeABeforeNodeB ? nodeB : nodeA;

  range.setStartBefore(firstNode);
  range.setEndAfter(secondNode);

  if (range.collapsed) {
    range.setStartBefore(secondNode);
    range.setEndAfter(firstNode);
  }

  return range.commonAncestorContainer;
};

export { findFirstCommonAncestorNode };
