import cn from "classnames";
import { useLocation } from "react-router-dom";

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @param props0.className - The root object
 * @example
 */
const Layout = ({ children, className }) => {
  const { pathname } = useLocation();

  return (
    <div
      className={cn(
        "pt-10 pb-24 min-h-[calc(100vh-3rem)]",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Layout;
