import { useState } from "react";

/**
 *
 * @example
 */
const useModal = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(null);

  /**
   *
   * @param modalValue
   * @example
   */
  function show(modalValue) {
    if (modalValue) {
      setData(modalValue);
    }
    setVisible(true);
  }

  /**
   *
   * @param modalValue
   * @param options0 - The root object
   * @param options0.useArg - The root object
   * @param options0.useTimeout - The root object
   * @example
   */
  const hide = (
    modalValue = null,
    { useArg: useArgument = false, useTimeout = true } = { useTimeout: true }
  ) => {
    if (Boolean(modalValue) && useArgument === true) {
      setData(modalValue);
    }
    setVisible(false);
    if (useTimeout) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (data) {
            setData(null);
          }

          resolve();
        }, 400);
      });
    }
  };

  return {
    data,
    hide,
    show,
    visible
  };
};

export default useModal;
