import {
  customActivity,
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers.js";

export default {
  ...Object.fromEntries(
    [
      "created_at",
      "deleted_at",
      "updated_at",
      "id",
      "project_id"
    ].map(
      (key) => [key, hiddenActivity]
    )
  ),
  name: normalActivity("Name"),
  sorting: customActivity({
    label: "Sortierung",
    value: (value) => value && Number(value)
  })
};
