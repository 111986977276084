import API from "~/src/modules/api.js";

/**
 *
 * @param values
 * @example
 */
const generateUnitObject = (values) => ({ show: values.show || false });

/**
 *
 * @param options0 - The root object
 * @param options0.data - The root object
 * @param options0.handleClose - The root object
 * @param options0.mutate - The root object
 * @param options0.setIsLoading - The root object
 * @param options0.showError - The root object
 * @param options0.unitId - The root object
 * @example
 */
const handleUpdate = ({
  data, handleClose, mutate, setIsLoading, showError, unitId
}) => {
  const automated_unit = generateUnitObject(data);

  setIsLoading(true);

  API.patch(`/automated-units/${unitId}`, { automated_unit })
    .then((res) => {
      mutate();
      handleClose();
    }).catch((error) => {
      showError(error);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export {
  handleUpdate
};
