import {
  customActivity,
  dateActivity,
  dateFormatActivity,
  hiddenActivity,
  yearActivity
} from "~/src/modules/format-activity-change/helpers.js";

export default {
  ...Object.fromEntries(
    [
      "created_at",
      "deleted_at",
      "updated_at",
      "id",
      "project_id"
    ].map(
      (key) => [key, hiddenActivity]
    )
  ),

  ...Object.fromEntries(
    [["construction_start_date", "Geplanter Baubeginn"], ["estimated_finish_date", "Geplante Fertigstellung"]]
      .flatMap(([key, label]) => [[key, dateActivity(label)], [`${key}_format`, dateFormatActivity(`${label} (Format)`)]])
  ),

  ...Object.fromEntries(
    [["construction_year", "Baujahr"], ["reconstruction_year", "Sanierungsjahr"]].map(([key, label]) => [key, yearActivity(label)])
  ),

  is_new: customActivity({
    label: "Neubau/Sanierung",
    value: (value) => (value ? "Neubau" : "Sanierung/Gebäudebestand")
  })
};
