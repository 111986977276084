import API from "~/src/modules/api.js";

/**
 *
 * @param inputValue
 * @param name
 * @param countryCode
 * @example
 */
export async function loadLocationOptions(inputValue, name, countryCode) {
  return API.post("/locations/autosuggest", {
    countryCode,
    query: inputValue
  }).then((res) => res.data.payload.locations.map((location) => ({
    id: location.id,
    label: location?.address.label,
    value: location.id
  }))).catch((error) => {
    console.log(error);
  });
};
