/**
 *
 * @param code
 * @param errorItem
 * @example
 */
const validationErrors = (errorItem) => {
  let errorCode = errorItem;

  if (typeof errorItem === "object") {
    errorCode = errorItem.type;

    if (errorCode === "custom") {
      errorCode = errorItem.message;
    }
  }

  const message = validationMessages[errorCode];

  if (message) {
    return message;
  }

  return "Eingabefehler.";
};

export { validationErrors };

const validationMessages = {
  "any.invalid": "Falsches Format",
  "any.required": "Pflichtfeld",
  "array.includesRequiredUnknonws": "Die Mindestanforderung ist nicht erfüllt",
  "array.min": "Die Mindestanforderung ist nicht erfüllt",
  "boolean.show_gka.no_active_transactions": "Kein Liegenschaftsankauf aktiv",
  "date.base": "Pflichtfeld",
  "date.max": "Datum zu spät",
  "date.max.verwertet": "Verwertungsdatum muss mind. ein Monat vor KV-Datum sein",
  "number.base": "Keine Zahl",
  "number.difference.thousand": "Differenz zu groß (max. 1000)",
  "number.less": "Zahl zu groß",
  "number.max": "Zahl zu groß",
  "number.min": "Zahl zu klein",
  "number.min.positionTo": "Zahl muss größer als \"Nummer (von)\" sein",
  "number.positive": "Zahl muss positiv sein",
  "object.with": "Pflichtfeld",
  "string.email": "Ungültiges Email Format",
  "string.empty": "Pflichtfeld",
  "string.etage": "Wort \"Etage\" nicht erlaubt",
  "string.max": "Zu lang",
  "string.min": "Zu kurz",
  "string.pattern.base": "Falsches Format"
};
