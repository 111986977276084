import Joi from "joi";

export const initialValues = {
  name: ""
};

export const schema = Joi.object({
  name: Joi.string().empty("").required()
    .min(2)
});
