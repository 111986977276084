import displayDiff from "./display-diff.jsx";
import isEmpty from "./is-empty.js";

const customActivity = ({
  empty,
  label = "",
  value = ""
}) => (innerValue, otherValue, side) => {
  const actualInnerValue = typeof value === "function" ? value(innerValue) : innerValue;
  const actualOtherValue = typeof value === "function" ? value(otherValue) : otherValue;

  return {
    empty: empty ?? (actualInnerValue === actualOtherValue || isEmpty(value, innerValue)),
    label,
    value: displayDiff(actualInnerValue, actualOtherValue, side)
  };
};

export default customActivity;
