import { useEffect, useState } from "react";

import useAutomatedProject from "~/src/hooks/use-automated-project.js";
import useNotification from "~/src/hooks/use-notification.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import EditorInput from "~/src/ui/forms/editor-input/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import CloseIcon from "~/src/ui/icons/close-icon.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleUpdate } from "./handlers.js";

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @param props0.project - The root object
 * @param props0.projectId - The root object
 * @param props0.visible - The root object
 * @example
 */
const AutomatedProjectNotesSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useAutomatedProject(projectId);
  const { showError } = useNotification();
  const [projectNotes, setProjectNotes] = useState(project?.project_notes || "");
  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   * @param e
   * @example
   */
  function onSubmit(e) {
    e.preventDefault();
    handleUpdate(projectNotes, projectId, mutate, handleHide, showError, setIsLoading);
  };

  /**
   *
   * @example
   */
  function handleHide() {
    setProjectNotes("");
    hide();
  };

  useEffect(() => {
    setProjectNotes(project?.project_notes);
  }, [project]);

  return (
    <SlideOver hide={handleHide} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={onSubmit}>
        <div className="flex-1">

          <div className="bg-gray-800 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2 className="text-lg font-medium text-white">
                  Projektnotizen bearbeiten
                </h2>
              </div>

              <div className="flex h-7 items-center">
                <button className="rounded-md text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500" onClick={handleHide} type="button">
                  <CloseIcon />
                </button>
              </div>
            </div>
          </div>

          <div className="px-4 sm:px-6 sm:py-5">

            <EditorInput
              name="projectNotes"
              onChange={setProjectNotes}
              value={projectNotes}
            />

          </div>

        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton onClick={handleHide} />

            <FormSubmitButton isSubmitting={isLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>

      </form>
    </SlideOver>
  );
};

export default AutomatedProjectNotesSlide;
