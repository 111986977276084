import { useState } from "react";
import { useForm } from "react-hook-form";

import MainContainer from "~/src/ui/containers/main-container/index.jsx";

import { selectByKeys } from "../_common/_exports.js";
import IssuersList from "../features/certificates/issuers/list.jsx";
import IssuerModal from "../features/certificates/issuers/modal.jsx";
import NewIssuerButton
  from "../features/certificates/issuers/new-issuer-button.jsx";
import {
  expandedSchema,
  getInitialValuesForExpandedSchema
} from "../features/certificates/issuers/schema.js";
import CompaniesSearchForm, {
  CompaniesSearchForm as SearchForm
} from "../features/companies-search-form/index.jsx";
import Pagination from "../features/pagination/index.jsx";
import useAuth from "../hooks/use-auth.js";
import { useIssuers } from "../hooks/use-certificates.js";
import useModal from "../hooks/use-modal.js";
import validationResolver from "../modules/validation-resolver.js";
import PageHeader from "../ui/headers/page-header/index.jsx";
import Page from "../ui/page/index.jsx";
import PageTitle from "../ui/titles/page-title.jsx";

const CertificatesPage = () => {
  const { isAdmin } = useAuth();

  const [issuerSearchString, setIssuerSearchString] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const {
    isError,
    isLoading,
    issuers,
    mutate: mutateIssuers,
    pagination
  } = useIssuers({
    pageNumber,
    searchString: issuerSearchString
  });

  const completeReactForm = useForm({
    defaultValues: getInitialValuesForExpandedSchema(),
    resolver: validationResolver(expandedSchema)
  });

  const reactForm = selectByKeys(
    completeReactForm,
    [
      "control", // for NumberField
      "errors",
      "register",
      "reset", // possibly not used
      "clearErrors",
      "resetField", // for AreaField
      "handleSubmit",
      "setError", // for temprorary client-side unique issuer name validation
      "setValue",
      "getValues",
      "watch"
    ]
  );

  reactForm.formState = selectByKeys(completeReactForm.formState, ["errors"]);

  const modal = useModal();

  return (
    <Page title="ESG">
      <PageHeader>
        <IssuerModal
          {...{
            modal,
            mutateIssuers,
            reactForm
          }}
        />

        <PageTitle>ESG</PageTitle>

        {(isAdmin)
          ? (
            <NewIssuerButton
              {...{
                issuersTempFrontEndValidation: issuers,
                modal,
                reactForm
              }}
            />
          )
          : null}
      </PageHeader>

      <MainContainer>

        <CompaniesSearchForm
          {...{
            placeholder: "Ausstellernamen",
            setName: setIssuerSearchString,
            setPage: setPageNumber
          }}
        />

        <Pagination
          {...{
            labels: ["Aussteller", "Aussteller"],
            page: pageNumber,
            pagination,
            setPage: setPageNumber
          }}
        />

        <IssuersList
          {...{
            isLoading,
            issuers,
            modal,
            mutate: mutateIssuers,
            reactForm
          }}
        />

      </MainContainer>
    </Page>
  );
};

export default CertificatesPage;
