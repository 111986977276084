import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import SlideFormFooter from "~/src/ui/footers/slide-form-footer/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.jsx";
import NumberInput from "~/src/ui/forms/number-input/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleUpdate } from "./handlers.js";
import { generateEditValues, schema } from "./schema.js";

const ConstructionTypesSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();

  const initialValues = generateEditValues(project);

  const handleHide = (innerForm) => {
    hide();
    innerForm.resetForm();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleUpdate({
        id: project?.construction_types?.id,
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    touched,
    values
  } = form;

  return (
    <SlideOver hide={() => handleHide(form)} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={form.isLoading} handleHide={() => handleHide(form)} title="Bautypen bearbeiten" />

          {
            [
              [
                "neubau",
                "Neubau",
                "(inkl. Gewerbe)"
              ],
              ["dg_ausbau", "DG-Ausbau"],
              ["sanierung", "Sanierung"],
              ["unsaniert", "Unsaniert"],
              ["fremdbestand", "Fremdbestand"]
            ]
              .map(([
                key,
                label,
                secondaryLabel = label
              ]) => (
                <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0" key={key}>
                  <div className="items-center space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div className="sm:col-span-1">
                      <InlineCheckbox
                        error={errors[key]}
                        id={key}
                        isSubmitting={isSubmitting}
                        label={`${label}?`}
                        name={key}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        touched={touched[key]}
                        value={values[key]}
                      />
                    </div>

                    {values[key]
                      ? (
                        <>
                          <div className="col-span-2" />

                          <div>
                            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor={`${key}_area_total`}>
                              Gesamtfläche {secondaryLabel}
                            </label>
                          </div>

                          <div className="sm:col-span-1">
                            <NumberInput
                              area
                              error={errors[`${key}_area_total`]}
                              id={`${key}_area_total`}
                              isSubmitting={isSubmitting}
                              name={`${key}_area_total`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder={`Gesamtfläche ${secondaryLabel}`}
                              touched={touched[`${key}_area_total`]}
                              value={values[`${key}_area_total`]}
                            />
                          </div>

                          <div className="col-span-1" />

                          <div>
                            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor={`${key}_unit_area_total`}>
                              Wohnfläche
                            </label>
                          </div>

                          <div className="sm:col-span-1">
                            <NumberInput
                              area
                              error={errors[`${key}_unit_area_total`]}
                              id={`${key}_unit_area_total`}
                              isSubmitting={isSubmitting}
                              name={`${key}_unit_area_total`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Gesamte Wohnfläche"
                              touched={touched[`${key}_unit_area_total`]}
                              value={values[`${key}_unit_area_total`]}
                            />
                          </div>

                          <div className="col-span-1" />

                          <div>
                            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor={`${key}_count`}>
                              Anzahl Wohneinheiten
                            </label>
                          </div>

                          <div className="sm:col-span-1">
                            <NumberInput
                              int
                              error={errors[`${key}_count`]}
                              id={`${key}_count`}
                              isSubmitting={isSubmitting}
                              name={`${key}_count`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Anzahl Wohneinheiten"
                              touched={touched[`${key}_count`]}
                              value={values[`${key}_count`]}
                            />
                          </div>

                          <div className="sm:col-span-1">
                            <InlineCheckbox
                              error={errors[`show_${key}_range`]}
                              id={`show_${key}_range`}
                              isSubmitting={isSubmitting}
                              label="von-bis"
                              name={`show_${key}_range`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              touched={touched[`show_${key}_range`]}
                              value={values[`show_${key}_range`]}
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor={`${key}_area_range_from`}>
                              Fläche (Wohneinheiten)
                            </label>
                          </div>

                          <div className="sm:col-span-1">
                            <NumberInput
                              area
                              error={errors[`${key}_area_range_from`]}
                              id={`${key}_area_range_from`}
                              isSubmitting={isSubmitting}
                              name={`${key}_area_range_from`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Fläche"
                              touched={touched[`${key}_area_range_from`]}
                              value={values[`${key}_area_range_from`]}
                            />
                          </div>

                          {values[`show_${key}_range`]
                            ? (
                              <div className="sm:col-span-1">
                                <NumberInput
                                  area
                                  error={errors[`${key}_area_range_to`]}
                                  id={`${key}_area_range_to`}
                                  isSubmitting={isSubmitting}
                                  name={`${key}_area_range_to`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder="Fläche bis"
                                  touched={touched[`${key}_area_range_to`]}
                                  value={values[`${key}_area_range_to`]}
                                />
                              </div>
                            )
                            : null}
                        </>
                      )
                      : null}
                  </div>
                </div>
              ))
          }
        </div>

        <SlideFormFooter form={form} handleHide={() => handleHide(form)} />
      </form>
    </SlideOver>
  );
};

export default ConstructionTypesSlide;
