import Select from "~/src/ui/forms/select/index.jsx";

import options from "./options.js";

/**
 *
 * @param properties
 * @example
 */
const MarketingPhaseSelect = (properties) => {
  let defaultValue = options[0];

  if (properties.defaultValue) {
    defaultValue = Object
      .keys(options)
      .map((key) => options[key])
      .find((format) => format.value === properties.defaultValue.value);
  }

  return (
    <Select
      options={options}
      placeholder="Aktueller Vermarktungsstatus"
      {...properties}
      defaultValue={defaultValue}
    />
  );
};

export default MarketingPhaseSelect;
