import cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.location - The root object
 * @example
 */
const ProjectLocation = ({ location }) => (
  <p className={cn({ "font-medium": location.default_location })} key={`location_${location.id}`}>
    {location.street?.name} {location.housenumber}, {location.street.city?.zipcode} {location.street.city?.name}, {location.street.city?.state.code.toLocaleUpperCase()}
  </p>
);

export default ProjectLocation;
