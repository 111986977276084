import { formatDistance } from "~/src/modules/formatters.new.js";

import customActivity from "./custom-activity.js";

const distanceActivity = (label) => customActivity({
  label,
  value: formatDistance
});

export default distanceActivity;
