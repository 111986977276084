import { Fragment } from "react";

import { fundingLabels } from "~/src/modules/labels.jsx";

import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import SlideFormFooter from "~/src/ui/footers/slide-form-footer/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.jsx";
import NumberInput from "~/src/ui/forms/number-input/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleUpdate } from "./handlers.js";
import { generateEditValues, schema } from "./schema.js";

const FundingSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();

  const initialValues = generateEditValues(project);

  const handleHide = (innerForm) => {
    hide();
    innerForm.resetForm();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleUpdate({
        id: project?.funding?.id,
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  return (
    <SlideOver hide={() => handleHide(form)} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={form.isLoading} handleHide={() => handleHide(form)} title="Förderungen bearbeiten" />

          {
            [
              ["not_funded"],
              [
                "normal_funded",
                [
                  "super_funded",
                  "smart_funded",
                  "smart_super_funded",
                  "wiener_wohnbau"
                ]
              ],
              ["gemeinde"]
            ]
              .map(([key, children = []]) => (
                <Fragment key={key}>
                  <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0" key={key}>
                    <div className="h-16 items-center space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6">
                      <div className="sm:col-span-1">
                        <InlineCheckbox
                          error={form.errors[key]}
                          id={key}
                          isSubmitting={form.isSubmitting}
                          label={`${fundingLabels.get(key)}?`}
                          name={key}
                          onBlur={form.handleBlur}
                          onChange={form.handleChange}
                          touched={form.touched[key]}
                          value={form.values[key]}
                        />
                      </div>

                      {form.values[key]
                        ? (
                          <div className="sm:col-span-1">
                            <NumberInput
                              int
                              error={form.errors[`${key}_total`]}
                              id={`${key}_total`}
                              isSubmitting={form.isSubmitting}
                              name={`${key}_total`}
                              onBlur={form.handleBlur}
                              onChange={form.handleChange}
                              placeholder="Anzahl Einheiten"
                              touched={form.touched[`${key}_total`]}
                              value={form.values[`${key}_total`]}
                            />
                          </div>
                        )
                        : null}
                    </div>
                  </div>

                  {
                    (form.values[key] && children.length > 0)
                      ? children
                        .map((childKey) => (
                          <div className="m-4 ml-20 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0" key={childKey} >
                            <div className="h-16 items-center space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6">
                              <div className="sm:col-span-2">
                                <InlineCheckbox
                                  error={form.errors[childKey]}
                                  id={childKey}
                                  isSubmitting={form.isSubmitting}
                                  label={`${fundingLabels.get(childKey)}?`}
                                  name={childKey}
                                  onBlur={form.handleBlur}
                                  onChange={form.handleChange}
                                  touched={form.touched[childKey]}
                                  value={form.values[childKey]}
                                />
                              </div>

                              {form.values[childKey]
                                ? (
                                  <div className="sm:col-span-1">
                                    <NumberInput
                                      int
                                      error={form.errors[`${childKey}_total`]}
                                      id={`${childKey}_total`}
                                      isSubmitting={form.isSubmitting}
                                      name={`${childKey}_total`}
                                      onBlur={form.handleBlur}
                                      onChange={form.handleChange}
                                      placeholder="Anzahl Einheiten"
                                      touched={form.touched[`${childKey}_total`]}
                                      value={form.values[`${childKey}_total`]}
                                    />
                                  </div>
                                )
                                : null}
                            </div>
                          </div>
                        ))
                      : null
                  }
                </Fragment>
              ))
          }
        </div>

        <SlideFormFooter form={form} handleHide={() => handleHide(form)} />
      </form>
    </SlideOver>
  );
};

export default FundingSlide;
