import dayjs from "dayjs";

/**
 *
 * @param value
 * @example
 */
const formatArea = (value) => {
  if (value !== undefined) {
    const formatter = new Intl.NumberFormat("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "unit",
      unit: "meter"
    });

    const parts = formatter.formatToParts(value);

    const meterPart = parts.at(-1);
    const squareMeterPart = {
      ...meterPart,
      value: `${meterPart.value}²`
    };

    return [...parts.slice(0, -1), squareMeterPart].map(({ value }) => value).join("");
  }

  return "";
};

export { formatArea };

/**
 *
 * @param value
 * @example
 */
const formatDistance = (value) => {
  if (value !== undefined) {
    const formatter = new Intl.NumberFormat("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "unit",
      unit: "meter"
    });

    return formatter.format(value);
  }

  return "";
};

export { formatDistance };

/**
 *
 * @param value
 * @example
 */
const formatDecimalExactPrecision = (value) => {
  if (value !== undefined) {
    let formattedNumber = value.toString();

    formattedNumber = formattedNumber.replace(/(\.\d*?)0+$/u, "$1");
    formattedNumber = formattedNumber.replace(/\.$/u, "");

    const formatter = new Intl.NumberFormat("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return Number(formattedNumber);
  }

  return "";
};

export { formatDecimalExactPrecision };

/**
 *
 * @param value
 * @example
 */
const formatEuro = (value) => {
  if (value !== undefined) {
    const formatter = new Intl.NumberFormat("de-AT", {
      currency: "EUR",
      style: "currency"
    });

    return formatter.format(value);
  }

  return "";
};

export { formatEuro };

/**
 *
 * @param root0 - The root object
 * @param root0.date - The root object
 * @param root0.withTime - The root object
 * @example
 */
const formatDate = ({ date = null, withTime = false }) => {
  if (date === null) {
    return null;
  }

  if (withTime) {
    return dayjs(date).format("DD.MM.YYYY HH:mm");
  }

  return dayjs(date).format("DD.MM.YYYY");
};

export { formatDate };

/**
 *
 * @param singular
 * @param plural
 * @param length
 * @example
 */
const pluralize = (singular = "", plural = "", length = 0) => (length === 0 || length > 1 ? plural : singular);

export { pluralize };
