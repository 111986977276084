import { formatEuro } from "~/src/modules/formatters.new.js";

import customActivity from "./custom-activity.js";

const priceActivity = (label) => customActivity({
  label,
  value: formatEuro
});

export default priceActivity;
