import useAuth from "~/src/hooks/use-auth.js";

import Skeleton from "~/src/ui/loading/skeleton/index.jsx";

import { Entry, Header } from "./list/_exports.js";

const IssuersList = ({
  isLoading, issuers, modal, mutate, reactForm
}) => {
  if (isLoading) {
    return <Skeleton />;
  }

  const { isAdmin, user } = useAuth();

  return (
    <div className="my-6 grid grid-cols-3 overflow-hidden rounded-sm border-2 border-gray-200 md:grid-cols-1">
      <Header />

      <div className="col-span-2 grid grid-cols-1 divide-y-2 divide-gray-200 md:col-span-1">
        {issuers?.map((issuer, index) => (
          <Entry
            key={index}
            {...{
              isAdmin,
              issuer,
              issuersTempFrontEndValidation: issuers,
              modal,
              mutate,
              reactForm
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default IssuersList;
