import API from "~/src/modules/api.js";

import { initialValues } from "./schema.js";

/**
 *
 * @param options0 - The root object
 * @param options0.data - The root object
 * @param options0.handleClose - The root object
 * @param options0.mutate - The root object
 * @param options0.projectId - The root object
 * @param options0.setIsLoading - The root object
 * @param options0.showError - The root object
 * @param options0.showSuccess - The root object
 * @param callback
 * @example
 */
const handleUpdate = ({
  data, handleClose, mutate, projectId, setIsLoading, showError, showSuccess
}, callback) => {
  setIsLoading(true);

  API
    .patch(`/automated-projects/${projectId}/public-notes`, {
      automated_project: {
        ...initialValues,
        ...data
      }
    })
    .then((res) => {
      mutate();
      showSuccess();
      handleClose();
    }).catch((error) => {
      showError();
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export {
  handleUpdate
};
