/**
 *
 * @param file
 * @example
 */
const readFileAsync = (file) => new Promise((resolve, reject) => {
  try {
    // not using resize because of image quality issues

    resolve(file);
    // const reader = new FileReader()

    // if (file.type === "application/pdf") {
    //   resolve(file)
    // }

    // reader.onload = async (e) => {
    //   const source = await resizeWithCanvas(e.target.result, file.type)
    //   file.blob = source
    //   resolve(file)
    // }

    // reader.readAsDataURL(file);
  }
  catch (error) {
    console.log(error);
  }
});

export { readFileAsync };

// function resizeWithCanvas(source, fileType) {
//   return new Promise((resolve, reject) => {
//     let MAX_WIDTH = 1000;
//     let MAX_HEIGHT = 1000;
//     let OUTPUT_QUALITY = 1;

//     const img = new Image()
//     img.src = source

//     img.onload = () => {
//       let canvas = document.createElement("canvas");
//       let ctx = canvas.getContext("2d");
//       let width = img.width;
//       let height = img.height;

//       if (width > height) {
//           if (width > MAX_WIDTH) {
//               height *= MAX_WIDTH / width;
//               width = MAX_WIDTH;
//           }
//       } else {
//           if (height > MAX_HEIGHT) {
//               width *= MAX_HEIGHT / height;
//               height = MAX_HEIGHT;
//           }
//       }
//       canvas.width = width;
//       canvas.height = height;

//       ctx = canvas.getContext("2d");
//       ctx.drawImage(img, 0, 0, width, height);

//       const url = canvas.toDataURL("image/jpeg", OUTPUT_QUALITY);
//       const blob = dataURItoBlob(url)

//       resolve(blob)
//     }
//   })
// }

// function dataURItoBlob(dataURI) {
//   // convert base64 to raw binary data held in a string
//   // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
//   var byteString = atob(dataURI.split(',')[1]);

//   // separate out the mime component
//   var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

//   // write the bytes of the string to an ArrayBuffer
//   var ab = new ArrayBuffer(byteString.length);

//   // create a view into the buffer
//   var ia = new Uint8Array(ab);

//   // set the bytes of the buffer to the correct values
//   for (var i = 0; i < byteString.length; i++) {
//       ia[i] = byteString.charCodeAt(i);
//   }

//   // write the ArrayBuffer to a blob, and you're done
//   var blob = new Blob([ab], {type: mimeString});
//   return blob;

// }
