const baseURL = globalThis?.settings?.REACT_APP_API_ENDPOINT;

const parseResponse = async (response) => {
  switch (response.headers.get("content-type")) {
    case "application/json":
    case "application/json; charset=utf-8":
      return { data: await response.json() };
    case "application/pdf":
    case "image/jpeg":
    case "image/jpg":
    case "image/png":
      return { data: await response.blob() };
    default:
      return { data: await response.text() };
  }
};

const API = {
  async get(endpoint) {
    try {
      const response = await fetch(`${baseURL}${endpoint}`, {
        credentials: "include",
        method: "GET"
      });

      if (!response.ok) {
        const data = await response.json();
        const error = new Error("An error occurred while fetching the data.");

        error.response = {
          data,
          status: response.status
        };

        throw error;
      }

      return await parseResponse(response);
    }
    catch (error) {
      error.status = error.response ? error.response.status : 500;
      throw error;
    }
  },

  async post(endpoint, body) {
    try {
      const isFormData = body instanceof FormData;

      const response = await fetch(`${baseURL}${endpoint}`, {
        body: isFormData ? body : JSON.stringify(body),
        credentials: "include",
        headers: isFormData
          ? {}
          : {
            "Content-Type": "application/json"
          },
        method: "POST"
      });

      if (!response.ok) {
        const data = await response.json();
        const error = new Error("An error occurred while posting the data.");

        error.response = {
          data,
          status: response.status
        };
        throw error;
      }

      return await parseResponse(response);
    }
    catch (error) {
      error.status = error.response ? error.response.status : 500;
      throw error;
    }
  },

  async put(endpoint, body) {
    try {
      const isFormData = body instanceof FormData;

      const response = await fetch(`${baseURL}${endpoint}`, {
        body: isFormData ? body : JSON.stringify(body),
        credentials: "include",
        headers: isFormData
          ? {}
          : {
            "Content-Type": "application/json"
          },
        method: "PUT"
      });

      if (!response.ok) {
        const data = await response.json();
        const error = new Error("An error occurred while putting the data.");

        error.response = {
          data,
          status: response.status
        };
        throw error;
      }

      return await parseResponse(response);
    }
    catch (error) {
      error.status = error.response ? error.response.status : 500;
      throw error;
    }
  },

  async patch(endpoint, body) {
    try {
      const response = await fetch(`${baseURL}${endpoint}`, {
        body: JSON.stringify(body),
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        method: "PATCH"
      });

      if (!response.ok) {
        const data = await response.json();
        const error = new Error("An error occurred while patching the data.");

        error.response = {
          data,
          status: response.status
        };
        throw error;
      }

      return await parseResponse(response);
    }
    catch (error) {
      error.status = error.response ? error.response.status : 500;
      throw error;
    }
  },

  async delete(endpoint) {
    try {
      const response = await fetch(`${baseURL}${endpoint}`, {
        credentials: "include",
        method: "DELETE"
      });

      if (!response.ok) {
        const data = await response.json();
        const error = new Error("An error occurred while deleting the data.");

        error.response = {
          data,
          status: response.status
        };
        throw error;
      }

      return { data: await parseResponse(response) };
    }
    catch (error) {
      error.status = error.response ? error.response.status : 500;
      throw error;
    }
  }
};

/**
 *
 * @param {string} endpoint
 * @example
 */
const fetcher = async (endpoint) => {
  try {
    return (await API.get(endpoint)).data;
  }
  catch (error) {
    return error;
  }
};

export { fetcher };

/**
 *
 * @param {Error} error
 * @example
 */
const parseError = (error) => {
  if (error.response) {
    // The server responded with a status code outside the 2xx range
    return {
      data: error.response.data,
      status: error.response.status
    };
  }
  // Network error or other issue
  console.log("Error", error.message);

  return {
    status: 500
  };
};

export { parseError };

export default API;
