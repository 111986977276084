const options = [
  {
    label: "unbekannt",
    value: "unknown"
  },
  {
    label: "Liegenschaft/Baurecht erworben",
    value: "construction_phase_purchase"
  },
  {
    label: "In Bau",
    value: "construction_phase_active"
  },
  {
    label: "Bau abgeschlossen",
    value: "construction_phase_completed"
  }
];

export default options;
