import { onClickShowModal } from "./controller.js";

const NewIssuerButton = ({
  issuersTempFrontEndValidation, modal, reactForm
}) => (
  <button
    className="mt-6 block text-sm underline focus:outline-none"
    type="button"
    onClick={onClickShowModal.bind(null, {
      issuersTempFrontEndValidation,
      modal,
      reactForm
    })}
  >
    Neuen Aussteller hinzufügen
  </button>
);

export default NewIssuerButton;
