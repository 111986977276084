import {
  fileActivity,
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers.js";

export default {
  ...Object.fromEntries([
    "id",
    "project_id",
    "file_type"
  ].map((key) => [key, hiddenActivity])),

  ...Object.fromEntries([["show_public", "Dokument öffentlich anzeigen"], ["public_name", "Öffentlicher Dateiname"]]
    .map(([key, label]) => [key, normalActivity(label)])),

  ...Object.fromEntries([["file_name", "Datei"]]
    .map(([key, label]) => [key, fileActivity(label)]))
};
