import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import SlideFormFooter from "~/src/ui/footers/slide-form-footer/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleUpdate } from "./handlers.js";
import { generateEditValues, schema } from "./schema.js";

const RechtsformSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();

  const initialValues = generateEditValues(project);

  const handleHide = async (innerForm) => {
    await hide();
    innerForm.resetForm();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleUpdate({
        id: project?.id,
        data: form.values,
        hide: async () => {
          await handleHide(form);
        },
        mutate,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  return (
    <SlideOver hide={() => handleHide(form)} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={form.isLoading} handleHide={() => handleHide(form)} title="Rechtsform bearbeiten" />

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="items-center space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div className="sm:col-span-1">
                <InlineCheckbox
                  error={form.errors.buyable}
                  id="buyable"
                  isSubmitting={form.isSubmitting}
                  label="Eigentum"
                  name="buyable"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  touched={form.touched.buyable}
                  value={form.values.buyable}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="items-center space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div className="sm:col-span-1">
                <InlineCheckbox
                  error={form.errors.rentable}
                  id="rentable"
                  isSubmitting={form.isSubmitting}
                  label="Miete"
                  name="rentable"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  touched={form.touched.rentable}
                  value={form.values.rentable}
                />
              </div>
            </div>
          </div>
        </div>

        <SlideFormFooter form={form} handleHide={() => handleHide(form)} />
      </form>
    </SlideOver>
  );
};

export default RechtsformSlide;
