import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param query
 * @example
 */
const useActivities = (query) => {
  const { data, error } = useSWR(`/activities?${new URLSearchParams(Object.fromEntries(Object.entries(query).filter(([key, value]) => value !== undefined)))}`, fetcher);

  return {
    activities: data?.payload?.activities,
    isError: error,
    isLoading: !error && !data,
    pagination: data?.payload?.pagination
  };
};

export default useActivities;
