import { dummyUnitObject } from "~/src/features/project-units/handlers.js";

import ProjectUnitCompact from "~/src/ui/project-unit-compact/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.offerDataAvailable - The root object
 * @param props0.saleDataAvailable - The root object
 * @param props0.unit - The root object
 * @example
 */
const ProjectUnitBulkCreation = ({
  offerDataAvailable, saleDataAvailable, unit
}) => {
  const units = (offerDataAvailable)
    ? dummyUnitObject(unit, unit.positionFrom, unit.positionTo)
      .map((unit) => ({
        ...unit,
        offer_data_available: offerDataAvailable,
        sale_data_available: saleDataAvailable
      }))
    : undefined;

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Bulk Erstellung!
      </h3>

      <div className="mt-2">
        <p className="pb-3 text-sm text-gray-500">
          {units ? `Es werden ${units.length} Units erstellt. Die Benennung folgt dem Schema: ${units[0].name}, (...) , ${units.at(-1).name}.` : null}
        </p>
      </div>

      <div className="max-h-72 overflow-y-scroll rounded-lg bg-gray-200 px-5" >
        {units
          ? units?.map((unit) => (
            <div className="relative pt-6" key={`unitDummy_${unit.position}`}>
              <ProjectUnitCompact automated isDummy unit={unit} />
            </div>
          ))
          : null}
      </div>
    </div>

  );
};

export default ProjectUnitBulkCreation;
