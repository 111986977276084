import Handler from "~/src/modules/handler.js";

const {
  handleUpdate
} = new Handler({
  endpoint: "/fundings/:id",

  afterEvery: ({ setIsSubmitting }) => {
    setIsSubmitting(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (data) => {
    const transformedData = {
      funding: {
        gemeinde: data.gemeinde || false,
        gemeinde_total: data.gemeinde && data.gemeinde_total ? data.gemeinde_total : null,
        normal_funded: data.normal_funded || false,
        normal_funded_total: data.normal_funded && data.normal_funded_total ? data.normal_funded_total : null,
        not_funded: data.not_funded || false,
        not_funded_total: data.not_funded && data.not_funded_total ? data.not_funded_total : null,
        smart_funded: data.smart_funded || false,
        smart_funded_total: data.smart_funded && data.smart_funded_total ? data.smart_funded_total : null,
        smart_super_funded: data.smart_super_funded || false,
        smart_super_funded_total: data.smart_super_funded && data.smart_super_funded_total ? data.smart_super_funded_total : null,
        super_funded: data.super_funded || false,
        super_funded_total: data.super_funded && data.super_funded_total ? data.super_funded_total : null,
        wiener_wohnbau: data.wiener_wohnbau || false,
        wiener_wohnbau_total: data.wiener_wohnbau && data.wiener_wohnbau_total ? data.wiener_wohnbau_total : null
      }
    };

    if (!data.normal_funded) {
      transformedData.funding.normal_funded_total = null;
      transformedData.funding.super_funded = false;
      transformedData.funding.super_funded_total = null;
      transformedData.funding.smart_funded = false;
      transformedData.funding.smart_funded_total = null;
      transformedData.funding.smart_super_funded = false;
      transformedData.funding.smart_super_funded_total = null;
      transformedData.funding.wiener_wohnbau = false;
      transformedData.funding.wiener_wohnbau_total = null;
    }

    return transformedData;
  }
});

export {
  handleUpdate
};
