import Handler from "~/src/modules/handler.js";

const { handleUpdate } = new Handler({
  endpoint: "/projects/:id/rechtsform",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    console.log(error);
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    await hide();
  },
  transformUpdateData: (data) => ({
    project: {
      buyable: data.buyable || false,
      rentable: data.rentable || false
    }
  })
});

export {
  handleUpdate
};
