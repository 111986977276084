import Select from "react-select";

/**
 *
 * @param props0 - The root object
 * @param props0.customValueKey - The root object
 * @param props0.defaultValue - The root object
 * @param props0.error - The root object
 * @param props0.isClearable - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.name - The root object
 * @param props0.onChange - The root object
 * @param props0.options - The root object
 * @param props0.placeholder - The root object
 * @param props0.touched - The root object
 * @param props0.value - The root object
 * @example
 */
const SelectInput = ({
  customValueKey,
  defaultValue = "",
  error,
  isClearable = false,
  isSubmitting,
  name,
  onChange,
  options,
  placeholder,
  touched,
  value
}) => {


  /**
   *
   * @param option
   * @example
   */
  function handleChange(option) {
    if (option) {
      const event = {
        target: {
          name,
          type: "custom-select",
          value: option || defaultValue
        }
      };

      if (customValueKey) {
        event.target.value = option[customValueKey];
      }

      onChange(event);
    }
    else {
      const event = {
        target: {
          name,
          type: "custom-select",
          value: {
            label: null,
            value: null
          }
        }
      };

      onChange(event);
    }
  }

  const customStyles = {
    control: (styles, state) => {
      let borderColor = "#cbd5e0";

      if (error) {
        borderColor = "rgba(239, 68, 68, 1)";
      }
      if (state.isFocused) {
        borderColor = "rgba(17, 24, 39, 1)";
      }

      return ({
        ...styles,
        borderColor,
        borderRadius: "3px",
        height: "20px"
      });
    },
    menu: (provided) => ({
      ...provided,
      zIndex: "9999 !important"
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9_999
    }),
    placeholder: (styles, state) => ({
      ...styles,
      color: "rgba(107, 114, 128,1.0)",
      fontSize: "14px"
    }),
    singleValue: (base) => ({
      ...base,
      background: "rgba(67, 56, 202, 1)",
      borderRadius: "5px",
      color: "white",
      padding: "0 6px"
    })
  };

  return (
    <Select
      className="react-select z-20"
      defaultValue={defaultValue}
      isClearable={isClearable}
      menuPortalTarget={document.querySelector("body")}
      name={name}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      {...(value === undefined ? { value: defaultValue } : { value })}
    />
  );
};

export default SelectInput;
