import Handler from "~/src/modules/handler.js";

const { handleUpdate } = new Handler({
  endpoint: "/construction-types/:id",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (data) => ({
    construction_type: {
      neubau: data.neubau === true,
      neubau_area_range_from: data.neubau && data.neubau_area_range_from ? data.neubau_area_range_from : null,
      neubau_area_range_to: data.neubau && data.show_neubau_range && data.neubau_area_range_to ? data.neubau_area_range_to : null,
      neubau_area_total: data.neubau && data.neubau_area_total ? data.neubau_area_total : null,
      neubau_count: data.neubau && data.neubau_count ? data.neubau_count : null,
      neubau_unit_area_total: data.neubau && data.neubau_unit_area_total ? data.neubau_unit_area_total : null,

      dg_ausbau: data.dg_ausbau === true,
      dg_ausbau_area_range_from: data.dg_ausbau && data.dg_ausbau_area_range_from ? data.dg_ausbau_area_range_from : null,
      dg_ausbau_area_range_to: data.dg_ausbau && data.show_dg_ausbau_range && data.dg_ausbau_area_range_to ? data.dg_ausbau_area_range_to : null,
      dg_ausbau_area_total: data.dg_ausbau && data.dg_ausbau_area_total ? data.dg_ausbau_area_total : null,
      dg_ausbau_count: data.dg_ausbau && data.dg_ausbau_count ? data.dg_ausbau_count : null,
      dg_ausbau_unit_area_total: data.dg_ausbau && data.dg_ausbau_unit_area_total ? data.dg_ausbau_unit_area_total : null,

      sanierung: data.sanierung === true,
      sanierung_area_range_from: data.sanierung && data.sanierung_area_range_from ? data.sanierung_area_range_from : null,
      sanierung_area_range_to: data.sanierung && data.show_sanierung_range && data.sanierung_area_range_to ? data.sanierung_area_range_to : null,
      sanierung_area_total: data.sanierung && data.sanierung_area_total ? data.sanierung_area_total : null,
      sanierung_count: data.sanierung && data.sanierung_count ? data.sanierung_count : null,
      sanierung_unit_area_total: data.sanierung && data.sanierung_unit_area_total ? data.sanierung_unit_area_total : null,

      unsaniert: data.unsaniert === true,
      unsaniert_area_range_from: data.unsaniert && data.unsaniert_area_range_from ? data.unsaniert_area_range_from : null,
      unsaniert_area_range_to: data.unsaniert && data.show_unsaniert_range && data.unsaniert_area_range_to ? data.unsaniert_area_range_to : null,
      unsaniert_area_total: data.unsaniert && data.unsaniert_area_total ? data.unsaniert_area_total : null,
      unsaniert_count: data.unsaniert && data.unsaniert_count ? data.unsaniert_count : null,
      unsaniert_unit_area_total: data.unsaniert && data.unsaniert_unit_area_total ? data.unsaniert_unit_area_total : null,

      fremdbestand: data.fremdbestand === true,
      fremdbestand_area_range_from: data.fremdbestand && data.fremdbestand_area_range_from ? data.fremdbestand_area_range_from : null,
      fremdbestand_area_range_to: data.fremdbestand && data.show_fremdbestand_range && data.fremdbestand_area_range_to ? data.fremdbestand_area_range_to : null,
      fremdbestand_area_total: data.fremdbestand && data.fremdbestand_area_total ? data.fremdbestand_area_total : null,
      fremdbestand_count: data.fremdbestand && data.fremdbestand_count ? data.fremdbestand_count : null,
      fremdbestand_unit_area_total: data.fremdbestand && data.fremdbestand_unit_area_total ? data.fremdbestand_unit_area_total : null
    }
  })
});

export {
  handleUpdate
};
