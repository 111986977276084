import customActivity from "./custom-activity.js";

const normalActivity = (label) => customActivity({
  label,
  value: (value) => {
    if (typeof value === "boolean") {
      return value ? "Ja" : "Nein";
    }

    return value;
  }
});

export default normalActivity;
