import Handler from "~/src/modules/handler.js";

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/company-roles/:id",

  afterEvery: ({ setIsSubmitting }) => {
    setIsSubmitting(false);
  },
  afterEveryError: (error, { showError }) => {
    showError();
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data, { projectId, role }) => ({
    company_role: {
      company: data.private
        ? null
        : {
          id: data.company.id
        },
      name: role,
      private: data.private,
      project: {
        id: projectId
      }
    }
  }),
  transformUpdateData: (data) => ({
    company_role: {
      company_id: data.private ? null : data.company.id,
      private: data.private
    }
  })
});

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
