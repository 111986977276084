import cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.handleClick - The root object
 * @param props0.isEditor - The root object
 * @param props0.isLocked - The root object
 * @param props0.isMod - The root object
 * @param props0.label - The root object
 * @param props0.reachedLimit - The root object
 * @param props0.small - The root object
 * @example
 */
const LockButton = ({
  handleClick, isEditor = false, isLocked = false, isMod: isModule = false, label = null, reachedLimit = false, small = false
}) => {
  let color = "bg-emerald-100 text-emerald-600 hover:bg-emerald-200";
  let lock = <PenIcon />;
  let disabled = false;

  if (isLocked) {
    if (isEditor) {
      color = "bg-amber-100 text-amber-700 hover:bg-amber-200";
      lock = <ClosedLock />;
    }
    else {
      if (!isModule) {
        disabled = true;
      }
      color = `bg-red-100 text-red-700 ${disabled ? "" : "hover:bg-red-200"}`;
      lock = <ClosedLock />;
    }
  }
  else if (reachedLimit) {
    disabled = true;
    color = `bg-gray-100 text-gray-700 ${disabled ? "" : "hover:bg-gray-200"}`;
    lock = <ClosedLock />;
  }

  return (
    <button
      disabled={disabled}
      onClick={() => handleClick("project")}
      type="button"
      className={cn(`${color} flex focus:outline-none cursor-pointer items-center px-3 py-1 rounded-full text-sm font-medium`, {
        "cursor-not-allowed": disabled,
        "w-40": !small
      })}
    >
      {lock}

      {(label && !small) ? <span className="ml-2 mt-0.5 text-xs">{label}</span> : null}
    </button>
  );
};

export default LockButton;

/**
 *
 * @example
 */
const ClosedLock = () => (
  <svg className="size-5 stroke-current" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
  </svg>
);

/**
 *
 * @example
 */
const PenIcon = () => (
  <svg className="size-5 stroke-current" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
  </svg>
);
