import { dateFormatLabels } from "~/src/modules/labels.jsx";

const initialValuesEdit = {
  buyable: false,
  offerDataAvailable: false,
  rentable: false,
  show: true,
  verwertet: false,
  verwertetDate: "",
  verwertetDateFormat: {
    label: dateFormatLabels.get("month"),
    value: "month"
  },

  offerPriceBruttoInvestor: "",
  offerPriceBruttoInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceInvestor: "",
  offerPriceInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceNormal: "",
  offerPriceNormalHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showOfferPriceBruttoInvestorHistory: false,
  showOfferPriceInvestorHistory: false,
  showOfferPriceNormalHistory: false,

  rentBk: "",
  rentBkHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentBrutto: "",
  rentBruttoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentNetto: "",
  rentNettoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showRentBkHistory: false,
  showRentBruttoHistory: false,
  showRentNettoHistory: false,

  offerArea: "",
  offerRoomCount: "",

  offerLoggiaArea: "",
  offerLoggiaCount: "",

  offerBalkonArea: "",
  offerBalkonCount: "",

  offerGartenArea: "",
  offerGartenCount: "",

  offerTerrasseArea: "",
  offerTerrasseCount: "",

  offerKellerArea: "",
  offerKellerCount: "",

  internalOfferNotes: "",
  offerNotice: ""
};

export default initialValuesEdit;
