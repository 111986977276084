import {
//  areaActivity,
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers.js";

export default {
  ...Object.fromEntries(
    [
      "id",
      "project_id",
      "issuer_id",
      "issuer_certificate_id",
      "issuer_searchable",
      "issuer_has_certificates",
      "issuer_has_points",
      "issuer_max_points",
      "issuer_certificate_issuer_id",
      "issuer_certificate_rank"
    ].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["assigned_points", "Punktesystem"],
      ["notes", "Bemerkung"],
      ["issuer", "Aussteller"],
      ["issuer_certificate", "Zertifikat"]
    ].map(([key, label]) => [key, normalActivity(label)])
  )
};
