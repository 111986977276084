import Handler from "~/src/modules/handler.js";

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/unit-documents/:id",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data, { unitId }) => ({
    documents: data.files.map((file) => ({
      file_name: file.fileName,
      file_type: file.type,
      is_offer_document: file.isOfferDocument,
      public_name: file.publicName,
      show_public: file.showPublic,
      unit: {
        id: unitId
      }
    }))
  }),
  transformUpdateData: (data) => ({
    document: {
      public_name: data.publicName,
      show_public: data.showPublic
    }
  })
});

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
