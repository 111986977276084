import Joi from "joi";

const today = new Date();

const lastSecondToday = today;

lastSecondToday.setHours(23, 59, 59, 999);

export const initialValues = {
  buildingPart: {
    id: "",
    label: ""
  },
  category: {
    id: "",
    label: ""
  },
  count: 3,
  createdAfter: new Date("2020-01-01"),
  createdBefore: lastSecondToday,
  updatedAfter: new Date("2020-01-01"),
  updatedBefore: lastSecondToday
};

const schema = Joi.object({
  buildingPart: Joi.object({
    id: Joi.number().empty(""),
    label: Joi.string().empty("")
  }),
  category: Joi.object({
    id: Joi.string().empty("").required(),
    label: Joi.string().empty("")
  }),
  count: Joi.number().min(1).max(30)
    .required(),
  createdAfter: Joi.date().optional().allow(null),
  createdBefore: Joi.date().optional().allow(null),
  updatedAfter: Joi.date().optional().allow(null),
  updatedBefore: Joi.date().optional().allow(null)
});

export default schema;
