import cn from "classnames";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import camelCase from "lodash-es";
import { MoveRightIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  NumberParam, useQueryParam, withDefault
} from "use-query-params";

import formatActivityChange from "~/src/modules/format-activity-change/index.jsx";
import { formatDate } from "~/src/modules/formatters.js";
import { pluralize } from "~/src/modules/formatters.new.js";
import innerText from "~/src/modules/inner-text.js";
import { activityNameLabels, activityTypeLabels } from "~/src/modules/labels.jsx";
import sortActivityChanges from "~/src/modules/sort-activity-changes.js";

dayjs.extend(relativeTime);

const getActivityLink = ({
  activityId, activityType, projectId
}) => {
  const prefixPath = activityType === "automated-project" ? "automated-projects" : "projects";

  return `/${prefixPath}/${projectId}`;
};

const getActivityAreaLink = ({
  activityId, activityType, projectId
}) => {
  const prefixPath = activityType === "automated-project" ? "automated-projects" : "projects";
  const prefix = `/${prefixPath}/${projectId}`;

  let subPage = "";

  switch (activityType) {
    case "building-part":
    case "unit":
      subPage = "project-units";
      break;
    case "company-role":
      subPage = "project-companies";
      break;
    case "construction-info":
    case "construction-type":
    case "funding":
      subPage = "project-attributes";
      break;
    case "einlage":
    case "energy-class":
    case "location":
    case "project-certificate":
    case "widmung":
      subPage = "project-infos";
      break;
    case "project-contract-region":
      subPage = "project-contracts";
      break;
    case "project-document":
      subPage = "project-documents";
      break;
    case "project-quantity":
      subPage = "project-attributes";
      break;
    case "timeline":
      subPage = "project-timeline";
      break;
    case "transaction":
      subPage = "project-transactions";
      break;
    default:
      break;
  }

  let hash = "";

  switch (activityType) {
    case "building-part":
    case "company-role":
    case "einlage":
    case "energy-class":
    case "location":
    case "project-certificate":
    case "project-contract-region":
    case "project-document":
    case "project-quantity":
    case "transaction":
    case "unit":
    case "widmung":
      hash = `${activityType}-${activityId}`;
      break;
    case "construction-info":
    case "construction-type":
    case "funding":
    case "timeline":
      hash = `${activityType}`;
      break;
    default:
      break;
  }

  return ["automated-project", "project"].includes(activityType)
    ? `${prefix}`
    : `${prefix}/${subPage}#${hash}`;
};

/**
 *
 * @param props0 - The root object
 * @param props0.activity - The root object
 * @param props0.activity.id - The root object
 * @param props0.activity.activity_changes - The root object
 * @param props0.activity.activity_changes.before - The root object
 * @param props0.activity.activity_changes.after - The root object
 * @param props0.activity.activity_changes.changes - The root object
 * @param props0.activity.activity_id - The root object
 * @param props0.activity.activity_name - The root object
 * @param props0.activity.activity_type - The root object
 * @param props0.activity.automated_project - The root object
 * @param props0.activity.created_at - The root object
 * @param props0.activity.editor - The root object
 * @param props0.activity.editor.id - The root object
 * @param props0.activity.editor.username - The root object
 * @param props0.activity.project - The root object
 * @example
 */
const Activity = ({
  activity: {
    id,
    activity_changes: {
      before, after, changes
    },
    activity_id: activityId,
    activity_name: activityName,
    activity_type: activityType,
    automated_project = {},
    created_at: createdAt,
    editor: { id: editorId, username: editorUsername } = {},
    project = {}
  }
}) => {
  const { id: projectId, name: projectName } = activityType.startsWith("automated") ? automated_project : project;

  const [globalCollapseLevel, setGlobalCollapseLevel] = useQueryParam(
    "collapseLevel",
    withDefault(NumberParam, 3)
  );

  const [collapseLevel, setCollapseLevel] = useState(globalCollapseLevel);

  useEffect(() => {
    setCollapseLevel(globalCollapseLevel);
  }, [globalCollapseLevel]);

  const formattedActivityChanges = sortActivityChanges({
    changes,
    type: camelCase(activityType)
  })
    .map((key) => [
      formatActivityChange({
        activityType,
        key,
        otherValue: after[key],
        side: "before",
        value: before[key]
      }),
      formatActivityChange({
        activityType,
        key,
        otherValue: before[key],
        side: "after",
        value: after[key]
      })
    ])
    .filter(
      ([beforeDisplay, afterDisplay]) => !(beforeDisplay.empty && afterDisplay.empty) && !beforeDisplay.hidden
    )
    .filter(([beforeDisplay, afterDisplay]) => {
      let result = true;

      if (activityType === "unit" && activityName === "create") {
        if (after.offer_data_available && !after.sale_data_available) {
          result = !afterDisplay.unitSale;
        }
        if (!after.offer_data_available && after.sale_data_available) {
          result = !afterDisplay.unitOffer;
        }
      }

      return result;
    });

  const displayActivityChanges = (changes) => changes.map(([beforeDisplay, afterDisplay], changeIndex) => (
    <li className="flex w-full items-stretch rounded-sm border border-gray-100" key={changeIndex} >
      <span
        className={cn(
          "font-medium bg-gray-100 px-2 py-2 break-words hyphens-auto w-[25%] rounded-sm",
          {
            "bg-primary-green text-white": afterDisplay.unitSale,
            "bg-primary text-white": afterDisplay.unitOffer
          }
        )}
      >
        {beforeDisplay.label}
      </span>

      <span className="w-[35%] hyphens-auto break-words p-2 text-start font-mono">
        {beforeDisplay.value}
      </span>

      <div className="flex w-[5%] items-center justify-center  bg-gray-100">
        <MoveRightIcon className="h-5 text-gray-300" />
      </div>

      <span className="w-[35%] hyphens-auto break-words p-2 text-end font-mono">
        {afterDisplay.value}
      </span>
    </li>
  ));

  return (
    <div
      key={id}
      className={cn(
        "w-full bg-white border border-gray-200 rounded p-2 shadow-md hover:shadow-lg active:shadow-sm transition-all relative",
        {
          "col-start-1 col-span-7": !["unit", "unit-document"].includes(
            activityType
          ),
          "col-start-6 col-span-7": ["unit", "unit-document"].includes(
            activityType
          )
          // "col-start-1 col-span-12 w-6/12 justify-self-start": activityType !== "unit" && activities[index + 1]?.activity_type === "unit",
        }
      )}
    >
      <div
        className={cn(
          "absolute border-gray-300 border-dashed -top-1 h-[calc(100%_+_0.5rem)] pointer-events-none",
          {
            "left-[calc((-100%_/_7)_*_5)] w-[calc((100%_/_2)_-_0.5rem_+_1px)]  border-r":
              ["unit", "unit-document"].includes(activityType),
            "right-[calc((-100%_/_7)_*_5)] w-[calc((100%_/_2)_-_0.5rem_+_1px)] border-l":
              !["unit", "unit-document"].includes(activityType)
          }
        )}
      />

      <div
        className={cn(
          "w-full h-full rounded-sm border-l-4 pl-2 flex flex-col gap-1 items-stretch",
          {
            "border-amber-400": activityName === "update",
            "border-green-400": activityName === "create",
            "border-red-400": activityName === "delete"
          }
        )}
      >
        <div
          className="flex cursor-pointer flex-col gap-1"
          onClick={() => setCollapseLevel(collapseLevel === 1 ? 2 : 1)}
        >
          <div className="flex items-center justify-between gap-4">
            <div className="flex min-w-fit flex-col items-start gap-1">
              <span>
                <Link
                  className="underline hover:no-underline"
                  to={`/activities?editors=${editorId}`}
                >
                  {editorUsername}
                </Link>

                {" "}
                hat

                {" "}

                <span
                  className={cn("font-medium", {
                    "text-amber-500": activityName === "update",
                    "text-green-500": activityName === "create",
                    "text-red-500": activityName === "delete"
                  })}
                >
                  {activityNameLabels.get(activityName)}
                </span>
              </span>

              <span className="text-xs">
                {formatDate({
                  date: createdAt,
                  withTime: true
                })}

                {" "}

                |
                {" "}

                {dayjs(createdAt).fromNow()}
              </span>
            </div>

            <div className="w-full border-b border-dashed border-gray-300" />

            <Link
              className="flex min-w-fit flex-col items-end gap-1"
              rel="noreferrer"
              target="_blank"
              to={getActivityAreaLink({
                activityId,
                activityType,
                projectId
              })}
            >
              <span className="flex items-center gap-2">
                <span className="underline hover:no-underline">
                  {activityTypeLabels.get(activityType)}{activityType === "unit" && ` (${after?.name || before?.name})`}
                </span>

                {activityType === "unit" && (
                  <>
                    {
                      ((activityName === "create" && after.offer_data_available) ||
                        (activityName === "update" && formattedActivityChanges.some(([beforeDisplay]) => beforeDisplay.unitOffer)))
                        ? (
                          <span className="bg-primary block w-16 rounded-sm py-px text-center text-xs font-medium text-white">
                            Angebot
                          </span>
                        )
                        : null
                    }

                    {
                      ((activityName === "create" && after.sale_data_available) ||
                        (activityName === "update" && formattedActivityChanges.some(([beforeDisplay]) => beforeDisplay.unitSale)))
                        ? (
                          <span className="bg-primary-green-dark block w-16 rounded-sm py-px text-center text-xs font-medium text-white">
                            Verkauf
                          </span>
                        )
                        : null
                    }
                  </>
                )}
              </span>

              <span className="text-xs">
                {formattedActivityChanges.length}

                {" "}

                {pluralize(
                  "Änderung",
                  "Änderungen",
                  formattedActivityChanges.length
                )}
              </span>
            </Link>
          </div>

          <div className="my-2 flex w-full justify-between truncate text-xs">
            <Link
              className="underline hover:no-underline"
              rel="noreferrer"
              target="_blank"
              to={getActivityLink({
                activityId,
                activityType,
                projectId
              })}
            >
              {projectName}
            </Link>

            <Link
              className="rounded-md bg-gray-300 px-2 text-xs font-bold text-gray-900"
              rel="noreferrer"
              target="_blank"
              to={getActivityLink({
                activityId,
                activityType,
                projectId
              })}
            >
              ID: {projectId}
            </Link>
          </div>
        </div>

        {collapseLevel !== 1 && (
          <ul className="flex flex-col gap-1 text-xs">
            {displayActivityChanges(formattedActivityChanges.slice(0, 5))}

            {formattedActivityChanges.length > 5 &&
              collapseLevel === 3 &&
              displayActivityChanges(formattedActivityChanges.slice(5))}
          </ul>
        )}

        <span className="mt-2 flex w-full justify-center gap-2 text-gray-400">
          {collapseLevel !== 2 && (
            <span
              className="cursor-pointer hover:text-gray-600"
              onClick={() => setCollapseLevel(2)}
            >
              {collapseLevel === 1 ? "Mehr anzeigen" : "Weniger anzeigen"}
            </span>
          )}

          {collapseLevel === 2 && (
            <>
              <span
                className="cursor-pointer hover:text-gray-600"
                onClick={() => setCollapseLevel(1)}
              >
                Weniger anzeigen
              </span>

              {formattedActivityChanges.length > 5 && (
                <span
                  className="cursor-pointer hover:text-gray-600"
                  onClick={() => setCollapseLevel(3)}
                >
                  Mehr anzeigen
                </span>
              )}
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default Activity;
