import Handler from "~/src/modules/handler.js";

const {
  handleCreate
} = new Handler({
  endpoint: "/companies",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { setError, showError }) => {
    setError(true);
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data) => ({
    company: {
      additional_address_info: data.additional_address_info,
      city: data.city,
      country: data.country,
      house_number: data.house_number,
      internal_notes: data.internal_notes || null,
      is_zvr: data.is_zvr || false,
      name: data.name,
      reg_number: data.reg_number || null,
      searchable: data.searchable,
      street: data.street,
      zipcode: data.zipcode
    }
  })
});

export {
  handleCreate
};
