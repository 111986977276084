import Joi from "joi";

const initialValues = {
  buyable: false,
  rentable: false
};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project) {
    const { buyable, rentable } = project;

    return {
      buyable,
      rentable
    };
  }

  return initialValues;
};

const schema = Joi.object({
  buyable: Joi.boolean().required(),
  rentable: Joi.boolean().required()
});

export {
  generateEditValues,
  schema
};
