import Joi from "joi";

export const initialValues = {
  description: "",
  fgee_class: "",
  fgee_from: "",
  fgee_range: false,
  fgee_to: "",
  gstNumbers: [{ value: "" }],
  hwb_class: "",
  hwb_from: "",
  hwb_range: false,
  hwb_to: ""
};

/**
 *
 * @param energy_class
 * @example
 */
const generateEditValues = (energy_class) => {
  if (energy_class) {
    return {
      description: energy_class.description || "",
      fgee_class: energy_class.fgee_class || "",
      fgee_from: energy_class.fgee_from || "",
      fgee_range: Boolean(energy_class.fgee_to),
      fgee_to: energy_class.fgee_to || "",
      gstNumbers: energy_class.gst_numbers.map((gstNumber) => ({ value: gstNumber })) || [{ value: "" }],
      hwb_class: energy_class.hwb_class || "",
      hwb_from: energy_class.hwb_from || "",
      hwb_range: Boolean(energy_class.hwb_to),
      hwb_to: energy_class.hwb_to || ""
    };
  }

  return initialValues;
};

export { generateEditValues };

const schema = Joi.object({
  description: Joi.string().empty(""),
  fgee_class: Joi.string().empty("").max(4)
    .pattern(/[ +\-A-G]$/u),
  fgee_from: Joi
    .number().positive().max(999)
    .empty("")
    .when("fgee_range", {
      is: true,
      then: Joi.required()
    }),
  fgee_range: Joi.boolean().required(),
  fgee_to: Joi
    .number().positive().max(999)
    .empty("")
    .when("fgee_range", {
      is: true,
      then: Joi.required()
    }),
  gstNumbers: Joi.array().sparse().items(Joi.object({ value: Joi.string().empty("") })),
  hwb_class: Joi.string().empty("").max(4)
    .pattern(/[ +\-A-G]$/u),
  hwb_from: Joi
    .number().positive().max(999)
    .empty("")
    .when("hwb_range", {
      is: true,
      then: Joi.required()
    }),
  hwb_range: Joi.boolean().required(),
  hwb_to: Joi
    .number().positive().max(999)
    .empty("")
    .when("hwb_range", {
      is: true,
      then: Joi.required()
    })
}).or("hwb_from", "hwb_class", "fgee_from", "fgee_class");

export default schema;
