import { camelCase } from "lodash-es";

import API from "~/src/modules/api.js";
import { dateFormatLabels } from "~/src/modules/labels.jsx";

/**
 *
 * @param inputValue
 * @param name
 * @param countryCode
 * @example
 */
export async function loadLocationOptions(inputValue, name, countryCode) {
  return API.post("/locations/autosuggest", {
    countryCode,
    query: inputValue
  }).then((res) => res.data.payload.locations.map((location) => ({
    id: location.id,
    label: location?.address.label,
    value: location.id
  }))).catch((error) => {
    console.log(error);
  });
};

/**
 *
 * @param key
 * @param value
 * @example
 */
const getDifferentKeysFromKeyObject = (key, value) => {
  if (Array.isArray(value)) {
    if (new Set(value.map((v) => JSON.stringify(v))).size > 1) {
      return true;
    }
  }
  else if (Array.isArray(value) === false && new Set([value]).size > 1) {
    return true;
  }

  return false;
};

export { getDifferentKeysFromKeyObject };

/**
 *
 * @param fieldKey
 * @param differentFieldKeys
 * @param initialUnitValues
 * @param selectedUnitValues
 * @example
 */
const getDefaultFieldValue = (fieldKey, differentFieldKeys, initialUnitValues, selectedUnitValues) => {
  let updatedValue;

  for (const currentValue of selectedUnitValues[fieldKey]) {
    const initialValue = initialUnitValues[fieldKey];

    const currentValueIsDirty = currentValue !== initialValue &&
      currentValue !== updatedValue &&
      currentValue !== null;

    if (differentFieldKeys.includes(fieldKey)) {
      const shouldSetToNeutral = !["boolean", "string"].includes(typeof initialValue);

      if (shouldSetToNeutral) {
        const updatedDateValue = {
          label: dateFormatLabels.get(currentValue),
          value: currentValue
        };

        if (updatedValue !== undefined) {
          if (Array.isArray(updatedValue) && updatedValue.length === 0 && fieldKey !== "verwertetDateFormat") {
            updatedValue = currentValue;
          }
          else if (fieldKey === "verwertetDateFormat" && currentValue !== null) {
            updatedValue = updatedDateValue;
          }
        }
        else if (fieldKey === "verwertetDateFormat") {
          updatedValue = updatedDateValue;
        }
        else {
          updatedValue = currentValue;
        }
      }
      else if (fieldKey === "offerDataAvailable") {
        updatedValue = initialValue;
      }
      else {
        updatedValue = currentValue;
      }
    }
    else if (updatedValue !== undefined && fieldKey !== "verwertetDateFormat" && currentValueIsDirty) {
      updatedValue = currentValue;
    }
    else {
      updatedValue = initialValue;
    }
  }

  return updatedValue;
};

export { getDefaultFieldValue };

/**
 *
 * @param objectA
 * @param objectB
 * @example
 */
const compareObjects = (objectA, objectB) => {
  let differentKeys = [];

  for (const key in objectA) {
    if (isNullOrValue(objectA[key]) !== isNullOrValue(objectB[key]) && !differentKeys.includes(key)) {
      differentKeys = [...differentKeys, key];
    }
  }

  return differentKeys;
};

export { compareObjects };

/**
 *
 * @param value
 * @example
 */
function isNullOrValue(value) {
  if (typeof value === "object" &&
    !Array.isArray(value) &&
    value !== null) {
    return JSON.stringify(value);
  }
  else if (typeof value === "object" &&
    Array.isArray(value) &&
    value !== null) {
    return JSON.stringify(value);
  }
  else if (value === "") {
    return null;
  }

  return value;
}
