import { dateFormatLabels } from "~/src/modules/labels.jsx";

const initialValues = {
  buildingPart: {
    id: "",
    name: "",
    sorting: ""
  },
  buyable: false,
  name: "",
  offerDataAvailable: false,
  rentable: false,
  saleDataAvailable: false,
  show: true,
  unit_category: {
    id: "",
    code: "",
    name: ""
  },
  verwertet: false,
  verwertetDate: new Date(),
  verwertetDateFormat: {
    label: dateFormatLabels.get("month"),
    value: "month"
  },

  hideTz: false,
  kvId: "",
  kvUrl: "",
  saleDate: "",
  salePriceGross: "",
  salePriceNet: "",
  tzNumber: "",
  tzYear: "",

  offerPriceBruttoInvestor: "",
  offerPriceBruttoInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceInvestor: "",
  offerPriceInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceNormal: "",
  offerPriceNormalHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showOfferPriceBruttoInvestorHistory: false,
  showOfferPriceInvestorHistory: false,
  showOfferPriceNormalHistory: false,

  rentBk: "",
  rentBkHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentBrutto: "",
  rentBruttoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentNetto: "",
  rentNettoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showRentBkHistory: false,
  showRentBruttoHistory: false,
  showRentNettoHistory: false,

  privateBuyers: [],

  companyBuyers: [],

  floor: "",
  maisonette: false,
  position: "",
  positionGroup: "",
  positionStaircase: "",
  staircase: "",

  offerArea: "",
  saleArea: "",

  offerRoomCount: "",
  saleRoomCount: "",

  offerLoggiaArea: "",
  offerLoggiaCount: "",
  saleLoggiaArea: "",
  saleLoggiaCount: "",

  offerBalkonArea: "",
  offerBalkonCount: "",
  saleBalkonArea: "",
  saleBalkonCount: "",

  offerGartenArea: "",
  offerGartenCount: "",
  saleGartenArea: "",
  saleGartenCount: "",

  offerTerrasseArea: "",
  offerTerrasseCount: "",
  saleTerrasseArea: "",
  saleTerrasseCount: "",

  offerKellerArea: "",
  offerKellerCount: "",
  saleKellerArea: "",
  saleKellerCount: "",

  internalOfferNotes: "",
  internalSaleNotes: "",
  offerNotice: "",
  saleNotice: ""
};

export default initialValues;
