import { Listbox, Transition } from "@headlessui/react";
import cn from "classnames";
import { CheckIcon, ChevronsUpDownIcon } from "lucide-react";
import { Fragment } from "react";
import { useController } from "react-hook-form";

const collator = new Intl.Collator("de-AT");

/**
 *
 * @param props0 - The root object
 * @param props0.label - The root object
 * @param props0.selected - The root object
 * @param props0.value - The root object
 * @example
 */
const Option = ({
  label,
  selected,
  value
}) => (
  <Listbox.Option
    value={value}
    className={({ active }) => cn(
      "relative cursor-default select-none py-2 pl-10 pr-4",
      {
        "bg-gray-200": active && !selected,
        "bg-gray-300": selected,
        white: !active && !selected
      }
    )}
  >
    <span
      className={`block truncate ${selected ? "font-medium" : "font-normal"
      }`}
    >
      {label}
    </span>

    {selected
      ? (
        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
          <CheckIcon aria-hidden="true" className="size-5" />
        </span>
      )
      : null}
  </Listbox.Option>
);

/**
 *
 * @param props0 - The root object
 * @param props0.className - The root object
 * @param props0.control - The root object
 * @param props0.defaultValue - The root object
 * @param props0.label - The root object
 * @param props0.multiple - The root object
 * @param props0.name - The root object
 * @param props0.onChange - The root object
 * @param props0.options - The root object
 * @example
 */
const Select = ({
  className,
  control,
  defaultValue,
  label,
  multiple = false,
  name,
  onChange = () => { },
  options
}) => {
  const {
    field: {
      onBlur, onChange: innerOnChange, value
    }
  } = useController({
    control,
    defaultValue,
    name
  });

  const flatOptions = options
    .flatMap((option) => (Array.isArray(option?.value)
      ? option.value
      : option));

  return (
    <Listbox
      {...{
        multiple,
        onBlur,
        onChange: (e) => {
          innerOnChange(e);
          onChange(e);
        },
        value: multiple ? value : value.value
      }}
      by={multiple ? "value" : undefined}
    >
      <div className={cn("relative", className)}>
        {label ? <Listbox.Label>{label}</Listbox.Label> : null}

        <Listbox.Button className="relative w-full cursor-default rounded border border-gray-400 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
          <span className="block truncate">
            {
              multiple
                ? (value.length === 0
                  ? "Keine Auswahl"
                  : value
                    .toSorted(({ value: valueA }, { value: valueB }) => {
                      options.findIndex(({ value }) => value === valueA) - options.findIndex(({ value }) => value === valueB);
                    })
                    .map(({ label }) => label)
                    .join(", "))
                : flatOptions.find((option) => value === option.value).label
            }
          </span>

          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronsUpDownIcon
              aria-hidden="true"
              className="size-5 text-gray-400"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
            {options
              .map((option, index) => {
                const {
                  label, shown = true, value: optionValue
                } = option;

                if (Array.isArray(optionValue)) {
                  return (
                    <li className="flex flex-col" key={index}>
                      <span className="w-full bg-gray-100 px-4 py-2 text-sm font-medium">{label}</span>

                      <ul>
                        {
                          optionValue
                            .filter(({ shown }) => shown)
                            .map((groupOption, groupOptionIndex) => (
                              <Option
                                key={groupOptionIndex}
                                label={groupOption.label}
                                selected={groupOption.value === value}
                                value={groupOption.value}
                              />
                            ))
                        }
                      </ul>
                    </li>
                  );
                }

                if (shown) {
                  return (
                    <Option
                      key={multiple ? option.value : index}
                      label={label}
                      selected={multiple ? Boolean(value.find(({ value }) => optionValue === value)) : optionValue === value}
                      value={multiple ? option : optionValue}
                    />
                  );
                }

                return null;
              })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Select;
