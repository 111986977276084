import "temporal-polyfill/global.js";
import queryString from "query-string";
import {
  Redirect, Route, BrowserRouter as Router, Switch
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import ActivitiesPage from "~/src/pages/activities/index.jsx";
import AutomatedProjectPage from "~/src/pages/automated-projects/[id]/index.jsx";
import AutomatedProjectsPage from "~/src/pages/automated-projects/index.jsx";
import CompaniesPage from "~/src/pages/companies/index.jsx";
import ContractsPage from "~/src/pages/contracts/index.jsx";
import ErrorPage from "~/src/pages/error/index.jsx";
import FeedPage from "~/src/pages/feed/index.jsx";
import Login from "~/src/pages/login/index.jsx";
import NotFoundPage from "~/src/pages/not-found/index.jsx";
import ProjectPage from "~/src/pages/projects/[id]/index.jsx";
import ProjectsPage from "~/src/pages/projects/index.jsx";
import Users from "~/src/pages/users-page/index.jsx";

import Nav from "~/src/features/nav/index.jsx";

import ProtectedRoute from "~/src/ui/routes/protected-route/index.jsx";

import CertificatesPage from "./pages/certificates.jsx";
import PersonNamesPage from "./pages/person-names.jsx";
import UnitBuyerDifferencesPage from "./pages/unit-buyer-differences.jsx";

/**
 *
 * @example
 */
const App = () => (
  <Router>
    <QueryParamProvider
      adapter={ReactRouter5Adapter}
      options={{
        objectToSearchString: queryString.stringify,
        searchStringToObject: queryString.parse
      }}
    >
      <div>
        <Nav />

        <Switch>

          <ProtectedRoute exact path="/">
            <Redirect to="projects" />
          </ProtectedRoute>

          <Route path="/login">
            <Login />
          </Route>

          <ProtectedRoute exact path="/projects">
            <ProjectsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/projects/:id">
            <ProjectPage />
          </ProtectedRoute>

          <ProtectedRoute path="/users">
            <Users />
          </ProtectedRoute>

          <ProtectedRoute path="/persons">
            <PersonNamesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/feed">
            <FeedPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/unit-buyer-differences">
            <UnitBuyerDifferencesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/contracts">
            <ContractsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/companies">
            <CompaniesPage />
          </ProtectedRoute>

          <ProtectedRoute path="/certificates">
            <CertificatesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/activities">
            <ActivitiesPage />
          </ProtectedRoute>

          <ProtectedRoute path="/companies">
            <CompaniesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/automated-projects">
            <AutomatedProjectsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/automated-projects/:id">
            <AutomatedProjectPage />
          </ProtectedRoute>

          <Route path="/error">
            <ErrorPage />
          </Route>

          <Route path="*">
            <NotFoundPage />
          </Route>

        </Switch>
      </div>
    </QueryParamProvider>
  </Router>
);

export default App;
