import cn from "classnames";
import {
  useCallback, useEffect, useRef, useState
} from "react";
import { useDropzone } from "react-dropzone";

import { readFileAsync } from "~/src/modules/upload-helpers.js";

import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import UploadIcon from "~/src/ui/icons/upload-icon/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleCreate } from "./handlers.js";
import ProjectImageUploadField from "./project-image-upload-field.jsx";
import { initialValues, schema } from "./schema.js";

/**
 *
 * @param root0 - The root object
 * @param root0.projectId - The root object
 * @param root0.visible - The root object
 * @param root0.hide - The root object
 * @example
 */
const ProjectImageSlide = ({
  hide, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();
  const [files, setFiles] = useState([]);
  const childReference = useRef();

  /**
   *
   * @param innerForm
   * @example
   */
  const handleHide = (innerForm) => {
    innerForm.resetForm();
    setFiles([]);
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: async () => {
      await childReference.current.preSubmit();

      handleCreate({
        id: projectId,
        data: form.values,

        hide: () => {
          handleHide(form);
        },
        mutate,
        setIsLoading: form.setIsLoading,
        showError
      });
    },
    schema
  });

  const onDropAccepted = useCallback(async (acceptedFiles) => {
    const filesToRead = acceptedFiles.map((file) => readFileAsync(file));
    const files = await Promise.all(filesToRead);

    for (const file of files) {
      const fileName = `${file.name.replace(/\.[^./]+$/u, "")}.png`;

      Object.assign(file, {
        preview: URL.createObjectURL(file),
        fileName: `${globalThis?.settings?.REACT_APP_HOST_ENV}/project-thumbnails/${crypto.randomUUID()}`
      });
    }

    setFiles(files);
  }, []);

  const {
    getInputProps, getRootProps, isDragActive
  } = useDropzone({
    maxSize: 1_024 * 1_024 * 50,
    accept: {
      "image/*": []
    },
    multiple: false,
    onDropAccepted
  });

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    for (const file of files) {
      URL.revokeObjectURL(file.preview);
    }
  }, [files]);

  useEffect(() => {
    if (files.length > 0) {
      const file = files[0];

      form.updateProperties({
        fileName: file.fileName,
        imageLandscape: true,
        showPublic: true
      });
    }
  }, [files]);

  const onUpload = (file) => {
    setFiles((previous) => previous.map((previousFile) => {
      if (previousFile.fileName === file.fileName) {
        return Object.assign(previousFile, {
          imageLandscape: true,
          showPublic: true,
          uploaded: true
        });
      }

      return previousFile;
    }));
  };

  return (
    <SlideOver
      visible={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">

          <SlideFormHeader
            closeDisabled={form.isLoading}
            title="Projektbild hinzufügen"
            handleHide={() => {
              handleHide(form);
            }}
          />

          <div className="mt-4">
            <div
              {...getRootProps()}
              className={cn(
                "mx-4 p-8 h-48 flex justify-center items-center cursor-pointer border-2 border-gray-400 border-dashed bg-gray-50 text-gray-400 text-lg font-medium",
                {
                  "border-2 border-indigo-500": isDragActive
                }
              )}
            >
              <input {...getInputProps()} />

              <UploadIcon />

              <span className="ml-4">Projektbild hinzufügen</span>
            </div>

            <div className="mt-10 divide-y divide-gray-300">

              {files.map((file, index) => (
                <ProjectImageUploadField
                  file={file}
                  form={form}
                  index={index}
                  key={file.name + index}
                  onUpload={onUpload}
                  ref={childReference}
                />
              ))}

            </div>
          </div>

        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton
              disabled={form.isLoading}
              onClick={() => {
                handleHide(form);
              }}
            />

            <FormSubmitButton isSubmitting={form.isLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>

      </form>
    </SlideOver>
  );
};

export default ProjectImageSlide;
