import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param options0 - The root object
 * @param options0.name - The root object
 * @param options0.page - The root object
 * @param query
 * @example
 */
const usePersonNames = (query) => {
  const parameters = new URLSearchParams(query);

  const {
    data, error, mutate
  } = useSWR(`/person-names?${parameters}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    pagination: data?.payload.pagination,
    personNames: data?.payload.personNames
  };
};

export default usePersonNames;
