import innerText from "~/src/modules/inner-text.js";

import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.new.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.autoFocus - The root object
 * @param props0.control - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.int - The root object
 * @param props0.label - The root object
 * @param props0.labels - The root object
 * @param props0.labels.checkbox - The root object
 * @param props0.labels.to - The root object
 * @param props0.meter - The root object
 * @param props0.name - The root object
 * @param props0.optional - The root object
 * @param props0.placeholder - The root object
 * @param props0.prefix - The root object
 * @param props0.register - The root object
 * @param props0.scale - The root object
 * @param props0.type - The root object
 * @param props0.watch - The root object
 * @example
 */
const RangeField = ({
  autoFocus = false,
  control,
  disabled = false,
  errors,
  int = false,
  label,
  labels: {
    checkbox: checkboxLabel,
    to: toLabel
  },
  meter = false,
  name,
  optional,
  prefix,
  register,
  scale,
  type = "search",
  watch,

  id = name,
  placeholder = innerText(label)
}) => {
  const checkboxName = `${name.replace("_from", "")}_range`;
  const checkboxId = `${id.replace("_from", "")}_range`;

  const toName = `${name.replace("_from", "")}_to`;
  const toId = `${id.replace("_from", "")}_to`;

  // oneliner for above
  // const [checkboxName, checkboxId, toName, toId] = ["range", "to"].map((nameSuffix) => [name, id].map((value) => `${value.replace("_from", "")}_${nameSuffix}`));

  const showCheckbox = optional;

  const checkbox = (
    <div className="sm:col-span-1">
      <InlineCheckbox
        {...{
          id: checkboxId,
          autoFocus,
          disabled,
          errors,
          label: checkboxLabel,
          name: checkboxName,
          register,
          type
        }}
      />
    </div>
  );

  const numberFieldProperties = {
    id,
    autoFocus,
    control,
    disabled,
    errors,
    int,
    label,
    meter,
    name,
    placeholder,
    register,
    scale,
    type
  };

  const showToField = !optional || watch(checkboxName);

  const toField = (
    <NumberField
      {...{
        ...numberFieldProperties,
        id: toId,
        label: toLabel,
        name: toName,
        placeholder: toLabel
      }}
    />
  );

  return (
    <>
      <NumberField
        {...{
          ...numberFieldProperties,
          outerSuffix: showCheckbox && checkbox,
          prefix
        }}
      />

      {showToField ? toField : null}
    </>
  );
};

export default RangeField;
