import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import validationResolver from "~/src/modules/validation-resolver.js";

import useAutomatedProject from "~/src/hooks/use-automated-project.js";
import useNotification from "~/src/hooks/use-notification.js";

import CheckboxInput from "~/src/ui/forms/checkbox-input/index.jsx";

import { handleUpdate } from "./handlers.js";
import schema, { generateEditValues, initialValues } from "./schema.js";

/**
 *
 * @param props0 - The root object
 * @param props0.projectId - The root object
 * @param props0.setEditing - The root object
 * @example
 */
const AutomatedProjectEnabledForm = ({ projectId, setEditing }) => {
  const { automated_project: project, mutate } = useAutomatedProject(projectId);

  const [formIsLoading, setIsLoading] = useState(false);

  const { showError, showSuccess } = useNotification();

  const {
    formState: { errors }, handleSubmit, register, reset
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  useEffect(() => {
    if (project) {
      const { enabled } = project;

      reset(generateEditValues({ enabled }));
    }
  }, [reset, project]);

  const handleClose = () => {
    reset();
    setEditing(false);
  };

  const onSubmit = (data) => {
    handleUpdate({
      data,
      handleClose,
      mutate,
      projectId: project.id,
      setIsLoading,
      showError,
      showSuccess
    });
  };

  return (
    <form autoComplete="off" onChange={handleSubmit(onSubmit)} onSubmit={handleSubmit(onSubmit)}>
      <CheckboxInput
        name="enabled"
        {...{
          errors,
          register
        }}
      />
    </form>
  );
};

export default AutomatedProjectEnabledForm;
