const roleOptions = [
  {
    label: "Erfasser",
    value: "editor"
  },
  {
    label: "Moderator",
    value: "moderator"
  },
  {
    label: "Administrator",
    value: "admin"
  },
  {
    label: "Deaktiviert",
    value: "inactive"
  }
];

export default roleOptions;
