import Joi from "joi";

const locationSchema = {
  city: Joi.string().empty("")
    .when("street", {
      is: Joi.string().required(),
      then: Joi.string().required()
    }),
  coordinates: Joi.object({
    label: Joi.string(),
    lat: Joi.number(),
    lng: Joi.number()
  }).allow(null, ""),
  country: Joi.object({
    code: Joi.string().empty(""),
    name: Joi.string().empty(""),
    value: Joi.number().positive().empty("")
  }).unknown().rename("value", "id")
    .empty(null, "")
    .optional()
    .when("city", {
      is: Joi.string().required(),
      then: Joi.object().required()
    })
    .when("street", {
      is: Joi.string().required(),
      then: Joi.object().required()
    })
    .when("houseNumber", {
      is: Joi.string().required(),
      then: Joi.object().required()
    })
    .when("zipcode", {
      is: Joi.string().required(),
      then: Joi.object().required()
    }),
  houseNumber: Joi.string().empty(""),
  street: Joi.string().empty("")
    .when("houseNumber", {
      is: Joi.string().required(),
      then: Joi.string().required()
    }),
  zipcode: Joi.string().empty("")
};

export default locationSchema;
