const dateFormats = [
  {
    label: "Tag",
    value: "day"
  },
  {
    label: "Monat",
    value: "month"
  },
  {
    label: "Quartal",
    value: "quarter"
  },
  {
    label: "Jahr",
    value: "year"
  }
];

export default dateFormats;
