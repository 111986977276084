import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import validationResolver from "~/src/modules/validation-resolver.js";

import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import CancelFormButton from "~/src/ui/buttons/cancel-form-button/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import InputArea from "~/src/ui/forms/input-area/index.new.jsx";

import { handleUpdate } from "./handlers.js";
import schema, { generateEditValues, initialValues } from "./schema.js";

const ProjectPublicNotesForm = ({ projectId, setEditing }) => {
  const { mutate, project } = useProject(projectId);

  const [formIsLoading, setIsLoading] = useState(false);

  const { showError, showSuccess } = useNotification();

  const {
    formState: { errors }, handleSubmit, register, reset
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  useEffect(() => {
    if (project) {
      const { public_notes } = project;

      reset(generateEditValues({ public_notes }));
    }
  }, [reset, project]);

  const handleClose = () => {
    reset();
    setEditing(false);
  };

  const onSubmit = (data) => {
    handleUpdate({
      id: project.id,
      data,
      handleClose,
      mutate,
      setIsLoading,
      showError,
      showSuccess
    });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

      <InputArea
        name="public_notes"
        {...{
          errors,
          register
        }}
      />

      <div className="mt-8">
        <FormSubmitButton isSubmitting={formIsLoading}>
          Speichern
        </FormSubmitButton>

        <CancelFormButton onClick={handleClose} />
      </div>

    </form>
  );
};

export default ProjectPublicNotesForm;
