import Handler from "~/src/modules/handler.js";

const {
  handleUpdate
} = new Handler({
  endpoint: "/projects/:id/project-notes",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { handleClose, mutate }) => {
    await mutate();
    handleClose();
  },
  transformUpdateData: (data) => ({
    project: {
      project_notes: data
    }
  })
});

export {
  handleUpdate
};
