import { Link } from "react-router-dom";

/**
 *
 * @param props0 - The root object
 * @param props0.automated - The root object
 * @param props0.project - The root object
 * @example
 */
const ActiveProjectLink = ({ automated, project }) => {
  const pathPrefix = automated ? "/automated-projects" : "/projects";
  const linkOptions = {
    pathname: `${pathPrefix}/${project.id}`,
    state: {
      project
    }
  };

  return (
    <Link className="truncate font-medium text-gray-500 hover:no-underline" to={linkOptions}>
      <p className="leading-none underline">{project?.name}</p>

      <div className="mt-1 space-x-1.5 text-right">
        <p className="text-xs">{(project?.active_project_editor_id || project?.active_editor_id) ? "Projekterfassung" : ""}</p>

        <p className="text-xs">{project?.active_unit_editor_id ? "Objekterfassung" : ""}</p>
      </div>
    </Link>
  );
};

export default ActiveProjectLink;
