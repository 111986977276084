import {
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers.js";

export default {
  ...Object.fromEntries(
    [
      "id",
      "project_id",
      "enabled"
    ].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["kgnr", "KG"],
      ["ez", "EZ"],
      ["comment", "Kommentar"]
    ].map(([key, label]) => [key, normalActivity(label)])
  )
};
