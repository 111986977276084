import { Link } from "react-router-dom";

import displayDiff from "./display-diff.jsx";

const fileActivity = (label) => (innerValue, otherValue, side) => {
  const innerValueUrl = innerValue ? `https://immodeveloper.fra1.digitaloceanspaces.com/${innerValue}` : "";
  const otherValueUrl = otherValue ? `https://immodeveloper.fra1.digitaloceanspaces.com/${otherValue}` : "";

  return {
    empty: innerValue === "" || innerValue === null || innerValue === undefined,
    label,
    value: innerValue && <a className="underline hover:underline" href={innerValueUrl} rel="noreferrer" target="_blank">{displayDiff(innerValueUrl, otherValueUrl, side)}</a>
  };
};

export default fileActivity;
