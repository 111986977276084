import cn from "classnames";

import innerText from "~/src/modules/inner-text.js";

import InputArea from "../input-area/index.new.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.compact - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.inline - The root object
 * @param props0.isDetailsView - The root object
 * @param props0.label - The root object
 * @param props0.name - The root object
 * @param props0.outerPrefix - The root object
 * @param props0.outerSuffix - The root object
 * @param props0.placeholder - The root object
 * @param props0.prefix - The root object
 * @param props0.range - The root object
 * @param props0.register - The root object
 * @param props0.suffix - The root object
 * @param props0.type - The root object
 * @example
 */
const AreaField = ({
  autoFocus = false,
  className,
  compact,
  disabled = false,
  errors,
  inline,
  isDetailsView = false,
  label,
  name,
  outerPrefix,
  outerSuffix,
  prefix,
  range,
  register,
  suffix,
  type = "search",

  id = name,
  placeholder = isDetailsView ? "-" : innerText(label)
}) => {
  if (isDetailsView) {
    disabled = true;
  }

  return (
    <div className={cn("py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200", className)}>
      <div
        className={cn(
          "sm:grid sm:gap-x-4",
          {
            "p-0": inline,
            "py-6 px-6": !inline,
            "sm:grid-cols-3": !compact,
            "sm:grid-cols-6": compact
          }
        )}
      >
        <div className={inline ? "col-span-3" : "col-span-1"}>
          <label
            htmlFor={id}
            className={cn(
              "inline-block text-sm font-medium sm:mt-px sm:pt-2",
              {
                "text-gray-700 text-base font-normal": isDetailsView,
                "text-gray-900": !isDetailsView
              }
            )}
          >
            {label}
          </label>
        </div>

        {outerPrefix}

        <div
          className={cn(
            "relative grid grid-cols-3",
            {
              "col-span-5": compact,
              [inline ? "col-span-2" : "col-span-1"]: prefix && !compact,
              [inline ? "col-span-3" : "col-span-2"]: !prefix && !compact
            }
          )}
        >
          {prefix}

          <InputArea
            {...{
              id,
              autoFocus,
              disabled,
              errors,
              isDetailsView,
              name,
              placeholder,
              register,
              type
            }}
            className={cn("w-full", {
              "col-span-2": prefix,
              "col-span-3": !prefix
            })}
          />

          {suffix}
        </div>

        {outerSuffix}
      </div>
    </div>
  );
};

export default AreaField;
