/**
 *
 * @param props0 - The root object
 * @param props0.editPossible - The root object
 * @param props0.onClick - The root object
 * @example
 */
const DeleteButton = ({ editPossible, onClick }) => {
  if (editPossible) {
    return (
      <div className="mt-3 flex items-start justify-end sm:col-span-1 sm:mt-0">
        <button
          className="rounded-md px-1 text-sm font-medium text-red-600 hover:text-red-500 focus:underline focus:outline-none"
          onClick={onClick}
          type="button"
        >
          löschen
        </button>
      </div>
    );
  }

  return null;
};

export default DeleteButton;
