const options = [
  {
    label: "Wien",
    value: "vie"
  },
  {
    label: "Niederösterreich",
    value: "noe"
  },
  {
    label: "Burgenland",
    value: "bgl"
  },
  {
    label: "Kärnten",
    value: "kae"
  },
  {
    label: "Oberösterreich",
    value: "ooe"
  },
  {
    label: "Salzburg",
    value: "sbg"
  },
  {
    label: "Steiermark",
    value: "stk"
  },
  {
    label: "Tirol",
    value: "tir"
  },
  {
    label: "Vorarlberg",
    value: "vbg"
  }
];

export default options;
