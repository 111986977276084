import { constructionPhaseLabels, marketingPhaseLabels } from "./labels.jsx";

const sorting = {
  buildingPart: ["name", "sorting"],
  companyRole: [
    "name",
    "company",
    "company_reg_number"
  ],
  constructionInfo: [
    "construction_start_date",
    "estimated_finish_date",
    "construction_year",
    "is_new",
    "reconstruction_year"
  ],
  constructionType: [
    ["neubau", "Neubau"],
    ["dg_ausbau", "DG-Ausbau"],
    ["sanierung", "Sanierung Altbestand"],
    ["fremdbestand", "Fremdbestand"],
    ["unsaniert", "unsaniert"]
  ]
    .flatMap(([key, label]) => [
      key,
      `${key}_area_range_from`,
      `${key}_area_range_to`,
      `${key}_area_total`,
      `${key}_unit_area_total`,
      `${key}_count`
    ]),
  einlage: [
    "katastralgemeinde_code",
    "katastralgemeinde",
    "katastralgemeinde_bezirksgericht_code",
    "katastralgemeinde_bezirksgericht",
    "area",
    "ez_number",
    "gst_numbers"
  ],
  energyClass: [
    "hwb_from",
    "hwb_to",
    "hwb_class",
    "fgee_from",
    "fgee_to",
    "fgee_class",
    "description"
  ],
  funding: [
    "not_funded",
    "normal_funded",
    "super_funded",
    "smart_funded",
    "smart_super_funded",
    "wiener_wohnbau",
    "gemeinde"
  ]
    .flatMap((key) => [key, `${key}_total`]),
  location: [
    "default_location",
    "city_zipcode",
    "city",
    "city_state",
    "street",
    "housenumber",
    "lat",
    "lng"
  ],
  project: [
    "name",
    "active_project_editor",
    "active_unit_editor",
    "project_research_filter",
    "unit_research_filter",
    "project_notes",
    "unit_notes",
    "last_review_date",
    "last_review_should_filter",
    "last_unit_offer_review_date",
    "last_unit_review_should_filter",
    "last_unit_offer_research_date",
    "last_unit_sale_research_date",
    "research_notes",
    "name_additional",
    "description",
    "public_notes",
    "show_gka",
    "gka_area",
    "gka_notes",
    "published",
    "buyable",
    "rentable",
    "image_file_name",
    "image_landscape",
    "show_image_public"
  ],
  projectCertificate: [
    "issuer",
    "issuer_certificate_rank",
    "assigned_points"
  ],
  projectContractRegion: [
    "kgnr",
    "ez",
    "comment"
  ],
  projectDocument: [
    "public_name",
    "file_name",
    "show_public"
  ],
  projectQuantity: [
    "unit_category",
    "total_units",
    "total_area_range_from",
    "total_area_range_to"
  ],
  timeline: [
    "current_construction_phase",
    ...Object.keys(constructionPhaseLabels)
      .filter((key) => key.startsWith("construction"))
      .flatMap((key) => ["date", "date_format"].map((type) => `${key}_${type}`)),
    "current_marketing_phase",
    ...Object.keys(marketingPhaseLabels)
      .filter((key) => key.startsWith("marketing"))
      .flatMap((key) => ["date", "date_format"].map((type) => `${key}_${type}`))
  ],
  transaction: [
    "transaction_date",
    "transaction_category",
    "property_area",
    "bauzins_net",
    "bauzins_gross",
    "sale_price_net",
    "sale_price_gross",
    ...["seller", "buyer"]
      .flatMap((type) => [
        type,
        `${type}_private`,
        `${type}_searchable`,
        `${type}_reg_number`,
        `${type}_is_zvr`,
        `${type}_internal_notes`,
        `${type}_street`,
        `${type}_house_number`,
        `${type}_zipcode`,
        `${type}_city`,
        `${type}_additional_address_info`,
        `${type}_country`
      ]),
    "usable",
    "kv_id",
    "katastralgemeinde_code",
    "katastralgemeinde",
    "katastralgemeinde_bezirksgericht_code",
    "katastralgemeinde_bezirksgericht",
    "tz_number",
    "brez",
    "br_stamm_ez",
    "ez_numbers",
    "gst_numbers",
    "baurechtsdauer",
    "baurechtsdauer_date",
    "build_area",
    "green_area",
    "usable_area",
    "notes"
  ],
  unit: [
    "show",
    "building_part",
    "position_staircase",
    "position",
    "unit_category",
    "name",
    "staircase",
    "floor",
    "maisonette",
    "verwertet",

    "rentable",
    ...[
      "netto",
      "brutto",
      "bk"
    ]
      .flatMap((priceType) => {
        const prefix = "rent";

        const part = `${prefix}_${priceType}`;

        return [
          part,
          `show_${part}_history`,
          `${part}_history`
        ];
      }),

    "buyable",
    ...[
      "normal",
      "investor",
      "brutto_investor"
    ]
      .flatMap((priceType) => {
        const prefix = "offer_price";

        const part = `${prefix}_${priceType}`;

        return [
          part,
          `show_${part}_history`,
          `${part}_history`
        ];
      }),

    "kv_id",
    "tz_number",
    "hide_tz",
    "sale_date",
    "sale_price_net",
    "sale_price_gross",

    "private_buyers",
    "company_buyers",

    ...["date", "date_format"].map((type) => `verwertet_${type}`),

    ...["offer", "sale"]
      .flatMap((category) => [
        `${category}_data_available`,
        `${category}_area`,
        `${category}_room_count`,
        `${category}_notice`,
        `internal_${category}_notes`,
        ...[
          "loggia",
          "balkon",
          "garten",
          "terrasse",
          "keller"
        ]
          .flatMap((key) => [`${category}_${key}_count`, `${category}_${key}_area`])
      ]),
    "tz_year"
  ],
  unitDocument: "public_name",
  widmung: [
    "plan_number",
    "widmung",
    "bauklasse",
    "bauweise",
    "height_limit_sign",
    "height_limit",
    "height_limit_to",
    "additional"
  ]
};

const indexOfForSort = (array, value) => (array.indexOf(value) + 1 || array.length + 1) - 1;

const sortActivityChanges = ({ changes, type }) => [...changes].sort((keyA, keyB) => (sorting[type] ? indexOfForSort(sorting[type], keyA) - indexOfForSort(sorting[type], keyB) : 0));

export default sortActivityChanges;
