const options = [
  {
    label: "Neubau",
    value: true
  },
  {
    label: "Sanierung/Gebäudebestand",
    value: false
  }
];

export default options;
