import { useParams } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";
import useAutomatedProject from "~/src/hooks/use-automated-project.js";
import useEdit from "~/src/hooks/use-edit.js";

import EditButton from "~/src/ui/buttons/edit-project-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import PublicNotesForm from "./form.jsx";

/**
 *
 * @example
 */
const AutomatedPublicProjectNotes = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    automated_project: project, isError, isLoading
  } = useAutomatedProject(id);
  const {
    editPossible, editing, setEditing
  } = useEdit({
    automated: true,
    project,
    user
  });

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  return (
    <TabListContainer>

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Projektbemerkungen" />

        <DescriptionContainer>
          {editing
            ? <PublicNotesForm projectId={id} setEditing={setEditing} />
            : <span className="whitespace-pre-line">{project?.public_notes}</span>}
        </DescriptionContainer>

        <EditButton
          editPossible={editPossible}
          editing={editing}
          onClick={() => setEditing((previous) => !previous)}
        />

      </div>

    </TabListContainer>
  );
};

export default AutomatedPublicProjectNotes;
