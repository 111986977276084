import API from "~/src/modules/api.js";

const setImageAccess = async ({ name, showPublic }) => {
  const response = await API.post("/files/set-access", {
    name,
    showPublic
  });
};

export default setImageAccess;
