/**
 *
 * @param props0 - The root object
 * @param props0.styles - The root object
 * @example
 */
const UploadIcon = ({ styles }) => (
  <svg className={`size-10 ${styles}`} fill="none" height={0} stroke="currentColor" viewBox="0 0 24 24" width={0}>
    <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
  </svg>
);

export default UploadIcon;
