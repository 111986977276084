import {
  BooleanParam, DelimitedArrayParam, DelimitedNumericArrayParam, NumberParam, StringParam, useQueryParams, withDefault
} from "use-query-params";

/**
 *
 * @example
 */
const useProjectQueryParameters = () => {
  const [query, setQuery] = useQueryParams({
    cityId: withDefault(NumberParam, ""),
    constructionPhaseFilter: withDefault(StringParam, "all"),
    marketingPhaseFilter: withDefault(StringParam, "all"),
    name: withDefault(StringParam, ""),
    page: withDefault(NumberParam, 1),
    researchFilter: withDefault(StringParam, "all"),
    sortFilter: withDefault(StringParam, "updated-desc"),
    state: withDefault(StringParam, ""),
    statusFilter: withDefault(StringParam, "all"),
    street: withDefault(StringParam, ""),
    synchronizedFilter: withDefault(StringParam, "all")
  });

  return {
    query,
    setQuery
  };
};

export default useProjectQueryParameters;
