import { Link } from "react-router-dom";

import displayDiff from "./display-diff.jsx";

const urlActivity = (label) => (innerValue, otherValue, side) => ({
  empty: innerValue === "" || innerValue === null || innerValue === undefined,
  label,
  value: innerValue && <a className="no-hyphens underline hover:underline" href={`${innerValue}`} rel="noreferrer" target="_blank">{displayDiff(innerValue ?? "", otherValue ?? "", side)}</a>
});

export default urlActivity;
