import Joi from "joi";

export const initialValues = {
  show_total_area: false,
  total_area_range_from: "",
  total_area_range_to: "",
  total_units: "",
  unit_category: {
    id: "",
    code: "",
    name: ""
  }
};

/**
 *
 * @param quantity
 * @example
 */
const generateEditValues = (quantity) => {
  if (quantity) {
    return {
      show_total_area: Boolean(quantity.total_area_range_to),
      total_area_range_from: quantity.total_area_range_from || "",
      total_area_range_to: quantity.total_area_range_to || "",
      total_units: quantity.total_units || "",
      unit_category: {
        id: quantity.category.id || "",
        code: quantity.category.code || "",
        name: quantity.category.name || ""
      }
    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  show_total_area: Joi.boolean().required(),
  total_area_range_from: Joi.number().positive().empty(""),
  total_area_range_to: Joi.number().positive().empty(""),
  total_units: Joi.number().positive().empty(""),
  unit_category: {
    id: Joi.number().positive().empty("")
      .required(),
    code: Joi.string().empty("").required(),
    name: Joi.string().empty("").required()
  }
});
