/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
const TabListContainer = ({ children }) => (
  <div className="py-8">
    {children}
  </div>
);

export default TabListContainer;
