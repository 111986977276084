import { dateFormatLabels } from "~/src/modules/labels.jsx";

import customActivity from "./custom-activity.js";

const dateFormatActivity = (label) => customActivity({
  label,
  value: (value) => dateFormatLabels.get(value)
});

export default dateFormatActivity;
