import { Trash2Icon } from "lucide-react";

import API from "~/src/modules/api.js";

import useAuth from "~/src/hooks/use-auth.js";

import Button from "~/src/ui/buttons/button/index.jsx";
import DeleteButton from "~/src/ui/buttons/delete-edit-project-button/index.jsx";

import { DetailButton, EditButton } from "./entry/_exports.js";

const Header = () => (
  <div className="hidden grid-cols-4 border-b-2 border-gray-200 md:grid">
    <div className="col-span-1 bg-gray-100 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-gray-500 md:col-span-full">
      <p className="ml-16">Name</p>
    </div>
  </div>
);

const Entry = ({
  isAdmin, issuer, issuersTempFrontEndValidation, modal, mutate, reactForm
}) => {
  const { user } = useAuth();

  const isAnton = user?.email === "stiglitz@rsgroup.at";

  const deleteIssuer = async () => {
    await API.delete(`/certificates/issuers/${issuer.id}`);

    mutate();
  };

  return (
    <div className="col-span-1 grid md:grid-cols-4" key={`issuer_${issuer.id}`}>

      <div className="col-span-1 flex items-start truncate whitespace-nowrap p-4 text-left text-sm text-gray-500 md:col-span-3">

        <DetailButton
          {...{
            issuer,
            issuersTempFrontEndValidation,
            modal,
            reactForm
          }}
        />

      </div>

      <div className="flex items-center justify-between whitespace-nowrap px-4 py-1 text-left text-sm font-medium text-gray-500 md:py-4">

        <div className="ml-4 flex w-12 gap-4">

          {
            (isAdmin)
              ? (
                <EditButton
                  {...{
                    issuer,
                    issuersTempFrontEndValidation,
                    modal,
                    reactForm
                  }}
                />
              )
              : null
          }

          {
            isAnton
              ? (
                <Button
                  icon={Trash2Icon}
                  onClick={deleteIssuer}
                />
              )
              : null
          }

        </div>

      </div>
    </div>
  );
};

export { Entry, Header };
