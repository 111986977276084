import {
  customActivity,
  dateActivity,
  hiddenActivity,
  htmlActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers.js";
import { formatArea } from "~/src/modules/formatters.new.js";
import { researchFilterLabels } from "~/src/modules/labels.jsx";

export default {
  ...Object.fromEntries(
    [
      "created_at",
      "deleted_at",
      "updated_at",
      "id",
      "active_project_editor_id",
      "active_project_editor_email",
      "active_project_editor_role",
      "active_unit_editor_id",
      "active_unit_editor_email",
      "active_unit_editor_role",
      "new_contracts_count",
      "slug",
      "unpublished_changes",
      "last_contract_update",
      "last_publish_date",
      "last_publisher",
      "last_publisher_id",
      "last_reviewer_id",
      "last_reviewer_email",
      "last_reviewer_role",
      "last_unit_offer_researcher_id",
      "last_unit_offer_researcher_email",
      "last_unit_offer_researcher_role",
      "last_unit_offer_reviewer_id",
      "last_unit_offer_reviewer_email",
      "last_unit_offer_reviewer_role",
      "last_unit_sale_researcher_id",
      "last_unit_sale_researcher_email",
      "last_unit_sale_researcher_role"
    ].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["active_project_editor", "Aktive Projekterfasserung"],
      ["active_unit_editor", "Aktive Objekterfasserung"],
      ["last_reviewer", "Letzte Projektkontrolle - Person"],
      ["last_unit_offer_researcher", "Letzte Erfassung (Angebote) - Person"],
      ["last_unit_offer_reviewer", "Letzte Kontrolle (Angebote) - Person"],
      ["last_unit_sale_researcher", "Letzte KV Erfassung (Verkäufe) - Person"],
      ["name", "Projektname"],
      ["name_additional", "Namenszusatz"],
      ["description", "Kurzbeschreibung"],
      ["public_notes", "Projektbemerkungen"],
      ["published", "Projekt öffentlich anzeigen"],
      ["buyable", "Eigentum"],
      ["rentable", "Miete"],
      ["show_gka", "Grundkostenanteil anzeigen"],
      ["gka_notes", "Information zur Berechnung des GKA"],
      ["image_file_name", "Projektbild"],
      ["image_landscape", "Bild im Querformat"],
      ["show_image_public", "Projektbild öffentlich anzeigen"],
      ["project_notes", "Projektnotizen"],
      ["unit_notes", "Objektnotizen"],
      ["research_notes", "Externe Ressourcen"],
      ["last_review_should_filter", "Projektkontrolle aktiv"],
      ["last_unit_review_should_filter", "Objektkontrolle aktiv"]
    ].map(([key, label]) => [key, normalActivity(label)])
  ),

  ...Object.fromEntries(
    [
      ["project_notes", "Projektnotizen"],
      ["unit_notes", "Objektnotizen"],
      ["research_notes", "Externe Ressourcen"]
    ].map(([key, label]) => [key, htmlActivity(label)])
  ),

  ...Object.fromEntries(
    [
      ["last_review_date", "Letzte Projektkontrolle - Datum"],
      ["last_unit_offer_research_date", "Letzte Erfassung (Angebote) - Datum"],
      ["last_unit_offer_review_date", "Letzte Kontrolle (Angebote) - Datum"],
      ["last_unit_sale_research_date", "Letzte KV Erfassung - Datum"]
    ].map(([key, label]) => [key, dateActivity(label)])
  ),

  gka_area: customActivity({
    label: "Projektbild Ausrichtung",
    value: formatArea
  }),

  project_research_filter: customActivity({
    label: "Projekterfassung",
    value: (value) => researchFilterLabels[value]
  }),
  unit_research_filter: customActivity({
    label: "Objekterfassung",
    value: (value) => researchFilterLabels[value]
  })
};
