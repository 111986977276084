import Handler from "~/src/modules/handler.js";

import { initialValues } from "./schema.js";

const {
  handleUpdate
} = new Handler({
  endpoint: "/projects/:id/name-additional",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, {
    handleClose, mutate, showSuccess
  }) => {
    await mutate();
    showSuccess();
    handleClose();
  },
  transformUpdateData: (data) => ({
    project: {
      ...initialValues,
      ...data
    }
  })
});

export {
  handleUpdate
};
