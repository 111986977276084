import Head from "@uiw/react-head";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import Layout from "~/src/ui/layout/index.jsx";

/**
 *
 * @param root0 - The root object
 * @param root0.title - The root object
 * @param root0.className - The root object
 * @param root0.children - The root object
 * @example
 */
const Page = ({
  children, className, title = ""
}) => {
  const { pathname } = useLocation();

  return (
    <>

      <Head>
        <Head.Title>
          {title} | IMMOdeveloper Editor
        </Head.Title>
      </Head>

      <Layout
        className={
          cn(
            {
              "bg-stone-50": pathname.startsWith("/automated-projects"),
              "bg-white": !pathname.startsWith("/automated-projects")
            },
            className
          )
        }
      >
        {children}
      </Layout>
    </>
  );
};

export default Page;
