import Joi from "joi";

const initialValues = {
  name: "",
  sorting: ""
};

/**
 *
 * @param part
 * @example
 */
const generateEditValues = (part) => {
  if (part) {
    return {
      name: part.name || "",
      sorting: part.sorting || ""
    };
  }

  return initialValues;
};

const schema = Joi.object({
  name: Joi.string().empty("").max(30)
    .required(),
  sorting: Joi.number().positive().precision(4)
    .empty("")
    .required()
});

export {
  generateEditValues,
  schema
};
