import { identityFn } from "~/src/_common/_exports.js";

const createRequestQuery = (
  { getRequestParamName: getRequestParameterName = identityFn, pageParams }
) => {
  const requestParameters = new URLSearchParams();

  for (const pageParameterName in pageParams) {
    const parameterValue = pageParams[pageParameterName] ?? "";
    const requestParameterName = getRequestParameterName(pageParameterName);

    requestParameters.append(requestParameterName, parameterValue);
  }

  return requestParameters.toString();
};

export { createRequestQuery };
