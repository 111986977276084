/**
 *
 * @param props0 - The root object
 * @param props0.className - The root object
 * @example
 */
const BulkEditIcon = ({ className }) => (
  <div className="inline-grid grid-cols-3">
    <div>
      <svg className={`${className} stroke-current`} fill="none" viewBox="0 0 24 24">
        <path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
      </svg>
    </div>

    <div>
      <svg className={`${className} stroke-current`} fill="none" viewBox="0 0 24 24">
        <path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
      </svg>
    </div>

    <div>
      <svg className={`${className} stroke-current`} fill="none" viewBox="0 0 24 24">
        <path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
      </svg>
    </div>
  </div>
);

export default BulkEditIcon;
