import { useParams } from "react-router-dom";

import ProjectCertificates from "~/src/features/project-infos/project-certificates/index.jsx";
import ProjectDescription from "~/src/features/project-infos/project-description/index.jsx";
import ProjectEinlagen from "~/src/features/project-infos/project-einlagen/index.jsx";
import ProjectEnergyClasses from "~/src/features/project-infos/project-energy-classes/index.jsx";
import ProjectImage from "~/src/features/project-infos/project-image/index.jsx";
import ProjectLocations from "~/src/features/project-infos/project-locations/index.jsx";
import ProjectName from "~/src/features/project-infos/project-name/index.jsx";
import ProjectNameAdditional from "~/src/features/project-infos/project-name-additional/index.jsx";
import ProjectWidmungen from "~/src/features/project-infos/project-widmungen/index.jsx";
import PublicProjectNotes from "~/src/features/project-infos/public-project-notes/index.jsx";

import useProject from "~/src/hooks/use-project.js";

import TabHeader from "~/src/ui/headers/tab-header/index.jsx";
import Skeleton from "~/src/ui/loading/item-skeleton/index.js";

/**
 *
 * @example
 */
const ProjectInfosPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  // TODO: Switch to slide for every form

  return (
    <>
      <TabHeader>Allgemeine Projektdaten</TabHeader>

      <div className="divide-y divide-gray-200">
        <ProjectName />

        <ProjectNameAdditional />

        <ProjectDescription />

        <ProjectImage />

        <PublicProjectNotes />

        <ProjectLocations />

        <ProjectEinlagen />

        <ProjectWidmungen />

        <ProjectCertificates />

        <ProjectEnergyClasses />
      </div>
    </>
  );
};

export default ProjectInfosPage;
