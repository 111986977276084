import Handler from "~/src/modules/handler.js";

import setImageAccess from "./set-image-access.js";

const {
  handleCreate,
  handleUpdate
} = new Handler({
  endpoint: "/projects/:id/project-image",

  afterCreateSuccess: async (responseData, { data }) => {
    await setImageAccess({
      name: data.fileName,
      showPublic: data.showPublic
    });
  },
  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data) => ({
    project: {
      image_file_name: data.fileName,
      image_landscape: data.imageLandscape,
      show_image_public: data.showPublic
    }
  }),
  transformUpdateData: (data) => ({
    project: {
      image_file_name: data.fileName,
      image_landscape: data.imageLandscape,
      show_image_public: data.showPublic
    }
  })
});

export {
  handleCreate,
  handleUpdate
};
