import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param options0 - The root object
 * @param options0.buildingPart - The root object
 * @param options0.category - The root object
 * @param options0.count - The root object
 * @param options0.createdRange - The root object
 * @param options0.projectId - The root object
 * @param options0.updatedRange - The root object
 * @example
 */
const useUnitStatistics = ({
  buildingPart = "",
  category = "",
  count = "",
  createdRange,
  projectId,
  updatedRange
}) => {
  const parametersObject = {
    buildingPart,
    category,
    count,
    createdAfter: createdRange?.[0],
    createdBefore: createdRange?.[1],
    updatedAfter: updatedRange?.[0],
    updatedBefore: updatedRange?.[1]
  };

  for (const [parameterKey, parameter] of Object.entries(parametersObject)) {
    if (parameter === undefined || parameter === null || parameter === "") {
      delete parametersObject[parameterKey];
    }
    else if (parameter instanceof Date) {
      parametersObject[parameterKey] = parameter.toISOString();
    }
  }

  const parameters = new URLSearchParams(parametersObject);

  const {
    data, error, mutate
  } = useSWR(`/projects/${projectId}/unit-statistics?${parameters}`, fetcher);

  if (category === "") {
    return {
      categories: data?.payload.categories,
      isError: error,
      isLoading: true,
      mutate
    };
  }

  return {
    categories: data?.payload.categories,
    isError: error,
    isLoading: !error && !data,
    mutate,
    statistics: data?.payload.statistics
  };
};

export default useUnitStatistics;
