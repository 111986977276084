import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @example
 */
const useAuth = () => {
  const {
    data, error, mutate
  } = useSWR("/auth/session", fetcher, {
    refreshInterval: 60_000 * 1
  });

  const loggedOut = error && error.status === 403;

  const isEditor = data?.payload?.role === "editor";
  const isModule = data?.payload && ["admin", "moderator"].includes(data.payload?.role);
  const isAdmin = data?.payload && data.payload?.role === "admin";

  return {
    isAdmin,
    isEditor,
    isError: error,
    isLoading: !error && !data,
    isMod: isModule,
    loggedOut,
    mutate,
    user: data?.payload
  };
};

export default useAuth;
