import Handler from "~/src/modules/handler.js";

const {
  handleUpdate
} = new Handler({
  endpoint: "/projects/:id/transaction-settings",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    console.error(error); // Logging the error to the console
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (data) => ({
    project: {
      gka_area: data.gka_area || null,
      gka_notes: data.gka_notes || null,
      show_gka: data.show_gka || false
    }
  })
});

export {
  handleUpdate
};
