import Handler from "~/src/modules/handler.js";

const {
  handleCreate
} = new Handler({
  endpoint: "/building-parts",

  afterEvery: ({ setIsSubmitting }) => {
    setIsSubmitting(false);
  },
  afterEveryError: (error, { showError }) => {
    showError();
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data, { projectId }) => ({
    part: {
      name: data.name,
      project_id: projectId,
      sorting: data.sorting
    }
  })
});

export {
  handleCreate
};
