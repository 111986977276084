import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import API from "~/src/modules/api.js";
import validationResolver from "~/src/modules/validation-resolver.js";

import useNotification from "~/src/hooks/use-notification.js";
import useProject from "~/src/hooks/use-project.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import CheckboxField from "~/src/ui/forms/checkbox-field/index.jsx";
import CollectionField from "~/src/ui/forms/collection-field/index.jsx";
import ComboField from "~/src/ui/forms/combo-field/index.jsx";
import Field from "~/src/ui/forms/field/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleCreate, handleUpdate } from "./handlers.js";
import schema, { generateEditValues, initialValues } from "./schema.js";

const EinlageSlide = ({
  einlage, hide, projectId, visible
}) => {
  const { mutate } = useProject(projectId);

  const [formIsLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { showError, showSuccess } = useNotification();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    watch
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });
  const baurecht = watch("baurecht");
  const kg = watch("kg");
  const br_kg = watch("baurechtKg");

  useEffect(() => {
    if (einlage) {
      setIsEdit(true);
      reset(generateEditValues(einlage));
    }
  }, [reset, einlage]);

  useEffect(() => {
    if (baurecht) {
      const isBaurechtKgEmpty = Object.values(br_kg).includes("");

      setValue("baurechtKg", isBaurechtKgEmpty ? kg : br_kg);
    }
    else {
      setValue("baurechtKg", initialValues.baurechtKg);
      setValue("brezNumber", initialValues.brezNumber);
    }
  }, [baurecht]);

  const handleClose = () => {
    reset(initialValues);
    hide();
  };

  const onSubmit = (data) => {
    const commonProperties = {
      data,
      hide: handleClose,
      mutate,
      setIsLoading,
      showError,
      showSuccess
    };

    if (einlage) {
      handleUpdate({
        ...commonProperties,
        id: einlage.id
      });
    }
    else {
      handleCreate({
        ...commonProperties,
        projectId
      });
    }
  };

  const loadOptions = async (inputValue) => API.post("/katastralgemeinden/search", {
    q: inputValue
  })
    .then((res) => res.data.payload.katastralgemeinden.map((kg) => ({
      id: kg.id,
      label: `${kg.code} ${kg.name} (BG ${kg.bezirksgericht.code} ${kg.bezirksgericht.name})`
    })))
    .catch((error) => {
      console.log(error);
    });

  const loadOptionsBaurecht = async (inputValue) => API.post("/katastralgemeinden/search", {
    q: inputValue
  })
    .then((res) => res.data.payload.katastralgemeinden.map((br_kg) => ({
      id: br_kg.id,
      label: `${br_kg.code} ${br_kg.name} (BG ${br_kg.bezirksgericht.code} ${br_kg.bezirksgericht.name})`
    })))
    .catch((error) => {
      console.log(error);
    });

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={formIsLoading} handleHide={handleClose} title={`Liegenschaft ${einlage ? "bearbeiten" : "erstellen"}`} />

          <ComboField
            async
            label="KG *"
            name="kg"
            options={loadOptions}
            messages={{
              queryEmpty: "KG eingeben..."
            }}
            {...{
              control,
              isEdit,
              resetField,
              setValue,
              watch
            }}
          />

          <Field
            label="EZ *"
            name="ezNumber"
            {...{
              errors,
              register
            }}
          />

          <NumberField
            area
            label="Fläche *"
            name="area"
            {...{
              control,
              errors,
              register
            }}
          />

          <CollectionField
            label="GST-Nr. *"
            name="gstNumbers"
            {...{
              control,
              errors,
              formIsLoading,
              handleSubmit,
              register
            }}
          />

          <CheckboxField
            label="Baurecht"
            name="baurecht"
            {...{
              errors,
              register
            }}
          />

          {baurecht
            ? (
              <div className="m-4 rounded bg-white/50">
                <ComboField
                  async
                  label="KG (Baurecht) *"
                  name="baurechtKg"
                  options={loadOptionsBaurecht}
                  messages={{
                    queryEmpty: "KG eingeben..."
                  }}
                  {...{
                    control,
                    isEdit,
                    resetField,
                    setValue,
                    watch
                  }}
                />

                <Field
                  label="BREZ *"
                  name="brezNumber"
                  {...{
                    errors,
                    register
                  }}
                />
              </div>
            )
            : null}
        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton disabled={formIsLoading} onClick={handleClose} />

            <FormSubmitButton isSubmitting={formIsLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default EinlageSlide;
