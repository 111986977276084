const isEmpty = (value, innerValue) => {
  let actualValue = value;

  if (typeof value === "function") {
    actualValue = value(innerValue);
  }

  return actualValue === null ||
    actualValue === undefined ||
    (typeof actualValue === "string" && actualValue === "") ||
    (Array.isArray(actualValue) && actualValue.length === 0);
};

export default isEmpty;
