import { Link, useRouteMatch } from "react-router-dom";

/**
 *
 * @param props0 - The root object
 * @param props0.activeOnlyWhenExact - The root object
 * @param props0.label - The root object
 * @param props0.to - The root object
 * @example
 */
const TabLink = ({
  activeOnlyWhenExact = true, label, to
}) => {
  const match = useRouteMatch({
    exact: activeOnlyWhenExact,
    path: to
  });

  if (match) {
    return (
      <Link className="whitespace-nowrap border-b-2 border-gray-600 px-1 py-4 text-sm font-medium text-gray-600" to={to}>
        {label}
      </Link>
    );
  }

  return (
    <Link className="whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700" to={to}>
      {label}
    </Link>
  );
};

export default TabLink;
