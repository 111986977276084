import Joi from "joi";
import { sum } from "lodash-es";

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => ({
  gemeinde: project?.funding?.gemeinde || false,
  gemeinde_total: project?.funding?.gemeinde_total || "",
  normal_funded: project?.funding?.normal_funded || false,
  normal_funded_total: project?.funding?.normal_funded_total || "",
  not_funded: project?.funding?.not_funded || false,
  not_funded_total: project?.funding?.not_funded_total || "",
  smart_funded: project?.funding?.smart_funded || false,
  smart_funded_total: project?.funding?.smart_funded_total || "",
  smart_super_funded: project?.funding?.smart_super_funded || false,
  smart_super_funded_total: project?.funding?.smart_super_funded_total || "",
  super_funded: project?.funding?.super_funded || false,
  super_funded_total: project?.funding?.super_funded_total || "",
  wiener_wohnbau: project?.funding?.wiener_wohnbau || false,
  wiener_wohnbau_total: project?.funding?.wiener_wohnbau_total || ""
});

export { generateEditValues };

export const schema = Joi.object({
  gemeinde: Joi.boolean().required(),
  gemeinde_total: Joi.number().positive().empty(""),
  normal_funded: Joi.boolean().required(),
  normal_funded_total: Joi.number().positive().empty("")
    .custom((
      value,
      {
        error,
        state: {
          ancestors: [values]
        }
      }
    ) => {
      const sumOfSubCategories = sum(
        [
          "super_funded",
          "smart_funded",
          "smart_super_funded",
          "wiener_wohnbau"
        ]
          .map((key) => Number(values[`${key}_total`]) || 0)
      );

      if (!Number.isNaN(Number(value)) && sumOfSubCategories > value) {
        return error("number.min");
      }

      return value;
    }),
  not_funded: Joi.boolean().required(),
  not_funded_total: Joi.number().positive().empty(""),
  smart_funded: Joi.boolean().required(),
  smart_funded_total: Joi.number().positive().empty(""),
  smart_super_funded: Joi.boolean().required(),
  smart_super_funded_total: Joi.number().positive().empty(""),
  super_funded: Joi.boolean().required(),
  super_funded_total: Joi.number().positive().empty(""),
  wiener_wohnbau: Joi.boolean().required(),
  wiener_wohnbau_total: Joi.number().positive().empty("")
});
