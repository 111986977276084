import { snakeCase } from "lodash-es";

import { formatYear, validDate } from "~/src/modules/formatters.js";
import Handler from "~/src/modules/handler.js";
import precision from "~/src/modules/precision.js";

/**
 *
 * @param object
 * @example
 */
const removeEmptyValues = (object) => Object.fromEntries(Object.entries(object).filter(([key, value]) => value !== "" && value !== null));

/**
 *
 * @param values
 * @param options0 - The root object
 * @param options0.includeSaleData - The root object
 * @example
 */
const generateUnitObject = (values, { includeSaleData = true } = {}) => {
  const unit = {
    building_part_id: values.buildingPart?.id || null,
    buyable: values.buyable || false,
    floor: values.floor || null,
    maisonette: values.maisonette || false,
    name: values.name || null,
    offer_data_available: values.offerDataAvailable || false,
    position: values.position || null,
    position_group: values.positionGroup || null,
    position_staircase: values.positionStaircase || null,
    rentable: values.rentable || false,
    sale_data_available: values.saleDataAvailable || false,
    show: values.show || false,
    staircase: values.staircase || null,
    unit_category: {
      id: values.unit_category.id
    },
    verwertet: values.verwertet || false,
    verwertet_date: values.verwertet
      ? validDate(values.verwertetDate) || null
      : null,
    verwertet_date_format: values.verwertetDateFormat?.value
  };

  const simpleOfferKeys = [
    "offerPriceNormal",
    "offerPriceInvestor",
    "offerPriceBruttoInvestor",
    "rentNetto",
    "rentBrutto",
    "rentBk",
    "offerArea",
    "offerRoomCount",
    "offerLoggiaCount",
    "offerLoggiaArea",
    "offerBalkonCount",
    "offerBalkonArea",
    "offerGartenCount",
    "offerGartenArea",
    "offerTerrasseCount",
    "offerTerrasseArea",
    "offerKellerCount",
    "offerKellerArea",
    "offerNotice",
    "internalOfferNotes"
  ];

  if (values.offerDataAvailable) {
    for (const key of simpleOfferKeys) {
      unit[snakeCase(key)] = values[key] || null;
    }

    unit.show_offer_price_normal_history = values.showOfferPriceNormalHistory || false;
    unit.offer_price_normal_history = unit.show_offer_price_normal_history
      ? values.offerPriceNormalHistory
      : [];
    unit.show_offer_price_investor_history = values.showOfferPriceInvestorHistory || false;
    unit.offer_price_investor_history = unit.show_offer_price_investor_history
      ? values.offerPriceInvestorHistory
      : [];
    unit.show_offer_price_brutto_investor_history = values.showOfferPriceBruttoInvestorHistory || false;
    unit.offer_price_brutto_investor_history = unit.show_offer_price_brutto_investor_history
      ? values.offerPriceBruttoInvestorHistory
      : [];

    unit.show_rent_netto_history = values.showRentNettoHistory || false;
    unit.rent_netto_history = unit.show_rent_netto_history
      ? values.rentNettoHistory
      : [];
    unit.show_rent_brutto_history = values.showRentBruttoHistory;
    unit.rent_brutto_history = unit.show_rent_brutto_history
      ? values.rentBruttoHistory
      : [];
    unit.show_rent_bk_history = values.showRentBkHistory || false;
    unit.rent_bk_history = unit.show_rent_bk_history
      ? values.rentBkHistory
      : [];
  }
  else {
    for (const key of simpleOfferKeys) {
      unit[snakeCase(key)] = null;
    }

    unit.verwertet = false;
    unit.verwertet_date = null;
    unit.verwertet_date_format = null;
    unit.show_offer_price_normal_history = false;
    unit.offer_price_normal_history = [];
    unit.show_offer_price_investor_history = false;
    unit.offer_price_investor_history = [];
    unit.show_offer_price_brutto_investor_history = false;
    unit.offer_price_brutto_investor_history = [];
    unit.show_rent_netto_history = false;
    unit.rent_netto_history = [];
    unit.show_rent_brutto_history = false;
    unit.rent_brutto_history = [];
    unit.show_rent_bk_history = false;
    unit.rent_bk_history = [];
  }

  const simpleSaleKeys = [
    "kvId",
    "kvUrl",
    "salePriceGross",
    "saleArea",
    "saleRoomCount",
    "saleLoggiaCount",
    "saleLoggiaArea",
    "saleBalkonCount",
    "saleBalkonArea",
    "saleGartenCount",
    "saleGartenArea",
    "saleTerrasseCount",
    "saleTerrasseArea",
    "saleKellerCount",
    "saleKellerArea",
    "saleNotice",
    "internalSaleNotes"
  ];

  if (includeSaleData) {
    if (values.saleDataAvailable) {
      for (const key of simpleSaleKeys) {
        unit[snakeCase(key)] = values[key] || null;
      }

      unit.tz_number = values.tzNumber;
      unit.tz_year = formatYear(values.tzYear);
      unit.hide_tz = values.hideTz;
      unit.sale_date = validDate(values.saleDate);
      unit.sale_price_net = values.salePriceNet;
      unit.buyer = values.buyer;

      const sanitizedPrivateBuyers = values.privateBuyers.map(removeEmptyValues);

      if (sanitizedPrivateBuyers.every((privateBuyer) => Object.values(privateBuyer).every((value) => typeof value === "boolean"))) {
        unit.private_buyers = [];
      }
      else {
        unit.private_buyers = sanitizedPrivateBuyers
          ?.map(({
            birthdate,
            country,
            houseNumber: house_number,
            surname,
            titlePrefix: title_prefix,
            titleSuffix: title_suffix,
            ...privateBuyer
          }) => (surname
            ? ({
              birthdate: validDate(birthdate),
              country_id: country?.id,
              house_number,
              surname,
              title_prefix,
              title_suffix,
              ...privateBuyer
            })
            : {})) || [];
      }

      const sanitizedCompanyBuyers = values.companyBuyers.map(removeEmptyValues);

      if (sanitizedCompanyBuyers.every((companyBuyer) => Object.values(companyBuyer).every((value) => typeof value === "boolean"))) {
        unit.company_buyers = [];
      }
      else {
        unit.company_buyers = sanitizedCompanyBuyers
          ?.map(({
            country,
            houseNumber: house_number,
            isZvr: is_zvr,
            name,
            noRegNumber: no_reg_number,
            regNumber: reg_number,
            ...companyBuyer
          }) => (name
            ? ({
              country_id: country?.id,
              house_number,
              is_zvr,
              name,
              no_reg_number,
              reg_number,
              ...companyBuyer
            })
            : {})) || [];
      }
    }
    else {
      for (const key of simpleSaleKeys) {
        unit[snakeCase(key)] = null;
      }

      unit.tz_number = null;
      unit.tz_year = null;
      unit.hide_tz = false;
      unit.sale_price_net = null;
    }
  }

  return unit;
};

/**
 *
 * @param values
 * @example
 */
const generateBulkUpdateForm = (values) => {
  const updateForm = generateUnitObject(values, { includeSaleData: false });

  const deletableKeys = [
    "building_part_id",
    "floor",
    "maisonette",
    "name",
    "position",
    "position_group",
    "position_staircase",
    "sale_data_available",
    "staircase",
    "unit_category"
  ];

  for (const key of deletableKeys) {
    delete updateForm[key];
  }

  return updateForm;
};

/**
 *
 * @param unitData
 * @param fromString
 * @param toString
 * @example
 */
const createFromUnitsBulk = (unitData, fromString, toString) => {
  const units = [];

  const from = Number(fromString);
  const to = Number(toString);

  const maxPrecision = Math.max(precision(from), precision(to));

  const {
    positionFrom, positionTo, ...unit
  } = unitData;

  const multiplier = 10 ** maxPrecision;

  for (let numerator = from * multiplier; numerator <= to * multiplier; numerator++) {
    const position = (numerator / multiplier).toFixed(maxPrecision);

    units.push({
      ...unit,
      name: `${unitData.name}${position}`,
      position
    });
  }

  return units;
};

/**
 *
 * @param bulkUnits
 * @param from
 * @param to
 * @example
 */
const dummyUnitObject = (bulkUnits, from, to) => {
  if (from && to) {
    return createFromUnitsBulk(bulkUnits, from, to)
      .map((unit) => ({
        ...unit
      }));
  }
};

/**
 *
 * @param values
 * @example
 */
const generateUnitObjectBulk = (values) => {
  const unit = generateUnitObject(values);

  delete unit.position;

  unit.positionFrom = values.positionFrom || null;
  unit.positionTo = values.positionTo || null;

  return unit;
};

/**
 *
 * @param options0 - The root object
 * @param options0.formValues - The root object
 * @param options0.handleClose - The root object
 * @param options0.hide - The root object
 * @param options0.mutate - The root object
 * @param options0.projectId - The root object
 * @param options0.setIsLoading - The root object
 * @param options0.setIsSubmitting - The root object
 * @param options0.showError - The root object
 * @example
 */
const handleCreateBulk = async ({
  formValues, handleClose, hide, mutate, projectId, setIsLoading, setIsSubmitting, showError
}) => {
  const unit = generateUnitObjectBulk(formValues);

  unit.project = {
    id: projectId
  };

  //check if Angebotsdaten vorhanden is check, only then proceed further
  if (!unit.offer_data_available) {
    showError();

    return;
  }

  setIsLoading(true);

  const baseUrl = globalThis?.settings?.REACT_APP_API_ENDPOINT;

  const url = `${baseUrl}/units-bulk`;

  const response = await fetch(
    url,
    {
      body: JSON.stringify({ unit }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"
    }
  );

  if (response.ok) {
    await mutate();
    handleClose();
  }
  else {
    console.log(response.statusText);
    showError(response.statusText);
  }

  setIsLoading(false);
  setIsSubmitting(false);
  hide();
};

/**
 *
 * @param options0 - The root object
 * @param options0.changedKeys - The root object
 * @param options0.formValues - The root object
 * @param options0.handleClose - The root object
 * @param options0.hide - The root object
 * @param options0.mutate - The root object
 * @param options0.projectIds - The root object
 * @param options0.resetSelection - The root object
 * @param options0.setIsLoading - The root object
 * @param options0.setIsSubmitting - The root object
 * @param options0.setPage - The root object
 * @param options0.showError - The root object
 * @example
 */
const handleBulkUpdate = async ({
  changedKeys, formValues, handleClose, hide, mutate, projectIds, resetSelection, setIsLoading, setIsSubmitting, setPage, showError
}) => {
  setIsLoading(true);

  const unitForm = generateBulkUpdateForm(formValues);
  const snakeChangedKeys = changedKeys.map((value) => snakeCase(value));

  const baseUrl = globalThis?.settings?.REACT_APP_API_ENDPOINT;
  const url = `${baseUrl}/units-bulk`;

  const response = await fetch(
    url,
    {
      body: JSON.stringify({
        changedKeys: snakeChangedKeys,
        projectIds,
        unitForm
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      method: "PATCH"
    }
  );

  if (response.ok) {
    await mutate();
    handleClose();
  }
  else {
    console.log(response.statusText);
    showError(response.statusText);
  }

  resetSelection();
  hide();
  setIsSubmitting(false);
  setIsLoading(false);
  setPage(1);
};

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/units/:id",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
    //setIsSubmitting(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (
    responseData,
    {
      handleClose, mutate, showSuccess
    }
  ) => {
    await mutate();
    showSuccess();
    handleClose();
  },
  transformCreateData: (data, { projectId }) => ({
    unit: {
      ...generateUnitObject(data),
      project: { id: projectId }
    }
  }),
  transformUpdateData: (data) => ({
    unit: generateUnitObject(data)
  })
});

export {
  dummyUnitObject,
  handleBulkUpdate,
  handleCreate,
  handleCreateBulk,
  handleDelete,
  handleUpdate
};
