import Joi from "joi";

export const roles = {
  builder: "Bauträger",
  marketer: "Vermarkter",
  owner: "Eigentümer"
};

export const initialValues = {
  company: {
    id: "",
    name: "",
    reg_number: ""
  },
  private: false
};

/**
 *
 * @param role
 * @example
 */
const generateEditValues = (role) => {
  if (role) {
    const { company } = role;

    return {
      company: {
        id: company?.id || "",
        name: company?.name || "",
        reg_number: company?.reg_number || ""
      },
      private: role.private || false
    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  company: Joi.object({
    id: Joi.number().positive().empty("")
      .when(Joi.ref("...private"), {
        is: false,
        then: Joi.required()
      }),
    name: Joi.string().empty("").when(Joi.ref("...private"), {
      is: false,
      then: Joi.required()
    }),
    reg_number: Joi.string().empty("").optional()
      .allow(null)
  }),
  private: Joi.boolean().required()
});
