import SignedLink from "~/src/ui/links/signed-link/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.document - The root object
 * @example
 */
const UnitDocumentItemSmall = ({ document }) => (
  <li className="flex" key={`document_unit_${document.id}`}>
    <div className="max-w-24 flex-1 truncate text-xs">
      <SignedLink document={document} styles="text-gray-900 hover:text-gray-600 underline">
        {document.public_name ? document.public_name : "Kein Name"}
      </SignedLink>
    </div>
  </li >
);

export default UnitDocumentItemSmall;
