const hasProperties = (jsx) => Object.hasOwn(jsx, "props");

const reduceJsxToString = (previous, current) => previous + innerText(current);

const innerText = (jsx) => {
  // Empty
  if (
    jsx === null ||
    typeof jsx === "boolean" ||
    jsx === undefined
  ) {
    return "";
  }

  // Numeric children
  if (typeof jsx === "number") {
    return jsx.toString();
  }

  // String literals
  if (typeof jsx === "string") {
    return jsx;
  }

  // Array of JSX
  if (Array.isArray(jsx)) {
    return jsx.reduce(reduceJsxToString, "");
  }

  // Children prop
  if (hasProperties(jsx) &&
    Object.hasOwn(jsx.props, "children")) {
    return innerText(jsx.props.children);
  }

  // Default
  return "";
};

export default innerText;
