import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.jsx";
import InlineInput from "~/src/ui/forms/inline-input/index.jsx";
import SlideFormHeader from "~/src/ui/headers/slide-form-header/index.jsx";
import SlideOver from "~/src/ui/slides/container/index.jsx";

import { handleUpdate } from "./document-handlers.js";
import { generateEditValues, singleSchema } from "./document-schema.js";

const EditUnitDocumentSlide = ({
  document, hide, mutateUnits, visible
}) => {
  const { showError } = useNotification();
  const initialValues = generateEditValues(document);

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleUpdate({
        id: document.id,
        data: form.values,
        form,
        hide: () => {
          handleHide(form);
        },
        mutate: mutateUnits,
        showError
      });
    },
    schema: singleSchema
  });

  return (
    <SlideOver
      visible={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">

          <SlideFormHeader
            closeDisabled={form.isLoading}
            title="Dokument bearbeiten"
            handleHide={() => {
              handleHide(form);
            }}
          />

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="publicName">
                  Öffentlicher Dateiname &#42;
                </label>
              </div>

              <div className="sm:col-span-2">
                <InlineInput
                  error={form.errors.publicName}
                  id="publicName"
                  isSubmitting={form.isSubmitting}
                  name="publicName"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Dateiname"
                  touched={form.touched.publicName}
                  value={form.values.publicName}
                />
              </div>
            </div>
          </div>

          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="showPublic">
                Sichtbarkeit
              </label>
            </div>

            <div className="sm:col-span-2">
              <InlineCheckbox
                error={form.errors.showPublic}
                id="showPublic"
                isSubmitting={form.isSubmitting}
                label="öffentlich anzeigen"
                name="showPublic"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.showPublic}
                value={form.values.showPublic}
              />
            </div>
          </div>

        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton
              disabled={form.isLoading}
              onClick={() => {
                handleHide(form);
              }}
            />

            <FormSubmitButton isSubmitting={form.isLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>

      </form>
    </SlideOver>
  );
};

export default EditUnitDocumentSlide;
