import cn from "classnames";

import innerText from "~/src/modules/inner-text.js";

import NumberInput from "~/src/ui/forms/number-input/index.new.jsx";

/**
 *
 * @param root0 - The root object
 * @param root0.allowNegative - The root object
 * @param root0.area - The root object
 * @param root0.autoFocus - The root object
 * @param root0.className - The root object
 * @param root0.compact - The root object
 * @param root0.control - The root object
 * @param root0.disabled - The root object
 * @param root0.errorClassName - The root object
 * @param root0.errors - The root object
 * @param root0.hasLabel - The root object
 * @param root0.inline - The root object
 * @param root0.innerClassName - The root object
 * @param root0.int - The root object
 * @param root0.isAllowed - The root object
 * @param root0.isDetailsView - The root object
 * @param root0.label - The root object
 * @param root0.meter - The root object
 * @param root0.name - The root object
 * @param root0.noprefix - The root object
 * @param root0.onChange - The root object
 * @param root0.outerPrefix - The root object
 * @param root0.outerSuffix - The root object
 * @param root0.prefix - The root object
 * @param root0.register - The root object
 * @param root0.scale - The root object
 * @param root0.spanLabel - The root object
 * @param root0.suffix - The root object
 * @param root0.thousandSeparator - The root object
 * @param root0.type - The root object
 * @param root0.watch - The root object
 * @param root0.id - The root object
 * @param root0.placeholder - The root object
 * @param root0.fixed - The root object
 * @example
 */
const NumberField = ({
  allowNegative,
  area = false,
  autoFocus = false,
  className,
  compact,
  control,
  disabled = false,
  errorClassName,
  errors,
  fixed = false,
  hasLabel = true,
  inline,
  innerClassName,
  int = false,
  isAllowed,
  isDetailsView = false,
  label,
  meter = false,
  name,
  noprefix = false,
  onChange = () => { },
  outerPrefix,
  outerSuffix,
  prefix,
  register,
  scale,
  spanLabel = false,
  suffix,
  type = "search",
  watch,

  id = `${name}${Date.now()}`,
  placeholder = innerText(label)
}) => (
  <div className={cn("py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200", className)}>
    <div
      className={cn("space-y-1 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-x-4", {
        "p-0": inline && !compact,
        "px-6 py-0": compact,
        "px-6 py-5": !inline && !compact
      }, innerClassName)}
    >
      {hasLabel
        ? (
          <div className={cn("", spanLabel ? "col-span-3" : "")}>
            <label
              htmlFor={id}
              className={cn(
                "inline-block text-sm font-medium sm:mt-px sm:pt-2",
                {
                  "col-span-1": !inline,
                  "col-span-3": inline,
                  "text-gray-700 text-base font-normal": isDetailsView,
                  "text-gray-900": !isDetailsView
                }
              )}
            >
              {label}
            </label>
          </div>
        )
        : null}

      {outerPrefix}

      <div
        className={cn("grid grid-cols-3", {
          [inline ? "col-span-2" : "col-span-1"]: prefix,
          [inline ? "col-span-3" : "col-span-2"]: !prefix
        })}
      >
        {prefix}

        <NumberInput
          {...{
            id,
            allowNegative,
            area,
            autoFocus,
            control,
            disabled,
            errorClassName,
            errors,
            fixed,
            inline,
            int,
            isAllowed,
            isDetailsView,
            meter,
            name,
            noprefix,
            onChange,
            placeholder,
            register,
            scale,
            type
          }}
          className={cn("w-full", {
            "col-span-1": prefix && suffix,
            "col-span-2": prefix || suffix,
            "col-span-3": !prefix && !suffix
          })}
        />

        {suffix}
      </div>

      {outerSuffix}
    </div>
  </div>
);

export default NumberField;
