const getRequestParameterName = (pageParameterName) => {
  switch (pageParameterName) {
    case "sortFilter":
    case "statusFilter":
    case "synchronizedFilter":
      return pageParameterName.replace("Filter", "");
  }

  return pageParameterName;
};

export {
  getRequestParameterName as getRequestParamName
};
