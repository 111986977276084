import Joi from "joi";

export const initialValues = {
  enabled: ""
};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project) {
    return {
      ...initialValues,
      ...project
    };
  }

  return initialValues;
};

export { generateEditValues };

const schema = Joi.object({
  enabled: Joi.boolean().required()
});

export default schema;
