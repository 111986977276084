const defaultClassName = "inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700";
const disabledClassName = "inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400";

const SubmitButton = ({
  children,
  disabled = false,
  isSubmitting = false,
  onClick,
  submitText = "Wird gespeichert",

  className = disabled ? disabledClassName : defaultClassName
}) => {
  if (isSubmitting) {
    return (
      <button disabled className={className} type="submit">
        <svg className="-ml-1 mr-3 size-5 animate-spin text-white" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />

          <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor" />
        </svg>

        {submitText}
      </button>
    );
  }

  return (
    <button className={className} disabled={disabled} onClick={onClick} type="button">
      {children}
    </button>
  );
};

export default SubmitButton;
