import cn from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { GlobalHotKeys } from "react-hotkeys";
import {
  Link, useHistory, useLocation
} from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";

import SettingsDropdown from "~/src/ui/dropdowns/settings-dropdown/index.jsx";
import NumberInput from "~/src/ui/forms/number-input/index.new.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";

/**
 *
 * @example
 */
const Nav = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user } = useAuth();
  const { pathname } = useLocation();

  // TODO [2024-05-01]: replace this with better role
  const isChristoph = user?.email === "mayer@rsgroup.at";
  const isAnton = user?.email === "stiglitz@rsgroup.at";
  const isThomas = user?.email === "geyer@rsgroup.at";

  const {
    control, formState: { errors }, handleSubmit, register, setFocus, setValue
  } = useForm();

  const history = useHistory();

  /**
   *
   * @param props0 - The root object
   * @param props0.id - The root object
   * @example
   */
  function onSubmit({ id }) {
    history.push(`/projects/${id.trim()}`);
    setValue("id", "");
  }

  /**
   *
   * @param event
   * @example
   */
  function onFocus(event) {
    event.target.select();
  }

  return (
    <nav
      className={cn(
        "h-12 sticky top-0 w-full z-30",
        {
          "bg-gray-700": !pathname.startsWith("/automated-projects"),
          "bg-stone-700": pathname.startsWith("/automated-projects")
        }
      )}
    >
      {user
        ? (
          <PageHeader>
            <div className="flex h-12 justify-between">
              <div className="flex items-center gap-4">
                <div className="relative flex shrink-0 items-center">
                  <Link className="font-bold text-white" to="/">
                    IMMOdeveloper Editor
                  </Link>

                  <div className="absolute -bottom-4 -right-3 flex items-center">
                    <span
                      className={cn("px-1 py-px leading-none text-[0.6rem] rounded-md uppercase", {
                        "bg-blue-300 text-blue-800": globalThis?.settings?.REACT_APP_HOST_ENV === "dev",
                        "bg-green-300 text-green-800 ": globalThis?.settings?.REACT_APP_HOST_ENV === "preview",
                        "bg-orange-300 text-orange-800 ": globalThis?.settings?.REACT_APP_HOST_ENV === "test",
                        "bg-red-300 text-red-800": globalThis?.settings?.REACT_APP_HOST_ENV === "production",
                        "bg-red-300 text-red-800 ": globalThis?.settings?.REACT_APP_HOST_ENV === "staging"
                      })}
                    >
                      {globalThis?.settings?.REACT_APP_HOST_ENV}
                    </span>
                  </div>
                </div>

                <GlobalHotKeys
                  keyMap={{ focus: "f" }}
                  handlers={{
                    focus: (event) => {
                      if (!event.metaKey && !event.ctrlKey) {
                        event.preventDefault();
                        setFocus("id");
                      }
                    }
                  }}
                />

                <form autoComplete="off" className="w-24" onSubmit={handleSubmit(onSubmit)}>
                  <input type="hidden" value="hidden" />

                  <NumberInput
                    int
                    allowNegative={false}
                    name="id"
                    placeholder="Projekt-ID"
                    thousandSeparator=""
                    {...{
                      control,
                      errors,
                      register
                    }}
                    small
                    onFocus={onFocus}
                  />
                </form>
              </div>

              <div className="flex sm:ml-6 sm:items-center">
                <div className="relative hidden space-x-4 xl:flex">
                  <Link className={cn("text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium", { "bg-black/25": pathname.startsWith("/automated-projects") })} to="/automated-projects">
                    Automatisierte Projekte
                  </Link>

                  <Link className={cn("text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium", { "bg-black/25": pathname.startsWith("/projects") })} to="/projects">
                    Projekte
                  </Link>

                  <Link className={cn("text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium", { "bg-black/25": pathname.startsWith("/feed") })} to="/feed">
                    KV-Feed
                  </Link>

                  <Link className={cn("text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium", { "bg-black/25": pathname.startsWith("/companies") })} to="/companies">
                    Unternehmen
                  </Link>

                  <Link className={cn("text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium", { "bg-black/25": pathname.startsWith("/certificates") })} to="/certificates">
                    ESG
                  </Link>

                  <Link className={cn("text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium", { "bg-black/25": pathname.startsWith("/activities") })} to="/activities">
                    Aktivitäten
                  </Link>

                  <Link className={cn("text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium", { "bg-black/25": pathname.startsWith("/contracts") })} to="/contracts">
                    KV-Suche
                  </Link>

                  {
                    (isChristoph || isAnton || isThomas)
                      ? (
                        <Link
                          to="/unit-buyer-differences"
                          className={cn("text-gray-300 hover:text-white px-3 py-2 rounded-b-md text-xs font-medium absolute top-[2.625rem] right-0", {
                            "bg-gray-700": !pathname.startsWith("/unit-buyer-differences"),
                            "bg-gray-800": pathname.startsWith("/unit-buyer-differences")
                          })}
                        >
                          Käufer-Differenzen
                        </Link>
                      )
                      : null
                  }

                </div>

                <SettingsDropdown />
              </div>

              <div className="-mr-2 hidden items-center lg:hidden">
                <button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                  <span className="sr-only">Open main menu</span>

                  <svg aria-hidden="true" className={`${mobileMenuOpen ? "hidden" : "block"} size-6`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path d="M4 6h16M4 12h16M4 18h16" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
                  </svg>

                  <svg aria-hidden="true" className={`${mobileMenuOpen ? "block" : "hidden"} size-6`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
                  </svg>
                </button>
              </div>
            </div>
          </PageHeader>
        )
        : null}

    </nav>
  );
};

export default Nav;
