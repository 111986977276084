import Joi from "joi";

export const initialValues = {
  additional: "",
  bauklasse: "",
  bauweise: "",
  height_limit: "",
  height_limit_range: false,
  height_limit_sign: "",
  height_limit_to: "",
  plan_number: "",
  publish_date: "",
  widmung: ""
};

/**
 *
 * @param widmung
 * @example
 */
const generateEditValues = (widmung) => {
  if (widmung) {
    return {
      additional: widmung.additional || "",
      bauklasse: widmung.bauklasse || "",
      bauweise: widmung.bauweise || "",
      height_limit: widmung.height_limit || "",
      height_limit_range: Boolean(widmung.height_limit_to),
      height_limit_sign: widmung.height_limit_sign ? "plus" : "",
      height_limit_to: widmung.height_limit_to || "",
      plan_number: widmung.plan_number || "",
      publish_date: widmung.publish_date || "",
      widmung: widmung.widmung || ""
    };
  }

  return initialValues;
};

export { generateEditValues };

const schema = Joi.object({
  additional: Joi.string().empty(""),
  bauklasse: Joi.string().empty(""),
  bauweise: Joi.string().empty(""),
  height_limit: Joi
    .when("height_limit_range", {
      is: true,
      otherwise: Joi.number().positive().empty(""),
      then: Joi.number().positive().empty("")
        .required()
    }),
  height_limit_range: Joi.boolean().required(),
  height_limit_sign: Joi.string().empty(""),
  height_limit_to: Joi
    .when("height_limit_range", {
      is: true,
      otherwise: Joi.number().positive().empty(""),
      then: Joi.number().positive().empty("")
        .required()
    }),
  plan_number: Joi.string().empty("").required()
    .pattern(/^[\dA-Za-z]+$/u),
  publish_date: Joi.date().empty("").required(),
  widmung: Joi.string().empty("")
});

export default schema;
