import {
  areaActivity,
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers.js";

export default {
  ...Object.fromEntries(
    [
      "id",
      "project_id",
      "unit_category_id",
      "unit_category_code"
    ].map(
      (key) => [key, hiddenActivity]
    )
  ),

  ...Object.fromEntries(
    [["unit_category", "Objektkategorie"], ["total_units", "Einheiten"]].map(([key, label]) => [key, normalActivity(label)])
  ),

  ...Object.fromEntries(
    [["total_area_range_from", "Fläche"], ["total_area_range_to", "Fläche bis"]].map(([key, label]) => [key, areaActivity(label)])
  )
};
