import { useParams } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import DeleteButton from "~/src/ui/buttons/delete-edit-project-button/index.jsx";
import EditButton from "~/src/ui/buttons/edit-project-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import SignedImage from "~/src/ui/images/signed-image/index.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import DeleteProjectImageModal from "./delete-project-image-modal.jsx";
import ProjectImageSlide from "./project-image-slide.jsx";

/**
 *
 * @example
 */
const ProjectImage = () => {
  const { id } = useParams();
  const { isAdmin, user } = useAuth();
  const {
    isError, isLoading, project
  } = useProject(id);
  const { editPossible, editing } = useEdit({
    project,
    user
  });
  const {
    hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  return (
    <TabListContainer>
      <ProjectImageSlide hide={hideSlide} projectId={id} visible={slideVisible} />

      <DeleteProjectImageModal hide={hideDelete} project={deleteData} show={deleteVisible} />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Projektbild" />

        <DescriptionContainer>

          <div>
            {project?.image_file_name
              ? (
                <div>
                  <p>{project?.show_image_public ? <span className="font-medium text-emerald-500">Bild öffentlich</span> : <span className="font-medium text-red-500">Bild privat</span>}</p>

                  <p>{project?.image_landscape ? "Querformat" : "Hochformat"}</p>
                </div>
              )
              : null}

            {project && project.image_file_name
              ? (
                <>
                  <div className="mt-2 h-auto w-40 rounded-lg">
                    <SignedImage document={{ file_name: project.image_file_name }} styles="object-cover" />
                  </div>

                  <p className="mt-2">{project.image_file_name}</p>
                </>
              )
              : null}
          </div>

        </DescriptionContainer>

        <div className="flex flex-col gap-4">
          <EditButton
            editPossible={editPossible}
            editing={editing}
            onClick={() => showSlide((previous) => !previous)}
          />

          {
            project && project.image_file_name
              ? (
                <DeleteButton
                  editPossible={editPossible}
                  onClick={() => showDelete(project)}
                />
              )
              : null
          }
        </div>
      </div>

    </TabListContainer>
  );
};

export default ProjectImage;
