/* eslint-disable max-lines-per-function */

/**
 *
 * @param {typeof test} contract - The root object
 * @param props0 - The root object
 * @param props0.editing - The root object
 * @returns
 * @example
 */
const transformContractToUnit = async (
  {
    balkon,
    balkon_count,
    garten,
    keller,
    kv_date,
    kv_id,
    loggia,
    loggia_count,
    nutzflaeche,
    persons,
    price_net,
    price_total,
    rooms,
    terrasse,
    terrasse_count,
    tz,
    tz_year
  }
) => {
  const contractBuyers = persons
    .filter(({ typeId }) => typeId === 1);

  const contractCompanyBuyers = contractBuyers
    .filter(({ categoryId }) => [
      0,
      1,
      2
    ].includes(categoryId));

  const contractPrivateBuyers = contractBuyers
    .filter(({ categoryId }) => categoryId === 3);

  const baseUrl = globalThis?.settings?.REACT_APP_API_ENDPOINT;

  const countriesResponse = await fetch(`${baseUrl}/locations/countries`, { credentials: "include" });

  const { payload: { countries } } = await countriesResponse.json();

  return {
    hide_tz: false,
    internal_sale_notes: "",
    kv_id,
    kv_url: `https://www.immomapping.com/contractdetails.aspx?doc=${kv_id}&src=mapping`,
    sale_area: nutzflaeche,
    sale_balkon_area: balkon,
    sale_balkon_count: balkon_count,
    sale_data_available: true,
    sale_date: kv_date,
    sale_garten_area: garten,
    sale_keller_area: keller,
    sale_loggia_area: loggia,
    sale_loggia_count: loggia_count,
    sale_notice: "",
    sale_price_gross: price_total,
    sale_price_net: price_net,
    sale_room_count: rooms,
    sale_terrasse_area: terrasse,
    sale_terrasse_count: terrasse_count,
    tz_number: tz,
    tz_year,

    company_buyers: contractCompanyBuyers
      .map(({
        lastname: name,
        city,
        companyNumber: reg_number,
        country,
        stairs,
        street,
        streetNumber: house_number,
        top,
        zip: zipcode
      }) => ({
        city,
        country: countries.find(({ code }) => code === country) || null,
        house_number: house_number
          ? [
            house_number,
            stairs,
            top
          ].filter(Boolean).join("/")
          : null,
        is_zvr: false,
        name,
        no_reg_number: reg_number === "" || reg_number === null || reg_number === undefined,
        reg_number,
        show: true,
        street,
        zipcode
      })),
    private_buyers: contractPrivateBuyers
      .map(({
        firstname: name,
        lastname: surname,
        birthdate: birthdateString,
        city,
        country,
        stairs,
        street,
        streetNumber: house_number,
        title: title_prefix,
        titleSuffix,
        top,
        zip: zipcode
      }) => {
        const birthdate = birthdateString ? new Date(birthdateString) : null;

        if (birthdate) {
          birthdate.setHours(12);
        }

        return {
          birthdate,
          city,
          country: countries.find(({ code }) => code === country) || countries.find(({ code }) => code === "AUT"),
          house_number: house_number
            ? [
              house_number,
              stairs,
              top
            ].filter(Boolean).join("/")
            : null,
          name,
          show: true,
          street,
          surname,
          title_prefix,
          title_suffix: titleSuffix,
          zipcode
        };
      })
  };
};

export default transformContractToUnit;
