import Handler from "~/src/modules/handler.js";

/**
 *
 * @param values
 * @example
 */
const generateQuantityObject = (values) => ({
  total_area_range_from: values.total_area_range_from || null,
  total_area_range_to: values.show_total_area && values.total_area_range_to ? values.total_area_range_to : null,
  total_units: values.total_units || null,
  unit_category_id: values.unit_category.id
});

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/quantities/:id",

  afterEvery: ({ setIsSubmitting }) => {
    setIsSubmitting(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data, { projectId }) => ({
    quantity: {
      ...generateQuantityObject(data),
      project_id: projectId
    }
  }),
  transformUpdateData: (data) => ({
    quantity: generateQuantityObject(data)
  })
});

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
