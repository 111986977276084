import { ClipboardListIcon, ClockIcon } from "lucide-react";
import queryString from "query-string";
import {
  Link, useHistory, useLocation, useParams, useRouteMatch
} from "react-router-dom";

import NotFoundPage from "~/src/pages/not-found/index.jsx";

import EditProjectEditors from "~/src/features/edit-project-editors/index.jsx";

import useAuth from "~/src/hooks/use-auth.js";
import useAutomatedProject from "~/src/hooks/use-automated-project.js";
import useStore from "~/src/hooks/use-store.js";

import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import ProjectLocations from "~/src/ui/displays/project-locations/index.jsx";
import ProjectName from "~/src/ui/displays/project-name/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import ProjectIcon from "~/src/ui/icons/project-icon.jsx";
import TabLink from "~/src/ui/links/tab-link/index.jsx";
import Page from "~/src/ui/page/index.jsx";
import Tabs from "~/src/ui/routes/tabs/index.jsx";

/**
 *
 * @example
 */
const AutomatedProjectPage = () => {
  const { id } = useParams();
  const { path, url } = useRouteMatch();
  const { state } = useLocation();

  const {
    automated_project: project, isError, isLoading
  } = useAutomatedProject(id);
  const { isAdmin } = useAuth();

  const savedQueryAutomated = useStore((state) => state.savedQueryAutomated);
  const history = useHistory();

  const goBackProjectsWithQuery = () => {
    const savedQueryString = queryString.stringify(savedQueryAutomated, {
      skipEmptyString: true,
      skipNull: true
    });

    history.push(`/automated-projects?${savedQueryString}`);
  };

  if (isError) {
    return <NotFoundPage status={isError?.status} />;
  }

  return (
    <Page title="Projektdetails">
      <PageHeader>
        <nav className="flex justify-between">
          <button className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700" onClick={goBackProjectsWithQuery} type="button">
            Zurück zur automatisierten Projektsuche
          </button>

          <div className="flex flex-col gap-2">
            <Link className="inline-flex items-center gap-1 text-sm font-medium text-gray-500 underline hover:text-gray-700" to={`/automated-projects/${id}/project-contracts`}>
              <ClipboardListIcon className="size-5 text-gray-500" />

              <span>Kaufverträge</span>
            </Link>

            <Link className="inline-flex items-center gap-1 text-sm font-medium text-gray-500 underline hover:text-gray-700" to={`/activities?automated-project=${id}`}>
              <ClockIcon className="size-5 text-gray-500" />

              <span>Aktivitäten</span>
            </Link>
          </div>
        </nav>

        <div className="mt-8 justify-between sm:flex">
          <div className="items-start sm:flex">
            {/* <ProjectIcon /> */}

            <div className="mt-2 sm:mt-1">
              <h2 className="flex items-center gap-2 text-2xl font-bold leading-7 text-gray-800 sm:truncate sm:text-2xl">
                <span className="rounded-md bg-gray-300 px-2 text-sm capitalize text-gray-900">ID: {project?.id}</span>

                <ProjectName isLoading={isLoading} project={project} state={state} />
              </h2>

              <ProjectLocations isLoading={isLoading} loadedProject={project} state={state} />
            </div>
          </div>

          <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:shrink-0 sm:justify-start">
            {!isLoading &&
              <EditProjectEditors automated projectId={id} />}
          </div>
        </div>
      </PageHeader>

      <MainContainer>
        <div className="mt-12">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex flex-wrap justify-center space-x-4 md:justify-start lg:space-x-8">
              <TabLink label="Übersicht" to={`${url}`} />

              <TabLink label="Objekte" to={`${url}/project-units`} />

              {isAdmin ? <TabLink label="Einstellungen" to={`${url}/project-settings`} /> : null}
            </nav>
          </div>
        </div>

        <div className="mt-6 px-4 pt-6 md:px-0">
          <Tabs path={path} />
        </div>

      </MainContainer>

    </Page>
  );
};

export default AutomatedProjectPage;
