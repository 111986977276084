import useSWR from "swr";

import { fetcher } from "~/src/modules/api.js";

/**
 *
 * @param options0 - The root object
 * @param options0.bauteil - The root object
 * @param options0.category - The root object
 * @param options0.hidden - The root object
 * @param options0.name - The root object
 * @param options0.page - The root object
 * @param options0.pagination - The root object
 * @param options0.projectId - The root object
 * @param options0.sort - The root object
 * @param options0.tz - The root object
 * @example
 */
const useAutomatedUnits = ({
  bauteil = "", category = "", hidden = false, name = "", page = 1, pagination = true, projectId, sort = "", tz = ""
}) => {
  let parameters = `page=${page}&name=${encodeURIComponent(name)}&tz=${encodeURIComponent(tz)}&sort=${sort}&category=${category}&bauteil=${bauteil}&pagination=${Number(pagination)}`;

  if (hidden) {
    parameters += "&hidden=true";
  }

  const {
    data, error, mutate
  } = useSWR(`/automated-projects/${projectId}/units?${parameters}`, fetcher);

  return {
    automatedUnits: data?.payload.automatedUnits,
    categories: data?.payload.categories,
    isError: error,
    isLoading: !error && !data,
    mutate,
    pagination: data?.payload.pagination,
    unitCount: data?.payload.unitCount,
    unitStats: data?.payload.unitStats
  };
};

export default useAutomatedUnits;
