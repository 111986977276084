import API from "~/src/modules/api.js";

/**
 *
 * @param projectNotes
 * @param projectId
 * @param mutate
 * @param hide
 * @param showError
 * @param setIsLoading
 * @example
 */
const handleUpdate = (projectNotes, projectId, mutate, hide, showError, setIsLoading) => {
  const data = {
    automated_project: {
      project_notes: projectNotes
    }
  };

  setIsLoading(true);

  API.patch(`/automated-projects/${projectId}/project-notes`, data)
    .then((res) => {
      mutate();
      hide();
    }).catch((error) => {
      showError();
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export {
  handleUpdate
};
