import cn from "classnames";

import { formatArea, formatEuro } from "~/src/modules/formatters.new.js";

const directions = ["top", "bottom"];

/**
 *
 * @param props0 - The root object
 * @param props0.label - The root object
 * @param props0.showEditActions - The root object
 * @param props0.showSlide - The root object
 * @param props0.statistic - The root object
 * @param props0.statisticKey - The root object
 * @example
 */
const TopBottom = ({
  label, showEditActions, showSlide, statistic, statisticKey
}) => (
  <li className={cn("bg-gray-100 p-4 rounded shadow space-y-4")} key={statisticKey}>
    <h3 className="text-lg font-medium text-gray-800">{label}</h3>

    <ul
      className="grid grid-cols-2 gap-2"
    >
      {
        directions.every((direction) => statistic?.[direction].length === 0)
          ? (
            <li className="col-span-2">
              <p className="text-gray-400">Kein Eintrag</p>
            </li>
          )
          : (
            ["green", "red"]
              .map((color, index) => [directions[index], color])
              .map(([direction, color]) => (
                <li key={direction}>
                  <ul className="flex flex-col gap-2">
                    {
                      statistic?.[direction]
                        .map(({
                          id, data, name: unitName, smape, value
                        }, index) => {
                          const opacity = smape / 2;
                          const adjustedOpacity = (smape + 1) / 2;

                          return (
                            <li
                              key={index}
                              className={cn(
                                "group flex gap-2 justify-between items-center p-2 border rounded relative",
                                direction,
                                {
                                  "bg-emerald-600/0": color === "green" && opacity >= 0 && opacity <= 0.05,
                                  "bg-emerald-600/10": color === "green" && opacity > 0.05 && opacity <= 0.15,
                                  "bg-emerald-600/20": color === "green" && opacity > 0.15 && opacity <= 0.25,
                                  "bg-emerald-600/30": color === "green" && opacity > 0.25 && opacity <= 0.35,
                                  "bg-emerald-600/40": color === "green" && opacity > 0.35 && opacity <= 0.45,
                                  "bg-emerald-600/50": color === "green" && opacity > 0.45,
                                  "bg-red-600/0": color === "red" && opacity >= 0 && opacity <= 0.05,
                                  "bg-red-600/10": color === "red" && opacity > 0.05 && opacity <= 0.15,
                                  "bg-red-600/20": color === "red" && opacity > 0.15 && opacity <= 0.25,
                                  "bg-red-600/30": color === "red" && opacity > 0.25 && opacity <= 0.35,
                                  "bg-red-600/40": color === "red" && opacity > 0.35 && opacity <= 0.45,
                                  "bg-red-600/50": color === "red" && opacity > 0.45,
                                  "border-emerald-600": color === "green",
                                  "border-red-600": color === "red",
                                  "cursor-pointer": showEditActions,
                                  "hover:bg-emerald-800/0": showEditActions && color === "green" && opacity >= 0 && opacity <= 0.05,
                                  "hover:bg-emerald-800/10": showEditActions && color === "green" && opacity > 0.05 && opacity <= 0.15,
                                  "hover:bg-emerald-800/20": showEditActions && color === "green" && opacity > 0.15 && opacity <= 0.25,
                                  "hover:bg-emerald-800/30": showEditActions && color === "green" && opacity > 0.25 && opacity <= 0.35,
                                  "hover:bg-emerald-800/40": showEditActions && color === "green" && opacity > 0.35 && opacity <= 0.45,
                                  "hover:bg-emerald-800/50": showEditActions && color === "green" && opacity > 0.45,
                                  "hover:bg-red-800/0": showEditActions && color === "red" && opacity >= 0 && opacity <= 0.05,
                                  "hover:bg-red-800/10": showEditActions && color === "red" && opacity > 0.05 && opacity <= 0.15,
                                  "hover:bg-red-800/20": showEditActions && color === "red" && opacity > 0.15 && opacity <= 0.25,
                                  "hover:bg-red-800/30": showEditActions && color === "red" && opacity > 0.25 && opacity <= 0.35,
                                  "hover:bg-red-800/40": showEditActions && color === "red" && opacity > 0.35 && opacity <= 0.45,
                                  "hover:bg-red-800/50": showEditActions && color === "red" && opacity > 0.45,
                                  "hover:border-emerald-800": color === "green" && showEditActions,
                                  "hover:border-red-800": color === "red" && showEditActions
                                }
                              )}
                              onClick={() => {
                                if (showEditActions) {
                                  showSlide(data);
                                }
                              }}
                            >
                              <h4
                                className={cn(
                                  "text-ellipsis whitespace-nowrap overflow-hidden transition-opacity duration-500 ",
                                  {
                                    "text-emerald-900": color === "green",
                                    "text-red-900": color === "red"
                                  }
                                )}
                              >
                                {unitName}
                              </h4>

                              <span
                                className={cn(
                                  "text-white font-medium whitespace-nowrap overflow-hidden rounded px-1 py-0.5 transition-opacity duration-500 min-w-max font-mono text-sm",
                                  {
                                    "bg-emerald-600": color === "green" && adjustedOpacity > 0.95,
                                    "bg-emerald-600/0": color === "green" && adjustedOpacity >= 0 && adjustedOpacity <= 0.05,
                                    "bg-emerald-600/10": color === "green" && adjustedOpacity > 0.05 && adjustedOpacity <= 0.15,
                                    "bg-emerald-600/20": color === "green" && adjustedOpacity > 0.15 && adjustedOpacity <= 0.25,
                                    "bg-emerald-600/30": color === "green" && adjustedOpacity > 0.25 && adjustedOpacity <= 0.35,
                                    "bg-emerald-600/40": color === "green" && adjustedOpacity > 0.35 && adjustedOpacity <= 0.45,
                                    "bg-emerald-600/50": color === "green" && adjustedOpacity > 0.45 && adjustedOpacity <= 0.55,
                                    "bg-emerald-600/60": color === "green" && adjustedOpacity > 0.55 && adjustedOpacity <= 0.65,
                                    "bg-emerald-600/70": color === "green" && adjustedOpacity > 0.65 && adjustedOpacity <= 0.75,
                                    "bg-emerald-600/80": color === "green" && adjustedOpacity > 0.75 && adjustedOpacity <= 0.85,
                                    "bg-emerald-600/90": color === "green" && adjustedOpacity > 0.85 && adjustedOpacity <= 0.95,

                                    "bg-red-600": color === "red" && adjustedOpacity > 0.95,
                                    "bg-red-600/0": color === "red" && adjustedOpacity >= 0 && adjustedOpacity <= 0.05,
                                    "bg-red-600/10": color === "red" && adjustedOpacity > 0.05 && adjustedOpacity <= 0.15,
                                    "bg-red-600/20": color === "red" && adjustedOpacity > 0.15 && adjustedOpacity <= 0.25,
                                    "bg-red-600/30": color === "red" && adjustedOpacity > 0.25 && adjustedOpacity <= 0.35,
                                    "bg-red-600/40": color === "red" && adjustedOpacity > 0.35 && adjustedOpacity <= 0.45,
                                    "bg-red-600/50": color === "red" && adjustedOpacity > 0.45 && adjustedOpacity <= 0.55,
                                    "bg-red-600/60": color === "red" && adjustedOpacity > 0.55 && adjustedOpacity <= 0.65,
                                    "bg-red-600/70": color === "red" && adjustedOpacity > 0.65 && adjustedOpacity <= 0.75,
                                    "bg-red-600/80": color === "red" && adjustedOpacity > 0.75 && adjustedOpacity <= 0.85,
                                    "bg-red-600/90": color === "red" && adjustedOpacity > 0.85 && adjustedOpacity <= 0.95

                                  }
                                )}
                              >
                                {
                                  statisticKey.includes("Area")
                                    ? formatArea(value)
                                    : formatEuro(value)
                                }
                              </span>
                            </li>
                          );
                        })
                    }
                  </ul>
                </li>
              ))
          )
      }
    </ul>
  </li>
);

export default TopBottom;
