import Joi from "joi";

const initialValues = {
  ez: "",
  kgnr: ""
};

const schema = Joi.object({
  ez: Joi.number().integer().positive()
    .empty("")
    .required(),
  kgnr: Joi.number().integer().positive()
    .empty("")
    .required()
});

export {
  initialValues,
  schema
};
