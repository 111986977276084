/**
 *
 * @param props0 - The root object
 * @param props0.styles - The root object
 * @example
 */
const LockIcon = ({ styles }) => (
  <svg aria-hidden="true" className={`size-5 text-white group-hover:text-gray-100 ${styles}`} fill="currentColor" viewBox="0 0 20 20">
    <path clipRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" fillRule="evenodd" />
  </svg>
);

export default LockIcon;
