import dayjs from "dayjs";
import Joi from "joi";

import companyBuyerSchema from "../_common/company-buyer-schema.js";
import privateBuyerSchema from "../_common/private-buyer-schema.js";

const schema = Joi.object({
  buildingPart: Joi.object({
    id: Joi.number().positive().empty(""),
    label: Joi.optional(),
    name: Joi.string().empty(""),
    sorting: Joi.number().positive().empty(""),
    value: Joi.optional()
  }).empty(null),
  buyable: Joi.boolean().required(),
  name: Joi.string().empty("").required(),
  offerDataAvailable: Joi.boolean().optional(),
  rentable: Joi.boolean().required(),
  saleDataAvailable: Joi.boolean().when("offerDataAvailable", {
    is: Joi.equal(null, false),
    then: Joi.valid(true).required()
  }),
  show: Joi.boolean().required(),
  unit_category: Joi.object({
    id: Joi.number().positive().empty("")
      .required(),
    code: Joi.string().empty("").required(),
    name: Joi.string().empty("")
  }).unknown(),
  verwertet: Joi.boolean().required(),
  verwertetDate: Joi.date()
    .empty(null).allow("")
    .optional()
    .when("saleDataAvailable", {
      is: true,
      then: Joi.when("offerDataAvailable", {
        is: true,
        then: Joi.when("verwertet", {
          is: true,
          then: Joi.date()
            .max(Joi.ref("saleDate", {
              adjust: (saleDate) => (saleDate
                ? dayjs(saleDate).subtract(1, "month").add(1, "day")
                  .toDate()
                : dayjs().add(1_000, "years").toDate())
            }))
            .error((errors) => {
              const error = errors.find(({ code }) => code === "date.max");

              error.code = "date.max.verwertet";

              return error;
            })
        })
      })
    }),
  verwertetDateFormat: Joi
    .when("verwertet", {
      is: true,
      otherwise: Joi.object().optional(),
      then: Joi.object({
        label: Joi.string().empty("").optional(),
        value: Joi.string().empty("").required()
      }).required()
    }),

  hideTz: Joi.boolean(),
  kvId: Joi.string().max(100).empty("")
    .optional(),
  kvUrl: Joi.string().uri().empty("")
    .optional(),
  tzNumber: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.string().empty("").optional(),
      then: Joi.string().empty("").required()
    }),
  tzYear: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.date().empty("").optional(),
      then: Joi.date().empty("").required()
    }),

  saleDate: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.date().empty("").optional(),
      then: Joi.date().empty(null).required()
    }),
  salePriceGross: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.string().empty("").optional(),
      then: Joi.string().empty("").optional()
    }),
  salePriceNet: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.string().empty("").optional(),
      then: Joi.string().empty("").required()
    }),

  offerPriceBruttoInvestor: Joi.string().empty(""),
  offerPriceInvestor: Joi.string().empty(""),
  offerPriceNormal: Joi.string().empty(""),
  showOfferPriceBruttoInvestorHistory: Joi.boolean(),
  showOfferPriceInvestorHistory: Joi.boolean(),
  showOfferPriceNormalHistory: Joi.boolean(),

  offerPriceBruttoInvestorHistory: Joi.alternatives().conditional("showOfferPriceBruttoInvestorHistory", {
    is: true,
    otherwise: Joi.any().optional(),
    then: Joi.array().items(
      Joi.object({
        date: Joi.date().required().messages({
          "any.required": "Date is required",
          "date.base": "Invalid date format"
        }),
        price: Joi.number().required().messages({
          "any.required": "Price is required",
          "number.base": "Price must be a number"
        })
      })
    )
  }),
  offerPriceInvestorHistory: Joi.alternatives().conditional("showOfferPriceInvestorHistory", {
    is: true,
    otherwise: Joi.any().optional(),
    then: Joi.array().items(
      Joi.object({
        date: Joi.date().required().messages({
          "any.required": "Date is required",
          "date.base": "Invalid date format"
        }),
        price: Joi.number().required().messages({
          "any.required": "Price is required",
          "number.base": "Price must be a number"
        })
      })
    )
  }),
  offerPriceNormalHistory: Joi.alternatives().conditional("showOfferPriceNormalHistory", {
    is: true,
    otherwise: Joi.any().optional(),
    then: Joi.array().items(
      Joi.object({
        date: Joi.date().required().messages({
          "any.required": "Date is required",
          "date.base": "Invalid date format"
        }),
        price: Joi.number().required().messages({
          "any.required": "Price is required",
          "number.base": "Price must be a number"
        })
      })
    )
  }),

  rentBk: Joi.string().empty(""),
  rentBkHistory: Joi.alternatives().conditional("showRentBkHistory", {
    is: true,
    otherwise: Joi.any().optional(),
    then: Joi.array().items(
      Joi.object({
        date: Joi.date().required().messages({
          "any.required": "Date is required",
          "date.base": "Invalid date format"
        }),
        price: Joi.number().required().messages({
          "any.required": "Price is required",
          "number.base": "Price must be a number"
        })
      })
    )
  }),
  rentBrutto: Joi.string().empty(""),
  rentBruttoHistory: Joi.alternatives().conditional("showRentBruttoHistory", {
    is: true,
    otherwise: Joi.any().optional(),
    then: Joi.array().items(
      Joi.object({
        date: Joi.date().required().messages({
          "any.required": "Date is required",
          "date.base": "Invalid date format"
        }),
        price: Joi.number().required().messages({
          "any.required": "Price is required",
          "number.base": "Price must be a number"
        })
      })
    )
  }),
  rentNetto: Joi.string().empty(""),
  rentNettoHistory: Joi.alternatives().conditional("showRentNettoHistory", {
    is: true,
    otherwise: Joi.any().optional(),
    then: Joi.array().items(
      Joi.object({
        date: Joi.date().required().messages({
          "any.required": "Date is required",
          "date.base": "Invalid date format"
        }),
        price: Joi.number().required().messages({
          "any.required": "Price is required",
          "number.base": "Price must be a number"
        })
      })
    )
  }),
  showRentBkHistory: Joi.boolean(),
  showRentBruttoHistory: Joi.boolean(),
  showRentNettoHistory: Joi.boolean(),

  privateBuyers: Joi.array().items(privateBuyerSchema).sparse(),

  companyBuyers: Joi.array().items(companyBuyerSchema).sparse(),

  floor: Joi.string().empty(""),
  maisonette: Joi.boolean(),
  position: Joi.number().positive().precision(4)
    .empty(""),
  positionGroup: Joi.number().positive().precision(4)
    .empty(""),
  positionStaircase: Joi.number().positive().precision(4)
    .empty(""),
  staircase: Joi.string().empty("").pattern(/(^|\s)etage($|\s)/iu, { invert: true })
    .error((errors) => {
      const error = errors.find(({ code }) => code === "string.pattern.invert.base");

      error.code = "string.etage";

      return error;
    }),

  offerArea: Joi.string().empty(""),
  saleArea: Joi.string().empty(""),

  offerRoomCount: Joi.number().positive().empty(""),
  saleRoomCount: Joi.number().positive().empty(""),

  offerLoggiaArea: Joi.string().empty(""),
  offerLoggiaCount: Joi.number().positive().empty(""),
  saleLoggiaArea: Joi.string().empty(""),
  saleLoggiaCount: Joi.number().positive().empty(""),

  offerBalkonArea: Joi.string().empty(""),
  offerBalkonCount: Joi.number().positive().empty(""),
  saleBalkonArea: Joi.string().empty(""),
  saleBalkonCount: Joi.number().positive().empty(""),

  offerGartenArea: Joi.string().empty(""),
  offerGartenCount: Joi.number().positive().empty(""),
  saleGartenArea: Joi.string().empty(""),
  saleGartenCount: Joi.number().positive().empty(""),

  offerTerrasseArea: Joi.string().empty(""),
  offerTerrasseCount: Joi.number().positive().empty(""),
  saleTerrasseArea: Joi.string().empty(""),
  saleTerrasseCount: Joi.number().positive().empty(""),

  offerKellerArea: Joi.string().empty(""),
  offerKellerCount: Joi.number().positive().empty(""),
  saleKellerArea: Joi.string().empty(""),
  saleKellerCount: Joi.number().positive().empty(""),

  internalOfferNotes: Joi.string().empty(""),
  internalSaleNotes: Joi.string().empty(""),
  offerNotice: Joi.string().empty(""),
  saleNotice: Joi.string().empty("")
});

export default schema;
