import dateFormatter from "~/src/modules/date-formatter.js";

import customActivity from "./custom-activity.js";

const dateActivity = (label) => customActivity({
  label,
  value: dateFormatter
});

export default dateActivity;
