import cn from "classnames";
import dayjs from "dayjs";

import dateFormatter from "~/src/modules/date-formatter.js";
import {
  formatDate, formatEuro, formatNumber, formatYear
} from "~/src/modules/formatters.js";

import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import UnitTd from "~/src/ui/tables/unit-td/index.jsx";

import UnitDocumentItem from "./unit-document-item/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.automated - The root object
 * @param props0.compact - The root object
 * @param props0.isDummy - The root object
 * @param props0.showDeleteDocument - The root object
 * @param props0.showEditActions - The root object
 * @param props0.showEditDocument - The root object
 * @param props0.showSlide - The root object
 * @param props0.unit - The root object
 * @example
 */
const ProjectUnit = ({
  automated, compact, isDummy = false, showDeleteDocument, showEditActions, showEditDocument, showSlide, unit
}) => {
  const offerDocuments = unit?.documents?.filter((document_) => document_.is_offer_document) ?? [];
  const saleDocuments = unit?.documents?.filter((document_) => !document_.is_offer_document) ?? [];

  const verwertetDate = unit.verwertet_date ? ` ${dateFormatter(unit.verwertet_date, unit.verwertet_date_format)}` : null;

  const validOfferArea = unit.offer_area && unit?.offer_area !== "0.00";
  const validSaleArea = unit.sale_area && unit?.sale_area !== "0.00";

  const displayPlaceholder = (isPerSquaremeter) => {
    const isNotRentableOrBuyable = !unit.rentable && !unit.buyable;
    const hasNoRentValues = !unit.rent_netto && !unit.rent_brutto && !unit.rent_bk;
    const hasNoPriceValues = !unit.offer_price_normal && !unit.offer_price_investor && !unit.offer_price_brutto_investor;
    const hasNoValues = hasNoRentValues && hasNoPriceValues;
    const dependsOnArea = isPerSquaremeter;

    return isNotRentableOrBuyable || hasNoValues || (dependsOnArea && !validOfferArea);
  };

  return (
    <div className="mt-2">

      <div className="mb-1 flex justify-between space-x-2 text-sm">
        <div className="flex items-center">
          <div className="space-x-4">
            <span className="rounded-md bg-gray-300 px-2 text-xs font-bold capitalize text-gray-900">ID: {unit.id}</span>

            {!automated && <span>Bauteil: {unit.building_part_id ? `${Number.parseFloat(unit.building_part?.sorting)} (${unit.building_part?.name})` : "-"}</span>}

            <span>Stiege: {unit.position_staircase ? Number.parseFloat(unit.position_staircase) : "-"}</span>

            <span>Nummer: {unit.position ? Number.parseFloat(unit.position) : "-"}</span>
          </div>

          <p className="ml-20">Kategorie: <span className="font-bold">{unit.unit_category?.name}</span></p>
        </div>

        {!automated && (
          <p>
            Zuletzt bearbeitet:
            <span className="font-bold">
              {formatDate({
                date: unit.updated_at,
                withTime: true
              })}
            </span>

            {unit.last_editor ? <span className="ml-1 text-xs">({unit.last_editor.username})</span> : null}
          </p>
        )}
      </div>

      {(unit.offer_data_available && !isDummy)
        ? (
          <div className="border-primary rounded-sm border-l-4 bg-gray-100 py-2 pl-2 pr-3 sm:rounded-none">

            <p className="bg-primary w-16 rounded-sm py-px text-center text-xs font-medium text-white sm:hidden">
              Angebot
            </p>

            <div className="items-center justify-between sm:flex">

              <div className="mt-2 flex items-center sm:mt-0">
                <h3 className="text-base font-medium text-gray-800">{unit.name}</h3>

                <p className="bg-primary ml-4 hidden w-16 rounded-sm py-px text-center text-xs font-medium text-white sm:block">
                  Angebot
                </p>
              </div>

              {unit.verwertet
                ? (
                  <p className="mt-1 text-xs font-medium text-gray-600 sm:mt-0 sm:text-sm">
                    verwertet
                    {unit.verwertet_date ? <span>: {verwertetDate}</span> : null}
                  </p>
                )
                : null}

            </div>

            <div className="mt-4 justify-between text-base text-gray-600 sm:flex">
              <div>
                <span>Stiege: {unit.staircase ? unit.staircase : "-"}</span>

                <span className="ml-6">Geschoss: {unit.floor ? unit.floor : "-"}</span>
              </div>

              {unit.offer_room_count ? <span>{unit.offer_room_count} Zimmer</span> : null}
            </div>

            <div className="mt-4 grid grid-cols-3 gap-1 lg:grid-cols-6">

              <div className="col-span-3 grid grid-cols-1 gap-1 sm:grid-cols-3">
                <div className="bg-gray-300 p-2 text-center text-sm text-gray-500">
                  <p>Preis / m²</p>

                  <div className="mt-0.5 space-y-3">
                    {(unit.buyable && unit.offer_price_normal && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.offer_price_normal) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Eigenverbraucher (Kauf)</p>
                        </div>
                      )
                      : null}

                    {(unit.buyable && unit.offer_price_investor && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.offer_price_investor) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Investor (Kauf)</p>
                        </div>
                      )
                      : null}

                    {(unit.buyable && unit.offer_price_brutto_investor && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.offer_price_brutto_investor) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Ø Investor Brutto (Kauf)</p>
                        </div>
                      )
                      : null}

                    {(unit.rentable && unit.rent_netto && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.rent_netto) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Ø Miete Netto</p>
                        </div>
                      )
                      : null}

                    {(unit.rentable && unit.rent_brutto && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.rent_brutto) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Ø Miete Netto + USt</p>
                        </div>
                      )
                      : null}

                    {(unit.rentable && unit.rent_bk && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.rent_bk) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Ø Miete Gesamtbelastung</p>
                        </div>
                      )
                      : null}

                    {(displayPlaceholder(true)) &&
                      <p className="font-bold">-</p>}
                  </div>
                </div>

                <div className="bg-gray-300 p-2 text-center text-sm text-gray-500">
                  <p className="">Nutzfläche</p>

                  <p className="font-bold">
                    {unit.offer_area ? `${formatNumber(unit.offer_area)} m²` : "-"}
                  </p>
                </div>

                <div className="bg-gray-300 p-2 text-center text-sm text-gray-500">
                  <p>Preis</p>

                  <div className="mt-0.5 space-y-3">
                    {(unit.offer_price_normal && unit.buyable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.offer_price_normal)}</p>

                          <p className="text-xs leading-none">Eigenverbraucher (Kauf) </p>

                          {unit.show_offer_price_normal_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.offer_price_normal_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`offer_price_normal_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}

                        </div>
                      )
                      : null}

                    {(unit.offer_price_investor && unit.buyable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.offer_price_investor)}</p>

                          <p className="text-xs leading-none">Investor Netto (Kauf)</p>

                          {unit.show_offer_price_investor_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.offer_price_investor_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`offer_price_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(unit.offer_price_brutto_investor && unit.buyable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.offer_price_brutto_investor)}</p>

                          <p className="text-xs leading-none">Investor Brutto (Kauf)</p>

                          {unit.show_offer_price_brutto_investor_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.offer_price_brutto_investor_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`offer_price_brutto_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(unit.rent_netto && unit.rentable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.rent_netto)}</p>

                          <p className="text-xs leading-none">Miete Netto</p>

                          {unit.show_rent_netto_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.rent_netto_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`rent_netto_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(unit.rent_brutto && unit.rentable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.rent_brutto)}</p>

                          <p className="text-xs leading-none">Miete Netto + USt</p>

                          {unit.show_rent_brutto_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.rent_brutto_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`rent_brutto_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(unit.rent_bk && unit.rentable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.rent_bk)}</p>

                          <p className="text-xs leading-none">Miete Gesamtbelastung</p>

                          {unit.show_rent_bk_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.rent_bk_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`rent_bk_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(displayPlaceholder(false)) &&
                      <p className="font-bold">-</p>}
                  </div>
                </div>
              </div>

              <div className="col-span-3">

                <div className="mt-2 grid grid-cols-3 gap-1 text-sm text-gray-500 sm:grid-cols-5 sm:text-right">
                  <div >
                    <p>
                      <span>Loggia: </span>

                      <UnitTd val={unit.offer_loggia_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_loggia_area} />
                  </div>

                  <div>
                    <p>
                      <span>Balkon: </span>

                      <UnitTd val={unit.offer_balkon_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_balkon_area} />
                  </div>

                  <div>
                    <p>
                      <span>Terrasse: </span>

                      <UnitTd val={unit.offer_terrasse_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_terrasse_area} />
                  </div>

                  <div>
                    <p>
                      <span>Garten: </span>

                      <UnitTd val={unit.offer_garten_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_garten_area} />
                  </div>

                  <div>
                    <p>
                      <span>Keller: </span>

                      <UnitTd val={unit.offer_keller_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_keller_area} />
                  </div>
                </div>
              </div>

            </div>

            {!compact && (
              <div className="mt-4">
                <p className="whitespace-pre-line text-sm text-gray-500">Bemerkungen: <br />{unit.offer_notice ? unit.offer_notice : "-"}</p>

                {!automated && <p className="mt-4 whitespace-pre-line text-sm text-gray-500">Interne Notizen: <br />{unit.internal_offer_notes ? unit.internal_offer_notes : "-"}</p>}

                {!automated && (
                  <div className="mt-4 flex items-center">
                    <p className="text-sm text-gray-500">Dokumente:</p>

                    {showEditActions
                      ? (
                        <button
                          className="bg-primary ml-2 block text-white"
                          type="button"
                          onClick={() => showSlide({
                            isOfferDocument: true,
                            unit
                          })}
                        >
                          <PlusIcon className="h-4 w-8" />
                        </button>
                      )
                      : null}
                  </div>
                )}

                <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                  {offerDocuments.map((document) => (
                    <UnitDocumentItem
                      document={document}
                      key={`document_unit_${document.id}`}
                      showDeleteDocument={showDeleteDocument}
                      showEditActions={showEditActions}
                      showEditDocument={showEditDocument}
                    />
                  ))}
                </ul>
              </div>
            )}

          </div>
        )
        : null}

      {(unit.offer_data_available && isDummy)
        ? (
          <div className="border-primary rounded-sm border-l-4 bg-gray-100 py-2 pl-2 pr-3 sm:rounded-none">

            <p className="bg-primary w-16 rounded-sm py-px text-center text-xs font-medium text-white sm:hidden">
              Angebot
            </p>

            <div className="items-center justify-between sm:flex">

              <div className="mt-2 flex items-center sm:mt-0">
                <h3 className="text-base font-medium text-gray-800">{unit.name}</h3>

                <p className="bg-primary ml-4 hidden w-16 rounded-sm py-px text-center text-xs font-medium text-white sm:block">
                  Angebot
                </p>
              </div>

              {unit.verwertet
                ? (
                  <p className="mt-1 text-xs font-medium text-gray-600 sm:mt-0 sm:text-sm">
                    verwertet
                    {unit.verwertet_date ? <span>: {verwertetDate}</span> : null}
                  </p>
                )
                : null}

            </div>

            <div className="mt-4 justify-between text-base text-gray-600 sm:flex">
              <div>
                <span>Stiege: {unit.staircase ? unit.staircase : "-"}</span>

                <span className="ml-6">Geschoss: {unit.floor ? unit.floor : "-"}</span>
              </div>

              {unit.offer_room_count ? <span>{unit.offer_room_count} Zimmer</span> : null}
            </div>

            <div className="mt-4 grid grid-cols-3 gap-1">

              <div className="col-span-3 grid grid-cols-1 gap-1 sm:grid-cols-3">
                <div className="bg-gray-300 p-2 text-center text-sm text-gray-500">
                  <p>Preis / m²</p>

                  <div className="mt-0.5 space-y-3">
                    {(unit.buyable && unit.offer_price_normal && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.offer_price_normal) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Eigenverbraucher (Kauf)</p>
                        </div>
                      )
                      : null}

                    {(unit.buyable && unit.offer_price_investor && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.offer_price_investor) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Investor (Kauf)</p>
                        </div>
                      )
                      : null}

                    {(unit.buyable && unit.offer_price_brutto_investor && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.offer_price_brutto_investor) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Ø Investor Brutto (Kauf)</p>
                        </div>
                      )
                      : null}

                    {(unit.rentable && unit.rent_netto && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.rent_netto) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Ø Miete Netto</p>
                        </div>
                      )
                      : null}

                    {(unit.rentable && unit.rent_brutto && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.rent_brutto) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Ø Miete Netto + USt</p>
                        </div>
                      )
                      : null}

                    {(unit.rentable && unit.rent_bk && validOfferArea)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro((unit.rent_bk) / unit.offer_area)}</p>

                          <p className="text-xs leading-none">Ø Miete Gesamtbelastung</p>
                        </div>
                      )
                      : null}

                    {(displayPlaceholder(true)) &&
                      <p className="font-bold">-</p>}
                  </div>
                </div>

                <div className="bg-gray-300 p-2 text-center text-sm text-gray-500">
                  <p className="">Nutzfläche</p>

                  <p className="font-bold">
                    {unit.offer_area ? `${formatNumber(unit.offer_area)} m²` : "-"}
                  </p>
                </div>

                <div className="bg-gray-300 p-2 text-center text-sm text-gray-500">
                  <p>Preis</p>

                  <div className="mt-0.5 space-y-3">
                    {(unit.offer_price_normal && unit.buyable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.offer_price_normal)}</p>

                          <p className="text-xs leading-none">Eigenverbraucher (Kauf) </p>

                          {unit.show_offer_price_normal_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.offer_price_normal_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`offer_price_normal_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}

                        </div>
                      )
                      : null}

                    {(unit.offer_price_investor && unit.buyable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.offer_price_investor)}</p>

                          <p className="text-xs leading-none">Investor Netto (Kauf)</p>

                          {unit.show_offer_price_investor_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.offer_price_investor_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`offer_price_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(unit.offer_price_brutto_investor && unit.buyable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.offer_price_brutto_investor)}</p>

                          <p className="text-xs leading-none">Investor Brutto (Kauf)</p>

                          {unit.show_offer_price_brutto_investor_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.offer_price_brutto_investor_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`offer_price_brutto_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(unit.rent_netto && unit.rentable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.rent_netto)}</p>

                          <p className="text-xs leading-none">Miete Netto</p>

                          {unit.show_rent_netto_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.rent_netto_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`rent_netto_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(unit.rent_brutto && unit.rentable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.rent_brutto)}</p>

                          <p className="text-xs leading-none">Miete Netto + USt</p>

                          {unit.show_rent_brutto_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.rent_brutto_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`rent_brutto_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(unit.rent_bk && unit.rentable)
                      ? (
                        <div>
                          <p className="font-bold">{formatEuro(unit.rent_bk)}</p>

                          <p className="text-xs leading-none">Miete Gesamtbelastung</p>

                          {unit.show_rent_bk_history
                            ? (
                              <>
                                <p className="mt-0.5 text-xs font-medium">Historische Preisentwicklung:</p>

                                <div className="flex flex-col">
                                  {unit.rent_bk_history
                                    .map((entry, index) => (
                                      <div className="flex justify-center space-x-1 text-xs" key={`rent_bk_investor_history-entry_${index}`}>
                                        <p>{entry.date ? `bis ${dateFormatter(entry.date, "month-short")}: ` : null}</p>

                                        <p>{entry.price ? formatEuro(entry.price) : null}</p>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )
                            : null}
                        </div>
                      )
                      : null}

                    {(displayPlaceholder(false)) &&
                      <p className="font-bold">-</p>}
                  </div>
                </div>
              </div>

              <div className="col-span-3">

                <div className="mt-2 grid grid-cols-5 gap-1 text-sm text-gray-500 sm:text-center">
                  <div >
                    <p>
                      <span>Loggia: </span>

                      <UnitTd val={unit.offer_loggia_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_loggia_area} />
                  </div>

                  <div>
                    <p>
                      <span>Balkon: </span>

                      <UnitTd val={unit.offer_balkon_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_balkon_area} />
                  </div>

                  <div>
                    <p>
                      <span>Terrasse: </span>

                      <UnitTd val={unit.offer_terrasse_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_terrasse_area} />
                  </div>

                  <div>
                    <p>
                      <span>Garten: </span>

                      <UnitTd val={unit.offer_garten_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_garten_area} />
                  </div>

                  <div>
                    <p>
                      <span>Keller: </span>

                      <UnitTd val={unit.offer_keller_count} />
                    </p>

                    <UnitTd area hideDash val={unit.offer_keller_area} />
                  </div>
                </div>
              </div>

            </div>

            {!compact && (
              <div className="mt-4">
                <p className="whitespace-pre-line text-sm text-gray-500">Bemerkungen: <br />{unit.offer_notice ? unit.offer_notice : "-"}</p>

                <p className="mt-4 whitespace-pre-line text-sm text-gray-500">Interne Notizen: <br />{unit.internal_offer_notes ? unit.internal_offer_notes : "-"}</p>

                <p className="text-sm text-gray-500"><br />Dokumente:</p>

                <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                  {offerDocuments.map((document) => (
                    <UnitDocumentItem
                      document={document}
                      key={`document_unit_${document.id}`}
                      showDeleteDocument={showDeleteDocument}
                      showEditActions={showEditActions}
                      showEditDocument={showEditDocument}
                    />
                  ))}
                </ul>
              </div>
            )}

          </div>
        )
        : null}

      {unit.sale_data_available
        ? (
          <div className="border-t-4 border-gray-300 text-sm">
            <div className="border-primary-green-dark border-l-4 bg-gray-100 py-2 pl-2 pr-3">

              <p className="bg-primary-green-dark w-16 rounded-sm py-px text-center text-xs font-medium text-white sm:hidden">
                Verkauft
              </p>

              <div className="items-center justify-between sm:flex">

                <div className="mt-2 flex items-center sm:mt-0">
                  <h3 className="text-base font-medium text-gray-800">{unit.name}</h3>

                  <p className="bg-primary-green-dark ml-4 hidden w-16 rounded-sm py-px text-center text-xs font-medium text-white sm:block">
                    Verkauft
                  </p>
                </div>

                <div className="mt-1 text-right text-xs font-medium text-gray-600 sm:mt-0 sm:text-sm">
                  <p
                    className={cn("", {
                      "text-amber-500 font-bold": unit.hide_tz
                    })}
                  >
                    {unit.kv_id
                      ? (
                        <a className="underline" href={`https://immobase.immounited.com/IMMOmapping/Default.aspx?mapmode=1&contractOfPurchaseId=${unit.kv_id}`} rel="noreferrer noopener" target="_blank">
                          TZ {(unit.tz_number && unit.tz_year) ? `${unit.tz_number}/${formatYear(unit.tz_year)}` : null}
                        </a>
                      )
                      : null}

                    {!unit.kv_id &&
                      <span>TZ {(unit.tz_number && unit.tz_year) ? `${unit.tz_number}/${formatYear(unit.tz_year)}` : null}</span>}

                    <span> vom {formatDate({ date: unit.sale_date })}</span>
                  </p>

                  {unit.hide_tz ? <p className="text-xs">außerbücherlicher Eigentümer</p> : null}

                  {unit.kv_id
                    ? (
                      <p className="mt-2 text-xs">
                        KV-ID:
                        {unit.kv_id
                          ? (
                            <a className="ml-2 underline" href={`https://kvea.immounited.com/?DocumentId=${unit.kv_id}`} rel="noreferrer noopener" target="_blank">
                              {unit.kv_id}
                            </a>
                          )
                          : null}
                      </p>
                    )
                    : null}

                </div>

              </div>

              <div className="mt-4 justify-between text-base text-gray-600 sm:flex">
                <div>
                  <span>Stiege: {unit.staircase ? unit.staircase : "-"}</span>

                  <span className="ml-6">Geschoss: {unit.floor ? unit.floor : "-"}</span>
                </div>

                {unit.sale_room_count ? <span>{unit.sale_room_count} Zimmer</span> : null}
              </div>

              <div className="mt-2 grid grid-cols-3 gap-1 sm:mt-4 lg:grid-cols-6">

                <div className="col-span-3 grid grid-cols-1 gap-1 sm:grid-cols-3">
                  <div className="bg-gray-300 p-2 text-center text-sm text-gray-500">
                    <p>Preis / m²</p>

                    <p className="font-bold">{unit.sale_price_net && validSaleArea ? `${formatEuro(unit.sale_price_net / unit.sale_area)}` : "-"}</p>
                  </div>

                  <div className="bg-gray-300 p-2 text-center text-sm text-gray-500">
                    <p className="">Nutzfläche</p>

                    <p className="font-bold">
                      {unit.sale_area ? `${formatNumber(unit.sale_area)} m²` : "-"}
                    </p>
                  </div>

                  <div className="bg-gray-300 p-2 text-center text-sm text-gray-500">
                    <p>Preis</p>

                    <p className="font-bold">
                      {unit.sale_price_net ? `${formatEuro(unit.sale_price_net)}` : "-"}

                    </p>
                  </div>
                </div>

                <div className="col-span-3">

                  <div className="mt-2 grid grid-cols-3 gap-1 text-sm text-gray-500 sm:grid-cols-5 sm:text-right">
                    <div>
                      <p>
                        <span>Loggia: </span>

                        <UnitTd val={unit.sale_loggia_count} />
                      </p>

                      <UnitTd area hideDash val={unit.sale_loggia_area} />
                    </div>

                    <div>
                      <p>
                        <span>Balkon: </span>

                        <UnitTd val={unit.sale_balkon_count} />
                      </p>

                      <UnitTd area hideDash val={unit.sale_balkon_area} />
                    </div>

                    <div>
                      <p>
                        Terrasse:
                        <UnitTd val={unit.sale_terrasse_count} />
                      </p>

                      <UnitTd area hideDash val={unit.sale_terrasse_area} />
                    </div>

                    <div>
                      <p>
                        <span>Garten: </span>

                        <UnitTd val={unit.sale_garten_count} />
                      </p>

                      <UnitTd area hideDash val={unit.sale_garten_area} />
                    </div>

                    <div>
                      <p>
                        <span>Keller: </span>

                        <UnitTd val={unit.sale_keller_count} />
                      </p>

                      <UnitTd area hideDash val={unit.sale_keller_area} />
                    </div>
                  </div>

                </div>

              </div>

              <div className="mt-4">
                <p className="mt-4 whitespace-pre-line text-sm text-gray-500">Käufer:</p>

                {
                  unit.private_buyers && unit.private_buyers.length > 0
                    ? (
                      <div className="my-4 flex flex-col gap-1 rounded border border-gray-300 p-2 text-gray-500">
                        <h4>Privatkäufer:</h4>

                        <ul className="flex flex-wrap text-xs">
                          {
                            unit.private_buyers.map((privateBuyer) => {
                              const {
                                id,
                                birthdate,
                                city,
                                name,
                                notes,
                                surname,
                                title_prefix,
                                title_suffix,
                                zipcode
                              } = privateBuyer;

                              const nameString = `${title_prefix ? `${title_prefix} ` : ""}${name ? `${name} ` : ""}${surname}${title_suffix ? `, ${title_suffix}` : ""}`;

                              const ageString = birthdate && String(dayjs().diff(dayjs(birthdate), "years"));

                              return (
                                <li className="my-1 border-r border-gray-300 px-2 py-1 last:border-none" key={id}>
                                  <p>
                                    <span className="font-medium">{nameString}</span>

                                    {birthdate ? <span> ({ageString})</span> : null}

                                    {(zipcode || city) ? <span>,</span> : null}

                                    {zipcode ? <span> {zipcode}</span> : null}

                                    {city ? <span> {city}</span> : null}
                                  </p>

                                  {notes ? <p className="max-w-prose">{notes}</p> : null}
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    )
                    : null
                }

                {
                  unit.company_buyers && unit.company_buyers.length > 0
                    ? (
                      <div className="my-4 flex flex-col gap-1 rounded border border-gray-300 p-2 text-gray-500">
                        <h4>Firmenkäufer:</h4>

                        <ul className="flex flex-wrap text-xs">
                          {
                            unit.company_buyers.map((companyBuyer) => {
                              const {
                                id,
                                city,
                                name,
                                notes,
                                reg_number,
                                zipcode
                              } = companyBuyer;

                              return (
                                <li className="my-1 border-r border-gray-300 px-2 py-1 last:border-none" key={id}>
                                  <p>
                                    <span className="font-medium">{name}</span>

                                    {reg_number ? <span> ({reg_number})</span> : null}

                                    {(zipcode || city) ? <span>,</span> : null}

                                    {zipcode ? <span> {zipcode}</span> : null}

                                    {city ? <span> {city}</span> : null}
                                  </p>

                                  {notes ? <p className="max-w-prose">{notes}</p> : null}
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    )
                    : null
                }

                <p className="whitespace-pre-line text-sm text-gray-500">Bemerkungen: <br />{unit.sale_notice ? unit.sale_notice : "-"}</p>

                {!automated && <p className="mt-4 whitespace-pre-line text-sm text-gray-500">Interne Notizen: <br /> {unit.internal_sale_notes ? unit.internal_sale_notes : "-"}</p>}

                {!automated && (
                  <div className="mt-4 flex items-center">
                    <p className="text-sm text-gray-500">Dokumente:</p>

                    {showEditActions
                      ? (
                        <button
                          className="bg-primary-green-dark ml-2 block text-white"
                          type="button"
                          onClick={() => showSlide({
                            isOfferDocument: false,
                            unit
                          })}
                        >
                          <PlusIcon className="h-4 w-8" />
                        </button>
                      )
                      : null}
                  </div>
                )}

                <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                  {saleDocuments.map((document) => (
                    <UnitDocumentItem
                      document={document}
                      key={`document_unit_${document.id}`}
                      showDeleteDocument={showDeleteDocument}
                      showEditActions={showEditActions}
                      showEditDocument={showEditDocument}
                    />
                  ))}
                </ul>

              </div>

            </div>
          </div>
        )
        : null}
    </div>
  );
};

export default ProjectUnit;
