/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { dateFormatLabels } from "~/src/modules/labels.jsx";

import { loadLocationOptions } from "../helpers.js";

/**
 *
 * @param unit
 * @example
 */
const generateEditValues = async (unit) => {
  if (unit) {
    const {
      building_part,
      buyable,
      company_buyers,
      floor,
      hide_tz,
      internal_offer_notes,
      internal_sale_notes,
      kv_id,
      kv_url,
      maisonette,
      name,
      offer_area,
      offer_balkon_area,
      offer_balkon_count,
      offer_data_available,
      offer_garten_area,
      offer_garten_count,
      offer_keller_area,
      offer_keller_count,
      offer_loggia_area,
      offer_loggia_count,
      offer_notice,
      offer_price_brutto_investor,
      offer_price_brutto_investor_history,
      offer_price_investor,
      offer_price_investor_history,
      offer_price_normal,
      offer_price_normal_history,
      offer_room_count,
      offer_terrasse_area,
      offer_terrasse_count,
      position,
      position_group,
      position_staircase,
      private_buyers,
      rent_bk,
      rent_bk_history,
      rent_brutto,
      rent_brutto_history,
      rent_netto,
      rent_netto_history,
      rentable,
      sale_area,
      sale_balkon_area,
      sale_balkon_count,
      sale_data_available,
      sale_date,
      sale_garten_area,
      sale_garten_count,
      sale_keller_area,
      sale_keller_count,
      sale_loggia_area,
      sale_loggia_count,
      sale_notice,
      sale_price_gross,
      sale_price_net,
      sale_room_count,
      sale_terrasse_area,
      sale_terrasse_count,
      show,
      show_offer_price_brutto_investor_history,
      show_offer_price_investor_history,
      show_offer_price_normal_history,
      show_rent_bk_history,
      show_rent_brutto_history,
      show_rent_netto_history,
      staircase,
      tz_number,
      tz_year,
      unit_category: uc,
      verwertet,
      verwertet_date,
      verwertet_date_format
    } = unit;

    return {
      buildingPart: {
        id: building_part?.id || "",
        label: building_part?.name ? <p><span className="inline-block w-16 px-1 font-bold text-yellow-500">{Number.parseFloat(building_part?.sorting)}</span>Bauteil: {building_part.name}</p> : "",
        name: building_part?.name || "",
        sorting: building_part?.sorting || ""
      },
      buyable: buyable || false,
      name: name || "",
      offerDataAvailable: offer_data_available || false,
      rentable: rentable || false,
      saleDataAvailable: sale_data_available || false,
      show: show || false,
      unit_category: {
        id: uc?.id || "",
        code: uc?.code || "",
        label: uc?.name || "",
        name: uc?.name || ""
      },
      verwertet: verwertet || false,
      verwertetDate: verwertet_date ? new Date(verwertet_date) : "",
      verwertetDateFormat: {
        label: verwertet_date_format ? dateFormatLabels.get(verwertet_date_format) : dateFormatLabels.get("day"),
        value: verwertet_date_format || "day"
      },

      hideTz: hide_tz,
      kvId: kv_id || "",
      kvUrl: kv_url || "",
      saleDate: sale_date ? new Date(sale_date) : "",
      salePriceGross: sale_price_gross || "",
      salePriceNet: sale_price_net || "",
      tzNumber: tz_number || "",
      tzYear: tz_year ? new Date(String(tz_year)) : "",

      offerPriceBruttoInvestor: offer_price_brutto_investor || "",
      offerPriceBruttoInvestorHistory: offer_price_brutto_investor_history?.length > 0
        ? offer_price_brutto_investor_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      offerPriceInvestor: offer_price_investor || "",
      offerPriceInvestorHistory: offer_price_investor_history?.length > 0
        ? offer_price_investor_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      offerPriceNormal: offer_price_normal || "",
      offerPriceNormalHistory: offer_price_normal_history?.length > 0
        ? offer_price_normal_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      showOfferPriceBruttoInvestorHistory: show_offer_price_brutto_investor_history || false,
      showOfferPriceInvestorHistory: show_offer_price_investor_history || false,
      showOfferPriceNormalHistory: show_offer_price_normal_history || false,

      rentBk: rent_bk || "",
      rentBkHistory: rent_bk_history?.length > 0
        ? rent_bk_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      rentBrutto: rent_brutto || "",
      rentBruttoHistory: rent_brutto_history?.length > 0
        ? rent_brutto_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      rentNetto: rent_netto || "",
      rentNettoHistory: rent_netto_history?.length > 0
        ? rent_netto_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      showRentBkHistory: show_rent_bk_history || false,
      showRentBruttoHistory: show_rent_brutto_history || false,
      showRentNettoHistory: show_rent_netto_history || false,

      privateBuyers: private_buyers?.length > 0
        ? await Promise.all(
          private_buyers.map(async (
            {
              id,
              birthdate,
              city,
              country,
              house_number: houseNumber,
              lat,
              lng,
              name: buyerName,
              notes,
              show: buyerShow,
              street,
              surname,
              title_prefix: titlePrefix,
              title_suffix: titleSuffix,
              zipcode
            },
            index
          ) => ({
            birthdate: birthdate ? new Date(birthdate) : null,
            city: city || "",
            coordinates: lat && lng
              ? {
                label: (await loadLocationOptions(`${lng},${lat}`))?.[0]?.label,
                lat,
                lng
              }
              : null,
            country: country?.id
              ? {
                id: country.id,
                code: country.code,
                label: country.name,
                name: country.name
              }
              : null,
            houseNumber: houseNumber || "",
            name: buyerName || "",
            notes: notes || "",
            show: buyerShow ?? true,
            street: street || "",
            surname: surname || "",
            titlePrefix: titlePrefix || "",
            titleSuffix: titleSuffix || "",
            zipcode: zipcode || ""
          }))
        )
        : [],

      companyBuyers: company_buyers?.length > 0
        ? await Promise.all(
          company_buyers.map(async ({
            city,
            country,
            house_number: houseNumber,
            is_zvr: isZvr,
            lat,
            lng,
            name: buyerName,
            no_reg_number: noRegNumber,
            notes,
            reg_number: regNumber,
            show: buyerShow,
            street,
            zipcode
          }) => ({
            city: city || "",
            coordinates: lat && lng
              ? {
                label: (await loadLocationOptions(`${lng},${lat}`))?.[0]?.label,
                lat,
                lng
              }
              : null,
            country: country?.id
              ? {
                id: country.id,
                code: country.code,
                label: country.name,
                name: country.name
              }
              : null,
            houseNumber: houseNumber || "",
            isZvr: isZvr || false,
            name: buyerName || "",
            noRegNumber: noRegNumber || false,
            notes: notes || "",
            regNumber: regNumber || "",
            show: buyerShow ?? true,
            street: street || "",
            zipcode: zipcode || ""
          }))
        )
        : [],

      floor: floor || "",
      maisonette: maisonette || false,
      position: position ? Number.parseFloat(position) : "",
      positionGroup: position_group || "",
      positionStaircase: position_staircase ? Number.parseFloat(position_staircase) : "",
      staircase: staircase || "",

      offerArea: offer_area || "",
      saleArea: sale_area || "",

      offerRoomCount: offer_room_count || "",
      saleRoomCount: sale_room_count || "",

      offerLoggiaArea: offer_loggia_area || "",
      offerLoggiaCount: offer_loggia_count || "",
      saleLoggiaArea: sale_loggia_area || "",
      saleLoggiaCount: sale_loggia_count || "",

      offerBalkonArea: offer_balkon_area || "",
      offerBalkonCount: offer_balkon_count || "",
      saleBalkonArea: sale_balkon_area || "",
      saleBalkonCount: sale_balkon_count || "",

      offerGartenArea: offer_garten_area || "",
      offerGartenCount: offer_garten_count || "",
      saleGartenArea: sale_garten_area || "",
      saleGartenCount: sale_garten_count || "",

      offerTerrasseArea: offer_terrasse_area || "",
      offerTerrasseCount: offer_terrasse_count || "",
      saleTerrasseArea: sale_terrasse_area || "",
      saleTerrasseCount: sale_terrasse_count || "",

      offerKellerArea: offer_keller_area || "",
      offerKellerCount: offer_keller_count || "",
      saleKellerArea: sale_keller_area || "",
      saleKellerCount: sale_keller_count || "",

      internalOfferNotes: internal_offer_notes || "",
      internalSaleNotes: internal_sale_notes || "",
      offerNotice: offer_notice || "",
      saleNotice: sale_notice || ""
    };
  }

  return initialValues;
};

export default generateEditValues;
