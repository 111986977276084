/**
 *
 * @param props0 - The root object
 * @param props0.color - The root object
 * @param props0.size - The root object
 * @example
 */
const CloseIcon = ({ color, size = "h-6 w-6" }) => (
  <svg className={`${size} ${color}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
  </svg>
);

export default CloseIcon;
