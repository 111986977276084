/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.children - The root object
 * @param props0.className - The root object
 * @param props0.label - The root object
 * @example
 */
const MultiField = ({
  id, children, className = "", label
}) => (
  <div className={`space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0 ${className}`}>
    <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-x-4 sm:space-y-0 sm:px-6 sm:py-5">
      <div>
        <label className="inline-block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor={id}>
          {label}
        </label>
      </div>

      <div className="grid grid-cols-2 gap-4 sm:col-span-2">
        {children}
      </div>
    </div>
  </div>
);

export default MultiField;
