/**
 *
 * @param props0 - The root object
 * @param props0.title - The root object
 * @example
 */
const FormTitle = ({ title }) => (
  <dt className="font-medium text-gray-700 sm:col-span-2">
    {title}
  </dt>
);

export default FormTitle;
