/**
 *
 * @param props0 - The root object
 * @param props0.isLoading - The root object
 * @param props0.loadedProject - The root object
 * @param props0.state - The root object
 * @example
 */
const ProjectLocations = ({
  isLoading, loadedProject, state
}) => {
  let project;

  project = isLoading && state?.project ? state.project : loadedProject;

  if (project && project.locations) {
    return project.locations.map((location) => (
      <div className="flex items-center" key={`location_${location.id}`}>
        <p className="text-gray-500">
          {location.street.city?.state.code.toLocaleUpperCase()}, {location.street.city.zipcode} {location.street.city.name}, {location.street.name} {location.housenumber}
        </p>
      </div>
    ));
  }

  return null;
};

export default ProjectLocations;
