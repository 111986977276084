import dayjs from "dayjs";
import Joi from "joi";

const schemaBulkEdit =
  Joi.object({
    buyable: Joi.boolean().optional(),
    offerDataAvailable: Joi.boolean().optional(),
    rentable: Joi.boolean().optional(),
    show: Joi.boolean().optional(),
    verwertet: Joi.boolean().optional(),
    verwertetDate: Joi.date()
      .empty(null).allow("")
      .optional()
      .when("offerDataAvailable", {
        is: true,
        then: Joi.when("verwertet", {
          is: true,
          then: Joi.date()
            .max(Joi.ref("saleDate", {
              adjust: (saleDate) => (saleDate
                ? dayjs(saleDate).subtract(1, "month").add(1, "day")
                  .toDate()
                : dayjs().add(1_000, "years").toDate())
            }))
            .error((errors) => {
              const error = errors.find(({ code }) => code === "date.max");

              error.code = "date.max.verwertet";

              return error;
            })
        })
      }),

    verwertetDateFormat: Joi
      .when("verwertet", {
        is: true,
        otherwise: Joi.object().optional(),
        then: Joi.object({
          label: Joi.string().empty(""),
          value: Joi.string().empty("")
        })
      }),

    offerPriceBruttoInvestor: Joi.string().empty("").optional(),
    offerPriceBruttoInvestorHistory: Joi.any().optional(),
    offerPriceInvestor: Joi.string().empty("").optional(),
    offerPriceInvestorHistory: Joi.any().optional(),
    offerPriceNormal: Joi.string().empty("").optional(),
    offerPriceNormalHistory: Joi.any().optional(),
    showOfferPriceBruttoInvestorHistory: Joi.boolean().optional(),
    showOfferPriceInvestorHistory: Joi.boolean().optional(),
    showOfferPriceNormalHistory: Joi.boolean().optional(),

    rentBk: Joi.string().empty("").optional(),
    rentBkHistory: Joi.any().optional(),
    rentBrutto: Joi.string().empty("").optional(),
    rentBruttoHistory: Joi.any().optional(),
    rentNetto: Joi.string().empty("").optional(),
    rentNettoHistory: Joi.any().optional(),
    showRentBkHistory: Joi.boolean().optional(),
    showRentBruttoHistory: Joi.boolean().optional(),
    showRentNettoHistory: Joi.boolean().optional(),

    offerArea: Joi.string().empty("").optional(),

    offerRoomCount: Joi.number().positive().empty("")
      .optional(),

    offerLoggiaArea: Joi.string().empty("").optional(),
    offerLoggiaCount: Joi.number().positive().empty("")
      .optional(),

    offerBalkonArea: Joi.string().empty("").optional(),
    offerBalkonCount: Joi.number().positive().empty("")
      .optional(),

    offerGartenArea: Joi.string().empty("").optional(),
    offerGartenCount: Joi.number().positive().empty("")
      .optional(),

    offerTerrasseArea: Joi.string().empty("").optional(),
    offerTerrasseCount: Joi.number().positive().empty("")
      .optional(),

    offerKellerArea: Joi.string().empty("").optional(),
    offerKellerCount: Joi.number().positive().empty("")
      .optional(),

    internalOfferNotes: Joi.string().empty("").optional(),
    offerNotice: Joi.string().empty("").optional()
  });

export default schemaBulkEdit;
