import { XIcon } from "lucide-react";

import useForm from "~/src/hooks/use-form.js";
import useNotification from "~/src/hooks/use-notification.js";

import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import InlineInput from "~/src/ui/forms/inline-input/index.jsx";
import NumberInput from "~/src/ui/forms/number-input/index.jsx";
import Modal from "~/src/ui/modals/index.jsx";

import { handleCreate } from "./handlers.js";
import { initialValues, schema } from "./schema.js";

const NewBauteilModal = ({
  hide, mutateProject, projectId, visible
}) => {
  const { showError } = useNotification();

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleCreate({
        data: form.values,
        projectId,

        hide: () => {
          handleHide(form);
        },
        mutate: mutateProject,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  return (
    <Modal
      show={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <div className="inline-block w-full overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-xl sm:p-6 sm:align-middle" role="dialog">

        <div className="flex justify-between">
          <h3 className="text-lg font-medium">Bauteil hinzufügen</h3>

          <button
            className="focus:outline-none"
            type="button"
            onClick={() => {
              handleHide(form);
            }}
          >
            <XIcon className="size-5" />
          </button>
        </div>

        <form autoComplete="off" onSubmit={form.handleSubmit}>
          <div className="mt-6 space-y-6">

            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-700" htmlFor="name">
                Name
              </label>

              <div className="mt-1">
                <InlineInput
                  autoFocus
                  error={form.errors.name}
                  id="name"
                  isSubmitting={form.isSubmitting}
                  name="name"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Name des Bauteils"
                  touched={form.touched.name}
                  value={form.values.name}
                />
              </div>
            </div>

            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-700" htmlFor="sorting">
                Position
              </label>

              <div className="mt-1">
                <NumberInput
                  noprefix
                  error={form.errors.sorting}
                  id="sorting"
                  int={false}
                  isSubmitting={form.isSubmitting}
                  name="sorting"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Position"
                  scale="none"
                  touched={form.touched.sorting}
                  value={Number.parseFloat(form.values.sorting)}
                />
              </div>
            </div>

          </div>

          <div className="mt-5 sm:mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <FormSubmitButton className="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 sm:col-start-2 sm:text-sm" isSubmitting={form.isLoading}>
              Speichern
            </FormSubmitButton>

            <button
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
              type="button"
              onClick={() => {
                handleHide(form);
              }}
            >
              abbrechen
            </button>
          </div>

        </form>

      </div>
    </Modal>
  );
};

export default NewBauteilModal;
