import { camelCase, capitalize } from "lodash-es";

import {
  areaActivity,
  collectionActivity,
  customActivity,
  dateActivity,
  dateFormatActivity,
  hiddenActivity,
  normalActivity,
  priceActivity,
  yearActivity
} from "~/src/modules/format-activity-change/helpers.js";
import { statisticsLabels } from "~/src/modules/labels.jsx";

import unitWrapper from "../helpers/unit-wrapper.js";

export default {
  ...Object.fromEntries(
    [
      "id",
      "automated_project_id",
      "building_part_id",
      "unit_category_id",
      "unit_category_code",
      "buyer",
      "show_buyer",
      "documents",
      "last_editor_id"
    ].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["show", "Objekt im Produkt anzeigen"],
      ["building_part", "Interne Sortierung - Bauteil"],
      ["position_staircase", "Interne Sortierung - Stiege"],
      ["position", "Interne Sortierung - Nummer"],
      ["unit_category", "Objektkategorie"],
      ["name", "Name"],
      ["staircase", "Stiege"],
      ["floor", "Geschoß"],
      ["maisonette", "Maisonettewohnung"]
    ].map(([key, label]) => [key, normalActivity(label)])
  ),

  ...Object.fromEntries(
    [
      [
        "verwertet",
        "Objekt verwertet",
        normalActivity
      ],

      [
        "rentable",
        "Mietpreise vorhanden",
        normalActivity
      ],
      ...[
        "netto",
        "brutto",
        "bk"
      ]
        .flatMap((priceType) => {
          const prefix = "rent";

          const part = `${prefix}_${priceType}`;

          const label = statisticsLabels.get(camelCase(part));

          return [
            [
              part,
              label,
              priceActivity
            ],
            [
              `show_${part}_history`,
              <span>{label} - historische Preise</span>,
              normalActivity
            ],
            [
              `${part}_history`,
              <span>{label} (historisch)</span>,
              (label) => collectionActivity({
                innerLabels: [
                  [
                    "date",
                    "Datum bis",
                    dateActivity
                  ],
                  [
                    "price",
                    "Preis",
                    priceActivity
                  ]
                ],
                label
              })
            ]
          ];
        }),

      [
        "buyable",
        "Kaufpreise vorhanden",
        normalActivity
      ],
      ...[
        "normal",
        "investor",
        "brutto_investor"
      ]
        .flatMap((priceType) => {
          const prefix = "offer_price";

          const part = `${prefix}_${priceType}`;

          const label = statisticsLabels.get(camelCase(part));

          return [
            [
              part,
              label,
              priceActivity
            ],
            [
              `show_${part}_history`,
              <span>{label} - historische Preise</span>,
              normalActivity
            ],
            [
              `${part}_history`,
              <span>{label} (historisch)</span>,
              (label) => collectionActivity({
                innerLabels: [
                  [
                    "date",
                    "Datum bis",
                    dateActivity
                  ],
                  [
                    "price",
                    "Preis",
                    priceActivity
                  ]
                ],
                label
              })
            ]
          ];
        })
    ].map(([
      key,
      label,
      activityFunction
    ]) => [key, unitWrapper(activityFunction(label), "offer")])
  ),

  ...Object.fromEntries(
    [
      [
        "kv_id",
        "KV-Detail ID",
        normalActivity
      ],
      [
        "tz_number",
        "TZ",
        normalActivity
      ],
      [
        "hide_tz",
        "TZ ausblenden (außerbücherlicher Eigentümer)",
        normalActivity
      ],
      [
        "sale_date",
        "KV-Datum",
        dateActivity
      ],
      [
        "sale_price_net",
        "Verkaufspreis exkl. USt",
        priceActivity
      ],
      [
        "sale_price_gross",
        "Verkaufspreis inkl. USt",
        priceActivity
      ],
      [
        "private_buyers",
        "Privatkäufer",
        (label) => collectionActivity({
          innerLabels: [
            [
              "birthdate",
              "Geburtsdatum",
              dateActivity
            ],
            ["city", "Stadt"],
            [
              "country",
              "Land",
              (label) => customActivity({
                label,
                value: (value) => value?.name
              })
            ],
            ["house_number", "Hausnummer"],
            ["name", "Vorname"],
            ["surname", "Nachname"],
            ["notes", "Notizen"],
            ["show", "Käufer anzeigen"],
            ["street", "Straße"],
            ["title_prefix", "Titel-Präfix"],
            ["title_suffix", "Titel-Suffix"],
            ["zipcode", "Postleitzahl"]
          ],
          label
        })
      ],
      [
        "company_buyers",
        "Firmenkäufer",
        (label) => collectionActivity({
          innerLabels: [
            ["city", "Stadt"],
            [
              "country",
              "Land",
              (label) => customActivity({
                label,
                value: (value) => value?.name
              })
            ],
            ["house_number", "Hausnummer"],
            ["is_zvr", "Verein"],
            ["name", "Name"],
            ["no_reg_number", "Keine FB- oder ZVR-Nr."],
            ["notes", "Notizen"],
            ["reg_number", "FB- oder ZVR-Nr."],
            ["show", "Käufer anzeigen"],
            ["street", "Straße"],
            ["zipcode", "Postleitzahl"]
          ],
          label
        })
      ]
    ].map(([
      key,
      label,
      activityFunction
    ]) => [key, unitWrapper(activityFunction(label), "sale")])
  ),

  ...Object.fromEntries(
    [["date", dateActivity], ["date_format", dateFormatActivity]].map(([type, activityFunction]) => [`verwertet_${type}`, unitWrapper(activityFunction(`verwertet (${type === "date" ? "Datum" : "Format"})`), "offer")])
  ),

  ...Object.fromEntries(
    ["offer", "sale"]
      .flatMap((category) => {
        const categoryName = category === "offer" ? "Angebot" : "Verkauf";

        return [
          [`${category}_data_available`, normalActivity(`${categoryName}sdaten vorhanden`)],
          [`${category}_area`, areaActivity("Fläche")],
          [`${category}_room_count`, normalActivity("Zimmer")],
          [`${category}_notice`, normalActivity("Sonstige Bemerkungen")],
          [`internal_${category}_notes`, normalActivity("Interne Notizen")],
          ...[
            "loggia",
            "balkon",
            "garten",
            "terrasse",
            "keller"
          ]
            .flatMap((key) => [[`${category}_${key}_count`, normalActivity(`${capitalize(key)} Anzahl`)], [`${category}_${key}_area`, areaActivity(`${capitalize(key)} Fläche`)]])
        ]
          .map(([key, activity]) => [key, unitWrapper(activity, category)]);
      })
  ),

  tz_year: unitWrapper(yearActivity("TZ Jahr"), "sale")
};
