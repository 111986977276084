const selectByKeys = (object, keys) => {
  if (typeof (object) !== "object" || object === null) {
    return;
  }
  const selection = {};

  for (const key of keys) {
    if (key in object) {
      selection[key] = object[key];
    }
  }

  return selection;
};

const removeFromArrayByIndex = (array, indexToRemove) => (
  array.filter(
    (x, index) => index !== indexToRemove
  )
);

export {
  removeFromArrayByIndex,
  selectByKeys
};
