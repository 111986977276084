/**
 *
 * @param list
 * @param property
 * @example
 */
const groupBy = (list = [], property = null) => list.reduce((accumulator, item) => {
  const key = item[property];

  if (!accumulator[key]) {
    accumulator[key] = [];
  }
  accumulator[key].push(item);

  return accumulator;
}, {});

export default groupBy;
