import { formatArea } from "~/src/modules/formatters.new.js";

import customActivity from "./custom-activity.js";

const areaActivity = (label) => customActivity({
  label,
  value: formatArea
});

export default areaActivity;
