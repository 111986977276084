import cn from "classnames";

import CheckboxInput from "~/src/ui/forms/checkbox-input/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.innerClassName - The root object
 * @param props0.label - The root object
 * @param props0.name - The root object
 * @param props0.outerPrefix - The root object
 * @param props0.outerSuffix - The root object
 * @param props0.prefix - The root object
 * @param props0.register - The root object
 * @param props0.suffix - The root object
 * @param props0.compact - The root object
 * @param props0.sameHeightAsTextField - The root object
 * @example
 */
const CheckboxField = ({
  autoFocus = false,
  className = "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
  compact = false,
  disabled = false,
  errors,
  innerClassName = "space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-x-4 sm:px-6 sm:py-5",
  label,
  name,
  outerPrefix,
  outerSuffix,
  prefix,
  register,
  suffix,

  id = name
}) => (
  <div className={className}>
    <div className={innerClassName}>
      <div
        className={cn(
          {
            "col-span-1": !compact,
            "col-span-2": compact
          }
        )}
      >
        <label
          htmlFor={id}
          className={cn("inline-block text-sm font-medium text-gray-900 cursor-pointer", {
            "cursor-default": disabled,
            "cursor-pointer": !disabled
          })}
        >
          {label}
        </label>
      </div>

      {outerPrefix}

      <div
        className={cn(
          "grid grid-cols-3",
          {
            "col-span-1": compact,
            "col-span-2": !compact
          }
        )}
      >
        {prefix}

        <CheckboxInput
          {...{
            id,
            autoFocus,
            disabled,
            errors,
            label,
            name,
            register
          }}
          className={cn("w-full flex items-center", {
            "col-span-2": prefix,
            "col-span-3": !prefix
          })}
        />

        {suffix}
      </div>

      {outerSuffix}
    </div>
  </div>
);

export default CheckboxField;
