import { useParams } from "react-router-dom";

import { fundingLabels } from "~/src/modules/labels.jsx";

import useAuth from "~/src/hooks/use-auth.js";
import useEdit from "~/src/hooks/use-edit.js";
import useModal from "~/src/hooks/use-modal.js";
import useProject from "~/src/hooks/use-project.js";

import SubmitButton from "~/src/ui/buttons/submit-button/index.jsx";
import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import EditIcon from "~/src/ui/icons/edit-icon.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

import FundingSlide from "./funding-slide.jsx";

/**
 *
 * @example
 */
const ProjectFunding = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  const normalFundedSubCategories = [
    "super_funded",
    "smart_funded",
    "smart_super_funded",
    "wiener_wohnbau"
  ]
    .filter((key) => project?.funding?.[key])
    .map((key) => (
      <div key={key}>
        <p className="text-sm font-medium text-gray-700">{fundingLabels.get(key)}</p>

        <p>{project?.funding?.[`${key}_total`] ? `${project?.funding?.[`${key}_total`]} Wohnung(en)` : "unbekannte Anzahl"}</p>
      </div>
    ));

  return (
    <TabListContainer>
      <FundingSlide hide={hideSlide} project={slideData} projectId={id} visible={slideVisible} />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">
        <DescriptionTitle title="Förderungen" />

        <DescriptionContainer>
          <div className="flex flex-col gap-4 ">
            {project?.funding?.not_funded
              ? (
                <div className="bg-gray-100 p-2">
                  <p className="text-sm font-medium text-gray-700">{fundingLabels.get("not_funded")}</p>

                  <p>{project?.funding?.not_funded_total ? `${project?.funding?.not_funded_total} Wohnung(en)` : "unbekannte Anzahl"}</p>
                </div>
              )
              : null}

            {project?.funding?.normal_funded
              ? (
                <div className="bg-gray-100 p-2">
                  <p className="text-sm font-medium text-gray-700">{fundingLabels.get("normal_funded")}</p>

                  <p>{project?.funding?.normal_funded_total ? `${project?.funding?.normal_funded_total} Wohnung(en)` : "unbekannte Anzahl"}</p>

                  {
                    normalFundedSubCategories.length > 0 && (
                      <div className="mt-2 flex flex-col gap-2 bg-gray-200 p-2">
                        <span className="text-sm font-medium text-gray-700">davon:</span>

                        {normalFundedSubCategories}
                      </div>
                    )
                  }
                </div>
              )
              : null}

            {project?.funding?.gemeinde
              ? (
                <div className="bg-gray-100 p-2">
                  <p className="text-sm font-medium text-gray-700">{fundingLabels.get("gemeinde")}</p>

                  <p>{project?.funding?.gemeinde_total ? `${project?.funding?.gemeinde_total} Wohnung(en)` : "unbekannte Anzahl"}</p>
                </div>
              )
              : null}
          </div>
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showSlide(project)}>
                  <EditIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>
    </TabListContainer>
  );
};

export default ProjectFunding;
