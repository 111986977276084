import dateFormatter from "~/src/modules/date-formatter.js";

import customActivity from "./custom-activity.js";

const yearActivity = (label) => customActivity({
  label,
  value: (value) => dateFormatter(value, "year")
});

export default yearActivity;
