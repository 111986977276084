import Handler from "~/src/modules/handler.js";

const {
  handleUpdate
} = new Handler({
  endpoint: "/projects/:id/unit-notes",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, {
    hide, mutate
  }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (unitNotes) => ({
    project: {
      unit_notes: unitNotes
    }
  })
});

export {
  handleUpdate
};
