import { useEffect, useMemo } from "react";
import ReactDOM from "react-dom";

const NotificationContainer = ({ children }) => {
  // Find our portal container in the DOM
  const portalRoot = document.querySelector("#notification-root");

  /*
     Create a div as a wrapper for our toast
     using the useMemo hook so that a new value isn't
     computed on every render
  */
  const container = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
  /*
     Append our toast container to the portal root
  */
    portalRoot.append(container);

    /**
     *
     *Clean up the DOM by removing our toast container
     *when the component is unmounted
     *
     * @example
     */
    return () => {
      container.remove();
    };
  });

  /*
     Render any child elements to the portal root
  */
  return ReactDOM.createPortal(children, portalRoot);
};

export default NotificationContainer;
