/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
const DescriptionContainer = ({ children }) => (
  <dd className="mt-2 text-sm text-gray-500 sm:col-span-5 md:mt-0">
    {children}
  </dd>
);

export default DescriptionContainer;
