import {
  areaActivity,
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers.js";

export default {
  ...Object.fromEntries(
    [
      "created_at",
      "deleted_at",
      "updated_at",
      "id",
      "project_id"
    ].map(
      (key) => [key, hiddenActivity]
    )
  ),

  ...Object.fromEntries(
    [
      ["dg_ausbau", "DG-Ausbau"],
      ["fremdbestand", "Fremdbestand"],
      ["neubau", "Neubau"],
      ["sanierung", "Sanierung Altbestand"],
      ["unsaniert", "unsaniert"]
    ]
      .flatMap(([key, label]) => [
        [key, normalActivity(label)],
        [`${key}_area_range_from`, areaActivity(`${label} - Fläche (Wohnungen)`)],
        [`${key}_area_range_to`, areaActivity(`${label} - Fläche bis (Wohnungen)`)],
        [`${key}_area_total`, areaActivity(`${label} - Gesamtfläche`)],
        [`${key}_unit_area_total`, areaActivity(`${label} - Wohnfläche`)],
        [`${key}_count`, areaActivity(`${label} - Anzahl Wohnungen`)]
      ])
  )
};
