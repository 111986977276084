import { EyeOffIcon, PencilIcon } from "lucide-react";

import { onClickShowModal } from "../../controller.js";

const DetailButton = ({
  issuer, modal, reactForm
}) => (
  <button
    {...{
      className: "font-medium text-gray-500 underline hover:text-gray-600 focus:outline-none flex items-center",
      onClick: onClickShowModal.bind(null, {
        issuer,
        modal,
        reactForm,
        readOnly: true
      }),
      type: "button"
    }}
  >

    {!issuer.searchable &&
      <EyeOffIcon className="mr-2 size-5 text-red-500 hover:text-red-400" />}

    {issuer.name}

  </button>
);

const EditButton = ({
  issuer, issuersTempFrontEndValidation, modal, reactForm
}) => (
  <button
    className="focus:outline-none"
    type="button"
    onClick={onClickShowModal.bind(null, {
      issuer,
      issuersTempFrontEndValidation,
      modal,
      reactForm
    })}
  >

    <PencilIcon className="size-5 text-gray-500 hover:text-gray-600" />

  </button>
);

export { DetailButton, EditButton };
