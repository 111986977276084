import { Trash2Icon } from "lucide-react";

/**
 *
 * @param props0 - The root object
 * @param props0.className - The root object
 * @example
 */
const DeleteIcon = ({ className }) => (
  <Trash2Icon className={className} />
);

export default DeleteIcon;
