import dayjs from "dayjs";
import "dayjs/locale/de";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(quarterOfYear);
dayjs.locale("de");

/**
 *
 * @param date
 * @param formatReceived
 * @example
 */
const dateFormatter = (date, formatReceived = "day") => {
  const format = (formatReceived.value) ? formatReceived.value : formatReceived;

  if (!date) {
    return null;
  }

  if (format === "day" || !format) {
    return dayjs(date).format("DD.MM.YYYY");
  }

  if (format === "month-short") {
    return dayjs(date).format("MM.YY");
  }

  if (format === "month") {
    return dayjs(date).format("MMMM YYYY");
  }

  if (format === "quarter") {
    const quarter = dayjs(date).quarter();

    return `Q${quarter}-${dayjs(date).format("YYYY")}`;
  }

  if (format === "year") {
    return dayjs(date).format("YYYY");
  }

  return null;
};

export default dateFormatter;
