import dateFormatter from "~/src/modules/date-formatter.js";
import { constructionPhaseLabels } from "~/src/modules/labels.jsx";

import DescriptionContainer from "~/src/ui/containers/description-container/index.jsx";
import TabListContainer from "~/src/ui/containers/tab-list-container/index.jsx";
import DescriptionTitle from "~/src/ui/titles/description-title/index.jsx";

/**
 *
 * @param props0 - The root object
 * @param props0.project - The root object
 * @example
 */
const ConstructionTimeline = ({ project }) => (

  <TabListContainer>

    <div className="sm:grid sm:grid-cols-8 sm:gap-4">

      <DescriptionTitle title="Bau-Timeline" />

      <DescriptionContainer>
        <div className="grid grid-cols-4 gap-x-2 gap-y-4 bg-gray-100 p-2">

          <div className="col-span-4 border-b border-gray-300 pb-2">
            <p className="text-base font-medium text-gray-700">Aktueller Baustatus</p>

            <p className="text-base">
              {project?.timeline?.current_construction_phase ? constructionPhaseLabels[project?.timeline?.current_construction_phase] : "-"}
            </p>
          </div>

          <div className="col-span-2">
            <p className="text-sm font-medium text-gray-700">Liegenschaft/Baurecht erworben</p>

            <p>
              {project?.timeline?.construction_phase_purchase_date ? dateFormatter(project?.timeline?.construction_phase_purchase_date, project?.timeline?.construction_phase_purchase_date_format) : "-"}
            </p>
          </div>

          <div className="col-span-2">
            <p className="text-sm font-medium text-gray-700">In Bau</p>

            <p>
              {project?.timeline?.construction_phase_active_date ? dateFormatter(project?.timeline?.construction_phase_active_date, project?.timeline?.construction_phase_active_date_format) : "-"}
            </p>
          </div>

          <div className="col-span-2">
            <p className="text-sm font-medium text-gray-700">Projektbau abgeschlossen</p>

            <p>
              {project?.timeline?.construction_phase_completed_date ? dateFormatter(project?.timeline?.construction_phase_completed_date, project?.timeline?.construction_phase_completed_date_format) : "-"}
            </p>
          </div>
        </div>
      </DescriptionContainer>

    </div>
  </TabListContainer>
);

export default ConstructionTimeline;
