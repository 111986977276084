import Handler from "~/src/modules/handler.js";

const { handleUpdate } = new Handler({
  endpoint: "/building-parts/:id",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (data) => ({
    part: {
      name: data.name,
      sorting: data.sorting
    }
  })
});

export {
  handleUpdate
};
