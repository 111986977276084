import * as cn from "classnames";

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @param props0.className - The root object
 * @example
 */
const Content = ({ children, className }) => (
  <main className={cn("mx-auto max-w-7xl sm:px-4 lg:px-8", className)}>
    {children}
  </main>
);

export default Content;
