import { validDate } from "~/src/modules/formatters.js";
import Handler from "~/src/modules/handler.js";

const generateTransactionObject = (values) => {
  const isBaurecht = values.transactionCategory?.code === "baurecht";

  const transaction = {
    build_area: values.buildArea || null,
    buyer_private: values.buyerPrivate,
    green_area: values.greenArea || null,
    gst_numbers: values.gstNumbers,
    historic: values.historic || false,
    katastralgemeinde: {
      id: values.katastralgemeinde.id
    },
    kv_id: values.kvId || null,
    kv_url: values.kvUrl || null,
    notes: values.notes || null,
    property_area: values.propertyArea,
    seller_private: values.sellerPrivate,
    sorting: values.sorting || null,
    transaction_category: {
      id: values.transactionCategory.id
    },
    transaction_date: validDate(values.transactionDate) || null,
    tz_number: values.tzNumber,
    tz_year: validDate(values.tzYear),
    usable: values.usable || false,
    usable_area: values.usableArea || null
  };

  transaction.seller_id = values.sellerPrivate ? null : values.seller.id;
  transaction.buyer_id = values.buyerPrivate ? null : values.buyer.id;

  if (isBaurecht) {
    transaction.brez = values.brez;
    transaction.br_stamm_ez = values.br_stamm_ez || null;
    transaction.baurechtsdauer = values.baurechtsdauer || null;
    transaction.baurechtsdauer_date = validDate(values.baurechtsdauer_date) || null;
    transaction.bauzins_net = values.bauzins_net || null;
    transaction.bauzins_gross = values.bauzins_gross || null;
    transaction.ez_numbers = [""];

    transaction.sale_price_net = null;
    transaction.sale_price_gross = null;
    transaction.shares = null;
  }
  else {
    transaction.ez_numbers = values.ezNumbers;
    transaction.sale_price_net = values.salePriceNet;
    transaction.sale_price_gross = values.salePriceGross || null;
    transaction.shares = values.shares;

    transaction.brez = null;
    transaction.br_stamm_ez = null;
    transaction.baurechtsdauer = null;
    transaction.baurechtsdauer_date = null;
    transaction.bauzins_net = null;
    transaction.bauzins_gross = null;
  }

  return transaction;
};

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/transactions/:id",

  afterEvery: ({ setIsSubmitting }) => {
    setIsSubmitting(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformCreateData: (data, { projectId }) => ({
    transaction: {
      ...generateTransactionObject(data),
      project: {
        id: projectId
      }
    }
  }),
  transformUpdateData: (data) => ({
    transaction: generateTransactionObject(data)
  })
});

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
