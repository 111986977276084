import { useState } from "react";

import { selectByKeys } from "~/src/_common/_exports.js";

import CancelButton from "~/src/ui/buttons/cancel-button/index.jsx";
import BottomClose from "~/src/ui/buttons/modal/bottom-close.jsx";
import AreaField from "~/src/ui/forms/area-field/index.jsx";
import Field from "~/src/ui/forms/field/index.jsx";
import FormSubmitButton from "~/src/ui/forms/form-submit-button/index.jsx";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox/index.new.jsx";
import NumberField from "~/src/ui/forms/number-field/index.jsx";

import {
  handleClose, onInvalidSubmit, onValidSubmit
} from "./controller.js";
import CertificateList from "./issuer-certificate-list.jsx";

/**
 *
 * @param root0 - The root object
 * @param root0.reactForm - The root object
 * @param root0.modal - The root object
 * @param root0.mutateIssuers - The root object
 * @param root0.issuerCertificatesCache - The root object
 * @param root0.mutateCertificates - The root object
 * @example
 */
const IssuerForm = ({
  issuerCertificatesCache, modal, mutateCertificates, mutateIssuers, reactForm
}) => {
  const [isDuringSubmit, setIsDuringSubmit] = useState(false);

  const hasPointsChecked = reactForm.watch("has_points");

  const isDetailsView = modal?.data?.readOnly;
  const isEditForm = modal?.data?.id;

  const [isSearchable, hasPoints] = (
    reactForm.getValues(["searchable", "has_points"])
  );

  const headerString = (
    isDetailsView
      ? "Ausstellerdetails"
      : (isEditForm
        ? "Aussteller"
        : "Neuen Aussteller hinzufügen")
  );

  const items = [];

  const commonFieldProperties = {
    errors: reactForm.formState.errors,
    isDetailsView,
    register: reactForm.register
  };

  if (isDetailsView) {
    items.push(
      <div className="mt-6">
        <p className="text-gray-700">Aussteller suchbar</p>

        {isSearchable
          ? <p>ja</p>
          : <p className="text-red-500">nein</p>}
      </div>
    );
  }
  else {
    items.push(
      <div className="mt-6">
        <InlineCheckbox
          {...{
            label: "Aussteller anzeigen",
            name: "searchable",
            ...commonFieldProperties
          }}
        />
      </div>
    );
  }

  items.push(
    <div className="mt-6">
      <Field
        {...{
          inline: true,
          label: "Ausstellername",
          name: "name",
          ...commonFieldProperties
        }}
      />
    </div>
  );

  if (!isDetailsView) {
    items.push(
      <div className="mt-6">
        <InlineCheckbox
          {...{
            label: "Zertifikate vorhanden",
            name: "has_certificates",
            ...commonFieldProperties
          }}
        />
      </div>
    );
  }

  items.push(
    <div className="mt-6">
      <CertificateList
        {...{
          commonFieldProps: commonFieldProperties,
          modal,
          reactForm
        }}
      />
    </div>
  );

  if (!isDetailsView) {
    items.push(
      <div className="mt-6">
        <InlineCheckbox
          {...{
            label: "Punktesystem vorhanden",
            name: "has_points",
            ...commonFieldProperties
          }}
        />
      </div>
    );
  }

  const pointsLabelForReadOnly = "Punktesystem - Maximal erreichbare Punkte";

  if (hasPointsChecked) {
    items.push(
      <div className="mt-6">
        <NumberField
          {...{
            allowNegative: false,
            control: reactForm.control,
            inline: true,
            int: true,
            label: isDetailsView ? pointsLabelForReadOnly : "Maximal verfügbare Punkte",
            name: "max_points",
            spanLabel: true,
            ...commonFieldProperties
          }}
        />
      </div>
    );
  }
  else if (isDetailsView) {
    items.push(
      <div className="mt-6 text-gray-700">
        <label>{pointsLabelForReadOnly}</label>

        <p>k.A.</p>
      </div>
    );
  }

  items.push(
    <div className="mt-6">
      <AreaField
        {...{
          errors: reactForm.formState.errors,
          inline: true,
          label: "Interne Bemerkungen",
          name: "internal_notes",
          ...selectByKeys(reactForm, [
            "register",
            "resetField",
            "setValue",
            "watch"
          ]),
          isDetailsView
        }}
      />
    </div>
  );

  return (
    <form
      autoComplete="off"
      onSubmit={reactForm.handleSubmit(
        onValidSubmit.bind({
          modal,
          mutateIssuerCertificates: issuerCertificatesCache.mutate,
          mutateIssuers,
          reactForm,
          setIsDuringSubmit
        }),
        onInvalidSubmit.bind({ modal })
      )}
    >

      <h3 className="text-lg font-medium">{headerString}</h3>

      <div> {items} </div>

      {isDetailsView
        ? (
          <BottomClose
            onClick={handleClose.bind(null, {
              modal,
              reactForm
            })}
          />
        )
        : (
          <div className="mt-5 sm:mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3" >
            <CancelButton
              onClick={handleClose.bind(null, {
                modal,
                reactForm
              })}
            />

            <FormSubmitButton
              {...{
                isSubmitting: isDuringSubmit,
                labelCentered: true
              }}
            >
              {" "}
              Speichern
            </FormSubmitButton>
          </div>
        )}

    </form>
  );
};

export default IssuerForm;
