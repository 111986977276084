import {
  dateActivity,
  distanceActivity,
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers.js";

export default {
  ...Object.fromEntries(["id", "project_id"].map((key) => [key, hiddenActivity])),

  ...Object.fromEntries([
    ["plan_number", "Plandokument"],
    ["widmung", "Flächenwidmung"],
    ["bauklasse", "Bauklasse"],
    ["bauweise", "Bauweise"],
    ["additional", "Zusätzl. Bestimmungen"],
    ["height_limit_sign", "Höhenbeschränkung (+)"]
  ].map(([key, label]) => [key, normalActivity(label)])),

  ...Object.fromEntries([["height_limit", "Höhenbeschränkung"], ["height_limit_to", "Höhenbeschränkung bis"]].map(([key, label]) => [key, distanceActivity(label)])),

  publish_date: dateActivity("Kundmachung")
};
