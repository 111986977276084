import { parseError } from "~/src/modules/api.js";
import Handler from "~/src/modules/handler.js";

const { handleDelete } = new Handler({
  endpoint: "/building-parts/:id",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsLoading(false);
    setIsSubmitting(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(parseError(error));
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  }
});

export { handleDelete };
