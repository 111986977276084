import {
  areaActivity,
  dateActivity,
  hiddenActivity,
  listActivity,
  normalActivity,
  priceActivity,
  urlActivity,
  yearActivity
} from "~/src/modules/format-activity-change/helpers.js";

export default {
  ...Object.fromEntries(
    [
      "id",
      "project_id",
      "transaction_category_id",
      "transaction_category_code",
      "katastralgemeinde_id",
      "katastralgemeinde_bezirksgericht_id",
      "katastralgemeinde_bezirksgericht_state_id",
      "seller_id",
      "seller_slug",
      "buyer_id",
      "buyer_slug"
    ].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["usable", "Aktiv (für GKA)"],
      ["kv_id", "KV-Detail ID"],
      ["transaction_category", "Objektkategorie"],
      ["katastralgemeinde_code", "KG-Nr."],
      ["katastralgemeinde", "KG"],
      ["katastralgemeinde_bezirksgericht_code", "BG-Nr."],
      ["katastralgemeinde_bezirksgericht", "BG"],
      ["tz_number", "TZ"],
      ["brez", "BREZ"],
      ["br_stamm_ez", "EZ Stammliegenschaft"],
      ["baurechtsdauer", "Baurechtsdauer"],
      ["property_area", "GST-Fläche"],
      ["shares", "Anteile"],
      ["notes", "Bemerkungen"],
      ...[["seller", "Verkäufer/Baurechtsgeber"], ["buyer", "Käufer/Bauberechtigter"]]
        .flatMap(([type, label]) => [
          [type, `${label} - Name`],
          [`${type}_private`, `${label} - Privat`],
          [`${type}_searchable`, `${label} - Suchbar`],
          [`${type}_reg_number`, `${label} - FB- oder ZVR-Nr.`],
          [`${type}_is_zvr`, `${label} - Verein`],
          [`${type}_internal_notes`, `${label} - Interne Bemerkungen`],
          [`${type}_street`, `${label} - Straße`],
          [`${type}_house_number`, `${label} - Hausnummer`],
          [`${type}_zipcode`, `${label} - PLZ`],
          [`${type}_city`, `${label} - Stadt`],
          [`${type}_additional_address_info`, `${label} - Adresszusatz`],
          [`${type}_country`, `${label} - Land`]
        ])
    ].map(([key, label]) => [key, normalActivity(label)])
  ),

  ...Object.fromEntries(
    [["transaction_date", "Vertragsdatum"], ["baurechtsdauer_date", "Baurecht bis"]].map(([key, label]) => [key, dateActivity(label)])
  ),

  ...Object.fromEntries(
    [
      ["bauzins_net", "Bauzins (monatlich) netto"],
      ["bauzins_gross", "Bauzins (monatlich) brutto"],
      ["sale_price_net", "Kaufpreis netto"],
      ["sale_price_gross", "Kaufpreis brutto"]
    ].map(([key, label]) => [key, priceActivity(label)])
  ),

  ...Object.fromEntries(
    [
      ["build_area", "Baufläche Gebäude"],
      ["green_area", "Baufläche begrünt"],
      ["usable_area", "Nutzfläche"]
    ].map(([key, label]) => [key, areaActivity(label)])
  ),

  ...Object.fromEntries(
    [["ez_numbers", "Einlagezahlen"], ["gst_numbers", "GST-Nr."]].map(([key, label]) => [key, listActivity(label)])
  ),

  kv_url: urlActivity("KV-Detail Link"),

  tz_year: yearActivity("TZ-Jahr")
};
